import moment from "moment";

import { multitenancyReceiptTemplateLogo, multitenancyPhone } from "multitenancy/multitenancyContactInfo";

// Add Logo Image to receipt template
export const convertReceiptData = (data) => {
  const splitData = data.split("'template__images'>");
  const joinedData = `${splitData[0]}'template__images'><img src='${multitenancyReceiptTemplateLogo()}' /><p>${multitenancyPhone()}</p>${splitData[1]}`;
  return joinedData;
};

export const NACHAButtonName = (type) => {
  if (type === "One Time" || type === "Extra") return "S";
  if (type === "Regular") return "M";
};

export const paymentProcessingDate = (date, paymentType) => {
  if (paymentType === "ach") return moment.utc(date).add(5, "days").format("ll");
  if (paymentType === "other") return moment.utc(date).format("ll");
  else return moment.utc(date).add(2, "days").format("ll");
};
