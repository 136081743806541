import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Modal from "@material-ui/core/Modal";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import PropTypes from "prop-types";
import Select from 'react-select'

import { TagsBody } from "../../components/TagsBody";
import { TableContainer } from "../TableContainer";
import { DatePicker } from "./components/DatePicker";
import { SystemCloseIcon16 } from "../icons/SystemIcons";
import { addNewNote, deleteNotes } from "../../lib/api/admin";
import { getShortConsumerDebts_new } from "lib/api/common";
import { LoadingSpinner } from "../LoadingSpinner";
import { fetchConsumerNotes, setShowPerPage } from "../../redux/actions/consumerNotes";
import {
  formattingPhoneNumber,
  unformattedPhoneNumber,
} from "../../lib/helpers/formatters";
import { SearchContainer } from "../TableContainer/components/TableCustomControls/SearchContainer";
import { TableShowRows } from "../TableContainer/components/TableCustomControls/TableShowRows";
import { TableShowPages } from "../TableContainer/components/TableCustomControls/TableShowPages";
import { CustomButton } from "../ui-kit/CustomButtom";
import { getCommCount } from "lib/api/common";
import { InfoTooltip } from "components/InfoTooltip";

import "./style.scss";

export const NotesBody = ({ adminNotes }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setDeleteModalOpen] = useState(false);
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [selectedNoteTags, setSelectedNoteTags] = useState([]);
  const [newNoteText, setNewNoteText] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [conv_id, setConv_id] = useState();
  const [headers] = useState(["Date and Time", "Author", "Note"]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [preparedDeleteObject, setPreparedObject] = useState({});
  
  const [debtList, setDebtList] = useState([]);
  const [selectedDataId, setSelectedDataId] = useState("");
  const [formedSelectFilter, setFormSelectFilter] = useState([]);
  const [selectedSearchDataId, setSelectedSearchDataId] = useState("");

  const [isSaveNoteDisabled, setSaveNoteDisabled] = useState(false);

  const handleDateChange = (date) => {
    setCurrentPage(1);
    fetchNotes(1, { date_from: date.date_from, date_to: date.date_to });
  };

  const notesData = useSelector((state) => state.consumerNotes.notesData);
  const datesRange = useSelector((state) => state.consumerNotes.datesRange);
  const activeTags = useSelector((state) => state.consumerNotes.activeTags);
  const showPerPage = useSelector((state) => state.consumerNotes.showPerPage);
  const loading = useSelector((state) => state.consumerNotes.loading);

  const [commCount, setCommCount] = useState();

  const fetchNotes = async (page, dates, pages) => {
    const date_from =
      (dates && `${moment(dates.date_from).format("YYYY-MM-DD")} 00:00`) ||
      `${moment(
        datesRange.date_from || new Date("January 1, 2020 00:00:00")
      ).format("YYYY-MM-DD")} 00:00`;

    const date_to =
      (dates && `${moment(dates.date_to).format("YYYY-MM-DD")} 23:59`) ||
      `${moment(datesRange.date_to).format("YYYY-MM-DD")} 23:59`;

    dispatch(
      fetchConsumerNotes({
        customer_id: id,
        current_page: page || 1,
        per_page: pages || showPerPage || 10,
        date_from,
        date_to,
        search: searchText,
        data_id: selectedSearchDataId.value
      })
    );
  };

  const fetchDebtInfo = async() => {
    const response = await getShortConsumerDebts_new({
      statuses: ["active", "established", "recalled", "paid_off", "delinquent", "on_hold"],
      client_id: id
    });
    if (response.success === "True") {
      setDebtList(response.data);
      formingSelectFilter(response.data);
      setSelectedDataId(response.data[0].referenceNumber);
    }
  }

  const formingSelectFilter = (data) => {
    let formedArray = [];
    data.map((item) => {
      const object = {
        label: item.referenceNumber,
        value: item.referenceNumber
      }
      formedArray.push(object);
    })
    const showAllObj = {label: "Show All", value: "all"};
    formedArray.unshift(showAllObj);

    setFormSelectFilter(formedArray);
    setSelectedSearchDataId(formedArray[0]);

    getCommValue(formedArray[0].value);
  }

  useEffect(() => {
    fetchDebtInfo();
  }, []);

  useEffect(() => {
    if (selectedSearchDataId) {
      fetchNotes(currentPage, {
        date_from: datesRange.date_from,
        date_to: datesRange.date_to,
      });
    }
  }, [selectedSearchDataId])

  const getCommValue = async(referenceNumber) => {
    const response = await getCommCount(referenceNumber, id);
    if (response.success === "True") {
      setCommCount(response.comm_count);
    }
  }

  const toggleAddNote = () => {
    setIsAddingNote(!isAddingNote);
  };

  const deleteNote = (created_at) => {
    setDeleteModalOpen(true);
    setPreparedObject({created_at: created_at}) 
  }

  const handleDeleteNote = async() => {
    const response = await deleteNotes(preparedDeleteObject);

    if (response.success === "True") {
      await fetchNotes(currentPage);
      setDeleteModalOpen(false);
    } else {
      alert.error(`Error: ${response.message}`);
    }
  }

  const handleAddNewNote = async () => {
    setSaveNoteDisabled(true);
    const response = await addNewNote({
      tags: selectedNoteTags,
      comment: newNoteText,
      customer_id: id,
      phone_number: unformattedPhoneNumber(phoneNumber),
      data_id: selectedDataId,
      conv_id: conv_id
    });

    if (response.success === "True") {
      setSaveNoteDisabled(false);
      setNewNoteText("");
      setPhoneNumber("");
      setSelectedDataId(debtList[0].referenceNumber)
      await fetchNotes(currentPage);
      toggleAddNote();
    } else {
      setSaveNoteDisabled(false);
      alert.error(`Error: ${response.message}`);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    setDeleteModalOpen(false);
  };

  const handleClose = () => {
    setOpen(true);
  };

  const handleNoChanges = () => {
    setSelectedDataId(debtList[0].referenceNumber)
    setOpen(false);
    setIsAddingNote(!isAddingNote);
  };

  const setCurrentTablePage = async (page) => {
    await setCurrentPage(page);
    await fetchNotes(page);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleEnterPressed = async () => {
    await fetchNotes();
  };

  const handleShowPerPage = async (pages) => {
    const [formatted] = pages.split(" ");
    dispatch(setShowPerPage(+formatted));

    if (notesData.notes_number > +formatted) {
      await fetchNotes(currentPage, null, +formatted);
    } else {
      await fetchNotes(1, null, +formatted);
    }
  };

  const handleChangePhoneNumber = (e) => {
    if (e.target.value.length > 14) {
      return;
    }
    setPhoneNumber(formattingPhoneNumber(e.target.value));
  };

  const handleChnageConversationId = (e) => {
    setConv_id(e.target.value);
  }

  const changeReferenceNumber = (e) => {
    setSelectedDataId(e.target.value);
  }

  const filterByDataId = (e) => {
    if (e) {
      setSelectedSearchDataId({label: e.label, value: e.value});
      getCommValue(e.value);
    } else {
      setSelectedSearchDataId("");
    }
  }

  return (
    <div className="main-container notes-body">
      {isAddingNote ? (
        <div className="notes-body__new-note">
          {adminNotes ? (
            <div className="d-flex justify-content-between">
              <h2 className="heading heading-2">Current Tags</h2>
              <div className="notes-body__header-buttons">
                <CustomButton text="New Tag" />
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-between">
              <h2 className="heading heading-2">New Note</h2>
              <div className="notes-body__header-buttons">
                <CustomButton
                  text="Close"
                  type="secondary"
                  onClickHandler={handleClose}
                />
                <CustomButton
                  text="Save Note"
                  disabled={isSaveNoteDisabled}
                  onClickHandler={handleAddNewNote}
                />
              </div>
            </div>
          )}

          <div className="new-note__main">
            <TagsBody
              notesTags={activeTags}
              setSelectedNoteTags={setSelectedNoteTags}
            />
            {!adminNotes && (
              <div className="new-note__comment">
                <h4 className="heading heading-body-bold">
                  Data id
                </h4>
                <select
                  className="form-control reference-number-select"
                  onChange={changeReferenceNumber}
                >
                  {debtList?.map((item, i) => {
                    return (
                      <option key={i} value={item?.referenceNumber}>{item?.referenceNumber}</option>
                    )
                  })}
                </select>
                <h4 className="heading heading-body-bold">
                  Phone Number (optional)
                </h4>
                <input
                  type="text"
                  className="form-control"
                  value={phoneNumber}
                  onChange={handleChangePhoneNumber}
                />
                <h4 className="heading heading-body-bold">
                  Conversation ID (optional)
                </h4>
                <input
                  type="text"
                  className="form-control"
                  value={conv_id}
                  onChange={handleChnageConversationId}
                />
                <h4 className="heading heading-body-bold">Comment</h4>
                <textarea
                  onChange={(e) => setNewNoteText(e.target.value)}
                  value={newNoteText}
                  className="form-control"
                  name="comment"
                  id="comment"
                  placeholder="Enter your comment here..."
                ></textarea>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          {loading && !!notesData ? (
            <LoadingSpinner middleFixed={true}/>
          ) : (
            <TableContainer
              tableControls={
                <div className="table-controls">
                  <SearchContainer
                    handleSearchChange={handleSearchChange}
                    handleEnterPressed={handleEnterPressed}
                    searchText={searchText}
                  />
                  <Select
                    className="select-search-container"
                    id="search-dropdown"
                    isClearable="true"
                    placeholder="Data Id"
                    value={selectedSearchDataId}
                    options={formedSelectFilter}
                    onChange={filterByDataId}
                  />
                  <div className="date-picker-container">
                    <DatePicker handleDateChange={handleDateChange} />
                  </div>
                  <div className={`comm-count ${commCount >= 5 ? "red-color" : null}`}>
                    {commCount >= 5 ? (
                      <InfoTooltip
                        title="More than 5 comm count"
                      />
                    ) : null}
                    Comm Count: {commCount ? commCount : "-"}
                  </div>
                  <TableShowRows
                    showPerPage={showPerPage}
                    handleShowPerPage={handleShowPerPage}
                  />
                  <TableShowPages
                    setCurrentTablePage={setCurrentTablePage}
                    currentPage={currentPage}
                    pagination={notesData.pagination}
                    itemsAmount={notesData.notes_number}
                    showPerPage={showPerPage}
                  />
                  <div className="add-note">
                    <CustomButton
                      text="Add New Note"
                      onClickHandler={toggleAddNote}
                    />
                  </div>
                </div>
              }
              tableHeaders={headers}
              data={notesData.page}
              currentPage={currentPage}
              setCurrentPage={setCurrentTablePage}
              deleteNote={deleteNote}
              pagesCount={
                (notesData.pagination &&
                  notesData.pagination.page_range) ||
                1
              }
            />
          )}
        </div>
      )}
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="notes-body__modal">
          <div className="modal__header">
            <h3 className="heading heading-3">Closing Page</h3>
            <button onClick={handleCloseModal} className="button-transparent">
              <SystemCloseIcon16 />
            </button>
          </div>
          <div className="modal__body">
            <p>
              Are you sure you want to close this note? All your information
              will be deleted.
            </p>
          </div>
          <div className="modal__footer">
            <CustomButton
              type="secondary"
              text="Cancel"
              onClickHandler={handleCloseModal}
            />
            <CustomButton text="Yes, Close" onClickHandler={handleNoChanges} />
          </div>
        </div>
      </Modal>
      {/* Delete Note Modal */}
      <Modal
        open={openDeleteModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="notes-body__modal">
          <div className="modal__header">
            <h3 className="heading heading-3">Delete Note</h3>
            <button onClick={handleCloseModal} className="button-transparent">
              <SystemCloseIcon16 />
            </button>
          </div>
          <div className="modal__body">
            <p>
              Are you sure you want to Delete this note?
            </p>
          </div>
          <div className="modal__footer">
            <CustomButton
              type="secondary"
              text="Cancel"
              onClickHandler={handleCloseModal}
            />
            <CustomButton text="Yes, Delete" onClickHandler={handleDeleteNote} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

NotesBody.propTypes = {
  adminNotes: PropTypes.string,
};
