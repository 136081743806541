import React from "react";
import {
  NavigationConsumersIcon,
  NavigationAgentsIcon,
  NavigationProfileIcon,
  NavigationSettingsIcon,
} from "../icons/NavigationIcons";
import {
  SystemArchieveIcon16,
  // SystemCalendarIcon16,
} from "../icons/SystemIcons";

export const authenticatedAdminNavItems = [
  {
    title: "Customers",
    link: "/admin/consumers",
    iconURL: "#",
    icon: <NavigationConsumersIcon />,
  },
  {
    title: "Roles",
    link: "/admin/roles",
    iconURL: "#",
    icon: <NavigationAgentsIcon />,
  },
  {
    title: "Inventory",
    link: "/admin/inventory",
    iconURL: "#",
    icon: <SystemArchieveIcon16 />,
  },
  {
    title: "Tags",
    link: "/admin/settings",
    iconURL: "#",
    icon: <NavigationSettingsIcon />,
  },

  // {
  //   title: "Letters",
  //   link: "/admin/letters",
  //   iconURL: "#",
  //   icon: <SystemCalendarIcon16 />,
  // },
  // {
  //   title: "Disputes",
  //   link: "/admin/disputes",
  //   iconURL: "#",
  //   icon: <SystemCalendarIcon16 />,
  // },
  {
    title: "My Profile",
    link: "/admin/profile",
    iconURL: "#",
    icon: <NavigationProfileIcon />,
  },
];
