import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useParams } from "react-router-dom";
import { Close } from "@material-ui/icons";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";

import { CustomButton } from "components/ui-kit/CustomButtom";
import { LoadingSpinner } from "components/LoadingSpinner";

import { removePaymentMethod } from "lib/api/common";

import { fetchSavedPayPal, fetchSavedDebitCards, fetchSavedBankAccounts } from "redux/actions/consumerSavedPaymentMethod";

import { useTranslation } from 'react-i18next';

import "./style.scss";

export const DeletePaymentModal = ({modal, toggle, cardID, type, selectedDataId}) => {

  const { t } = useTranslation()
  
  const { id } = useParams();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const backBtn = () => {
    toggle();
  }

  const removePayment = async() => {
    setLoading(true);
    let request;
    if (id) {
      request = {
        client_id: id,
        card_id: cardID,
        data_id: selectedDataId
      }
    } else {
      request = {
        card_id: cardID,
        data_id: selectedDataId
      }
    }
    const removePaymentMethodResponse = await removePaymentMethod(request);

    if (removePaymentMethodResponse.success !== "True") {
      alert.error(removePaymentMethodResponse.message);
      return;
    } else {
      setLoading(false);
      toggle();

      dispatch(fetchSavedPayPal(id, selectedDataId));
      dispatch(fetchSavedDebitCards(id, selectedDataId));
      dispatch(fetchSavedBankAccounts(id, selectedDataId));
    }
  }

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className="remove-payment-modal"
      centered
    >
        <ModalBody>
          <div className="mod-header">
            <span>{t("Remove")} {t(type)}</span>
            <button className="close-modal-btn" onClick={() => toggle()}>
              <Close />
            </button>
          </div>
          <div className="mod-body">
            {t("Are you sure you want to remove this")} {t(type)}? {t("This action cannot be undone.")}
          </div>
          <div className="mod-footer">
            <CustomButton
              text={t("Cancel")}
              onClickHandler={() => backBtn()}
              optionalClass="cancel-btn"
            />
            <CustomButton
              text={t("Remove")}
              onClickHandler={() => removePayment()}
              optionalClass="confirm-btn"
            />
          </div>
        </ModalBody>
        {loading ? (<LoadingSpinner middleFixed={true}/>) : null}
    </Modal>
  )
}