import React from "react";
import "./style.scss";

import WaythruLogo from "scss/images/logo.png";
import ConsuegraLogo from "scss/images/consuegra-logo.png";
import WayLogo from "scss/images/logo_W.svg";
import TrombergLogi from "scss/images/TMP_Logo.png";

export const MultitenancyLogoPicker = () => {

  const hostname = window.location.hostname.toLowerCase();

  const getLogo = () => {
    switch (hostname) {
      case "waythru-test1.com":
        return <img className="logo-img" src={WaythruLogo} alt="logo" />;
      case "investinet.waythru.com":
        return <img className="logo-img" src={WaythruLogo} alt="logo" />;
      case "consuegra-test.waythru-test1.com":
        return <img className="logo-img" src={ConsuegraLogo} alt="logo" />;
      case "consuegralaw.waythru.com":
        return <img className="logo-img" src={ConsuegraLogo} alt="logo" />;
      case "waythru-test2.com":
        return <img className="logo-img" src={WayLogo} alt="logo" />;
      case "demo.waythru.com":
        return <img className="logo-img" src={WayLogo} alt="logo" />;
      case "tmppllc-test.waythru-test1.com":
        return <img className="logo-img" src={TrombergLogi} alt="logo" />;
      case "tmppllc.waythru.com":
        return <img className="logo-img" src={TrombergLogi} alt="logo" />;
    }
  }


  return (
    <>
      {getLogo()}
    </>
  )
}