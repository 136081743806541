import React from "react";

import moment from "moment";
import PropTypes from "prop-types";
import { formattedMoney } from "lib/helpers/formatters";

import { ButtonLink } from "components/ui-kit/ButtonLink";

import { multitenancyPhone, multitenancyCompanyName, multitenancyHours } from "multitenancy/multitenancyContactInfo";

export const AuthAgreementWebMultiPay = ({
  accountType,
  accountPaymentRequest,
  paymentFrequency,
  selectedPaymentAccount,
  setIsShowingModal,
  isChangingPaymentMethod,
  start_date,
  end_date,
  start_amount,
  end_amount
}) => {

  const accountNumberLastFour = () => {
    if (selectedPaymentAccount === "new") {
      return accountPaymentRequest.account_number?.length <= 4
        ? accountPaymentRequest.account_number
        : accountPaymentRequest.account_number?.slice(
            accountPaymentRequest.account_number?.length - 4,
            accountPaymentRequest.account_number?.length
          );
    } else {
      return selectedPaymentAccount.AccountNumber?.length <= 4
        ? selectedPaymentAccount.AccountNumber
        : selectedPaymentAccount.AccountNumber?.slice(
            selectedPaymentAccount.AccountNumber?.length - 4,
            selectedPaymentAccount.AccountNumber?.length
          );
    }
  };

  return (
    <div className="nacha-container">
      <div className="nacha-text">
        <p>
          I hereby authorize {multitenancyCompanyName()} to initiate{" "}
          <span className="bold">{paymentFrequency}</span> electronic debit
          withdrawals from the indicated bank account for payments in the amount
          of <span className="bold">${formattedMoney(start_amount)}</span> (or as they become
          due and payable under the terms and conditions of the agreement) and
          as described herein. Furthermore, I assert that I am the owner, or an 
          authorized signer of the bank account provided.
        </p>
        {!isChangingPaymentMethod && (
          <p>
            The first payment will be withdrawn from your bank on{" "}
            <span className="bold">{start_date}</span> or (the
            following business banking day), then, subsequent{" "}
            <span className="bold" style={{ marginLeft: "4px" }}>
              {paymentFrequency}
            </span>{" "}
            payments for the duration of this authorization and term of your payment
            plan.
          </p>
        )}
        <p>
          The final payment of <span className="bold">${formattedMoney(end_amount)}</span> will be due on <span className="bold">{end_date}</span>.
        </p>
        {accountType ? (
          <>
            <p>
              Type of Account: {accountType === "1" ? "Checking" : "Savings"}
            </p>
            <p>
              Financial Institution Name: <span className="bold">{selectedPaymentAccount.BankName || accountPaymentRequest.bank_name}</span>
            </p>
            <p>
              Name on Account: <span className="bold">{selectedPaymentAccount.NameOnCheck || accountPaymentRequest.name_on_check}</span>
            </p>
            <p>
              Transit/ABA Number: <span className="bold">{selectedPaymentAccount.RoutingNumber || accountPaymentRequest.routing_number}</span>
            </p>
            <p>
              Last 4 of Account Number: <span className="bold">{accountNumberLastFour()}</span>
            </p>
          </>
        ) : (
          <p>
            Name on Account: <span className="bold">{selectedPaymentAccount.NameOnCheck || accountPaymentRequest.name_on_check}</span>
          </p>
        )}
        <p>
          To rescind this authorization and suspend or cancel this payment, or
          the amount withdrawn from your account needs to be different than the amount
          authorized herein, you may call 1 {multitenancyPhone()} during the following
          business hours: {multitenancyHours()}.
        </p>
        <p>Authorization Signature: {accountPaymentRequest.name_on_check}</p>
        <p>Date and Time: {moment(Date()).format("ll")}</p>
      </div>

      <ButtonLink
        text="Download NACHA Authorization Agreement"
        onClickHandler={() => setIsShowingModal(true)}
      />
    </div>
  );
};

AuthAgreementWebMultiPay.propTypes = {
  accountType: PropTypes.string,
  accountPaymentRequest: PropTypes.object,
  paymentFrequency: PropTypes.string,
  paymentAmount: PropTypes.any,
  selectedPaymentAccount: PropTypes.any,
  setIsShowingModal: PropTypes.func,
  isChangingPaymentMethod: PropTypes.bool,
};
