import React from "react";
import PropTypes from "prop-types";

export const CustomButton = ({
  type = "primary",
  text = "button",
  size = "medium",
  onClickHandler,
  icon,
  buttonId = "button",
  disabled,
  optionalClass,
  imgUrl
}) => {
  function buttonClassName(size, type, optionalClass) {
    let buttonClass = `button button-${type} button-${type}--${size}`;
    if (optionalClass) buttonClass += ` ${optionalClass}`;

    return buttonClass;
  }

  return (
    <button
      className={
        optionalClass
          ? buttonClassName(size, type, optionalClass)
          : buttonClassName(size, type)
      }
      onClick={onClickHandler}
      id={buttonId}
      disabled={disabled}
    >
      {icon ? <span className="icon">{icon}</span> : null}
      {imgUrl ? <img className="info-icon" src={imgUrl}/> : null}
      {text}
    </button>
  );
};

CustomButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.string,
  onClickHandler: PropTypes.func,
  icon: PropTypes.object,
  buttonId: PropTypes.string,
  disabled: PropTypes.any,
  optionalClass: PropTypes.string,
};
