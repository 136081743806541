import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
// import { useAlert } from "react-alert";
// import Checkbox from "@material-ui/core/Checkbox";

// import planeIcon from "scss/images/plane-icon.png";
import editIcon from "scss/images/edit.png";

// import { getBalance } from "../../../../lib/api/admin";
import {
  // formattedMoney,
  returnLastFiveChar,
  // formattingPhoneNumber,
} from "../../../../lib/helpers/formatters";
import {
  // disableEmailSubscription,
  // disablePhoneSubscription,
  // enableEmailSubscription,
  // enablePhoneSubscription,
  fetchSubscriptionStatus
} from "../../../../redux/actions/consumerSubscription";

import { ContactPreferenceModal } from "./ContactPreferenceModal";

import { fetchConsumerProfile } from "../../../../redux/actions/profile";
import "./style.scss";

export const ConsumerInfo = () => {
  // const alert = useAlert();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profile.profileData);
  const profileLoading = useSelector((state) => state.profile.loading);

  const [contactPreferenceModal, setContactPreferenceModal] = useState(false);
  const toggleContactPreferenceModal = () => setContactPreferenceModal(!contactPreferenceModal);

  // const consumerStrategy = useSelector((state) => state.profile.strategy);
  // const subscriptionLoading = useSelector(
  //   (state) => state.consumerSubscription.loading
  // );

  // const phoneOptOutState = useSelector(
  //   (state) =>
  //     state.consumerSubscription.subscriptionStatuses.phone_subscription
  // );
  // const emailOptOutState = useSelector(
  //   (state) =>
  //     state.consumerSubscription.subscriptionStatuses.email_subscription
  // );

  // const commCount = useSelector((state) => state.profile.comm_count);

  // const balance = useSelector((state) => state.balance.balanceData);

  const { id } = useParams();

  // const [currentBalance, setCurrentBalance] = useState();
  // const [perMonth, setPerMonth] = useState();

  useEffect(() => {
    // TODO: Remove After regression test 13.12.2021
    // const fetchBalance = async () => {
    //   let data;

    //   try {
    //     data = await getBalance({ client_id: id });
    //     setCurrentBalance(data.total_current_balance);
    //     setPerMonth(data.total_per_month);
    //   } catch (e) {
    //     alert.error("Failed to fetch balance. Please try again.");
    //   }
    // };
    // fetchBalance();

    dispatch(fetchSubscriptionStatus(id));
    dispatch(fetchConsumerProfile(id));
    // eslint-disable-next-line
  }, []);

  // const handleChange = async (e) => {
  //   const checked = e.target.checked;
  //   if (e.target.name === "phoneOptOut") {
  //     !checked
  //       ? dispatch(enablePhoneSubscription(id, emailOptOutState))
  //       : dispatch(disablePhoneSubscription(id, emailOptOutState));
  //   } else if (e.target.name === "emailOptOut") {
  //     !checked
  //       ? dispatch(enableEmailSubscription(id, phoneOptOutState))
  //       : dispatch(disableEmailSubscription(id, phoneOptOutState));
  //   }
  // };

  // const goToEmailsTemplates = () => {
  //   window.location.replace(`/admin/consumers/consumer/${id}/email-templates`);
  // }

  const openContactPreferenceModal = () => {
    toggleContactPreferenceModal();
  }

  return (
    !profileLoading && (
      <aside className="consumer-info">
        <div className="consumer-info__column-wrapper">
          {/* <div className="consumer-info__column">
            <h6>Phone Number</h6>
            <span
              className={
                phoneOptOutState
                  ? "opt-out__status"
                  : "opt-out__status--disabled"
              }
            >              
              <Checkbox
                checked={!phoneOptOutState}
                onChange={handleChange}
                disabled={subscriptionLoading}
                name="phoneOptOut"
                size="small"
                color="primary"
              />
              {profileData.profile &&
                formattingPhoneNumber(profileData.profile.cell_phone)}
            </span>
          </div>
          <div className="consumer-info__column">
            <h6>Email</h6>
            <span
              className={
                emailOptOutState
                  ? "opt-out__status"
                  : "opt-out__status--disabled"
              }
            >
              <Checkbox
                checked={!emailOptOutState}
                onChange={handleChange}
                disabled={subscriptionLoading}
                name="emailOptOut"
                size="small"
                color="primary"
              />
              {profileData.profile && profileData.profile.email}
            </span>
          </div> */}
          <div className="commun-pref-btn" onClick={() => openContactPreferenceModal()}>
            <img className="info-icon" src={editIcon}/>
            <div>Communication Preferences</div>
          </div>
          {contactPreferenceModal ? (
            <ContactPreferenceModal
              toggle={toggleContactPreferenceModal}
              modal={contactPreferenceModal}
            />
          ) : null}
          {/* <div className="consumer-info__column">
            <h6>Comm Count</h6>
            <span>
              {commCount}
            </span>
          </div> */}
          {/* <div className="send-mail-btn" onClick={() => goToEmailsTemplates()}>
            <img src={planeIcon} alt="plane icon"/>
            <span>Send...</span>
          </div> */}
        </div>
        <div className="consumer-info__column-wrapper">
          {/* <div className="consumer-info__row">
            <span className="label">Lang</span>
            <span className="value">{profileData.profile && profileData.profile.language}</span>
          </div> */}
          <div className="consumer-info__row">
            <span className="label ssn">DOB</span>
            <span className="value">{profileData.profile && moment(profileData.profile.dob).format('ll')}</span>
          </div>
          <div className="consumer-info__row">
            <span className="label ssn">SSN</span>
            <span className="value">{profileData.profile && returnLastFiveChar(profileData.profile.ssn)}</span>
          </div>
        </div>
      </aside>
  
    )
  );
};
