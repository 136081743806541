import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useParams } from "react-router-dom";

import { LoadingSpinner } from "../../../components/LoadingSpinner";

import { OtherMasterCardIcon, OtherVisaIcon } from "../../../components/icons/OtherIcons";
import amexSvg from "../../../scss/images/amex.svg";
import discoverSVG from "../../../scss/images/discover.svg";
import { DeleteIcon } from "scss/multitenantIcons/deleteIcon";

import { 
  setDefaultPaymentMethod, 
  registerNewTkl2Account,
  getNACHA,
  getShortConsumerDebts_new,
  generateNACHA
} from "lib/api/common";

import { fetchSingleBalance } from "../../../redux/actions/singleBalance";
import { 
  setNACHAModalData, 
} from "redux/actions/consumerPayments";
import { fetchSavedPayPal, fetchSavedDebitCards, fetchSavedBankAccounts } from "redux/actions/consumerSavedPaymentMethod";

import { AddDebitCardModal } from "./AddDebitCardModal";
import { AddBankAccountModal } from "./AddBankAccountModal";
import { AddPayPalModal } from "./AddPayPalModal";
import { DeletePaymentModal } from "./DeletePaymentModal";

import { fetchDefaultPaymentMethod } from "redux/actions/getDefaultPaymentMethod";
import { ReceiptModal } from "components/ReceiptModal";
import { NACHAPhoneMultiModalData, NACHAWebMultiModalData } from "lib/common/NACHAModalData";
import { accountNumberLastFour } from "lib/helpers/other";

import { useTranslation } from 'react-i18next';
import { showDependOnTenant, showOnConsuegra } from "multitenancy/multitenancyPayPal";

import "./style.scss";

export const PaymentMethod = () => {

  const { t } = useTranslation();

  const { id } = useParams();

  const alert = useAlert();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [innerLoading, setInnerLoading] = useState(false);

  const [addPayPalModal, setAddPayPalModal] = useState(false);
  const toggleAddPayPalModal = () => setAddPayPalModal(!addPayPalModal);

  const [addDebitCardModal, setAddDebitCardModal] = useState(false);
  const toggleAddDebitCardModal = () => setAddDebitCardModal(!addDebitCardModal);

  const [addBankAccountModal, setAddBankAccountModal] = useState(false);
  const toggleAddBankAccountModal = () => setAddBankAccountModal(!addBankAccountModal);

  const [removePaymentModal, setRemovePaymentModal] = useState(false);
  const toggleRemovePaymentModal = () => setRemovePaymentModal(!removePaymentModal);
  const [removedCardID, setRemovedCardID] = useState();
  const [typeOfDelete, setTypeOfDelete] = useState();

  const savedPayPalAccounts = useSelector((state) => state.consumerSavedPaymentMethod.savedPayPalAccounts);
  const savedDebitCards = useSelector((state) => state.consumerSavedPaymentMethod.savedDebitCards);
  const savedBankAccount = useSelector((state) => state.consumerSavedPaymentMethod.savedBankAccount);

  const profileInfo = useSelector((state) => state.profile.profileData.profile);

  const defaultPaymentMethod = useSelector((state) => state.getDefaultPaymentMethod.data);
  const [disableMailWireDefault, setDisableMailWireDefault] = useState(false);

  const [isShowingModal, setIsShowingModal] = useState(false);
  const modalData = useSelector((state) => state.consumerPayments.modalData);

  const [debtList, setDebtList] = useState([]);
  const [selectedDataId, setSelectedDataId] = useState('');

  const [getNachaState, setNachaState] = useState();

  const [selectedACHAccount, setSelectedACHAccount] = useState(null);
  const ModalHeader = () => {
    return (
      <button
        className="button button-accept button-accept--medium"
        onClick={() => setCardAsDefault(selectedACHAccount)}
      >
        {t("Accept")}
      </button>
    );
  };

  useEffect(() => {
    const fetchAllDebts = async() => {
      setLoading(true);
      const response = await getShortConsumerDebts_new({
        statuses: ["active", "established", "recalled", "paid_off", "delinquent", "on_hold"],
        client_id: id
      });
      if (response.success === "True") {
        setDebtList(response.data);
        setSelectedDataId(response.data[0].referenceNumber);
        setLoading(false);
      }
    }
    fetchAllDebts();
  }, [])

  useEffect(() => {
    if (selectedDataId) {
      dispatch(fetchSavedPayPal(id, selectedDataId));
      dispatch(fetchSavedDebitCards(id, selectedDataId));
      dispatch(fetchSavedBankAccounts(id, selectedDataId));
      dispatch(fetchDefaultPaymentMethod(id, selectedDataId));
    }
  }, [selectedDataId])

  useEffect(() => {
    dispatch(fetchSingleBalance(id));
  }, [])

  useEffect(() => {
    if (defaultPaymentMethod) {
      initialSetDefaultPaymentMethodFunc();
    }
  }, [defaultPaymentMethod]);

  const renderCardIcon = (cardType) => {
    cardType = cardType.slice(0, -5);
    if (cardType === "MASTERCARD") {
      return <OtherMasterCardIcon />
    }
    if (cardType === "VISA") {
      return <OtherVisaIcon />
    }
    if (cardType === "AMEX") {
      return <img className="card-icon" src={amexSvg} alt="amex" />
    }
    if (cardType === "DISCOVER") {
      return <img className="card-icon" src={discoverSVG} alt="discover" />
    }
  }

  const addNewPayPal = async() => {
    if (profileInfo.new_payment_system && !profileInfo.profile_created) {
      setInnerLoading(true);
      let request
      if (id) {
        request = await registerNewTkl2Account({client_id: id});
      } else {
        request = await registerNewTkl2Account();
      }
      if (request.success === "True") {
        setInnerLoading(false);
        toggleAddPayPalModal();
      } else {
        setInnerLoading(false);
        alert.error(request.message);
      }
    } else {
      toggleAddPayPalModal();
    }
  }


  const addNewCard = async() => {
    if (profileInfo.new_payment_system && !profileInfo.profile_created) {
      setInnerLoading(true);
      let request
      if (id) {
        request = await registerNewTkl2Account({client_id: id});
      } else {
        request = await registerNewTkl2Account();
      }
      if (request.success === "True") {
        setInnerLoading(false);
        toggleAddDebitCardModal();
      } else {
        setInnerLoading(false);
        alert.error(request.message);
      }
    } else {
      toggleAddDebitCardModal();
    }
  }

  const addNewBankAccount = async() => {
    if (profileInfo.new_payment_system && !profileInfo.profile_created) {
      setInnerLoading(true);
      let request
      if (id) {
        request = await registerNewTkl2Account({client_id: id});
      } else {
        request = await registerNewTkl2Account();
      }
      if (request.success === "True") {
        setInnerLoading(false);
        toggleAddBankAccountModal();
      } else {
        setInnerLoading(false);
        alert.error(request.message);
      }
    } else {
      toggleAddBankAccountModal();
    }
  }

  const setCardAsDefault = async (card) => {
    setIsShowingModal(false);
    let request;
    if (id) {
      request = {
        client_id: id,
        card_id: card.Id,
        data_id: selectedDataId
      }
    } else {
      request = {
        card_id: card.Id,
        data_id: selectedDataId
      }
    }

    setLoading(true);
    const setDefaultPaymentMethodResponse = await setDefaultPaymentMethod(request);

    if (setDefaultPaymentMethodResponse.success !== "True") {
      setLoading(false);
      alert.error(setDefaultPaymentMethodResponse.message);
    } else {
      setLoading(false);
      setDisableMailWireDefault(false);

      if (getNachaState === "False") {
        await generateNACHA({
          account_number: card.AccountNumber,
          account_type: card.TypeOfAccount,
          bank_name: card.BankName,
          agreement_timestamp: new Date(),
          email: profileInfo.email,
          client_id: id,
          routing_number: card.RoutingNumber,
          name_on_check: card.NameOnCheck,
          payment_frequency: "Regular",
          data_id: selectedDataId
        });
      }

      dispatch(fetchSavedPayPal(id, selectedDataId));
      dispatch(fetchSavedDebitCards(id, selectedDataId));
      dispatch(fetchSavedBankAccounts(id, selectedDataId));
      dispatch(fetchDefaultPaymentMethod(id, selectedDataId));
    }
  }
  
  const openAchNacha = async (card) => {
    setInnerLoading(true);

    let nachaData;

    nachaData = await getNACHA({
      client_id: id,
      payment_date: new Date(),
      payment_frequency: "Regular",
      data_id: selectedDataId,
      account_number: card.AccountNumber,
      routing_number: card.RoutingNumber,
    });
    
    setInnerLoading(false);
    setNachaState(nachaData.success);
  
    dispatch(
      setNACHAModalData({
        ...modalData,
        type: card.TypeOfAccount,
        account_number: card.AccountNumber,
        bank_name: card.BankName,
        routing_number: card.RoutingNumber,
        name_on_check: card.NameOnCheck,
        email: profileInfo.email,
      })
    )
    setIsShowingModal(true);
    setSelectedACHAccount(card);
  }

  const renderCardNumber = (cardNumber) => {
    cardNumber = cardNumber.slice(-4);
    return (
      <span className="card-number">
        •••• {cardNumber}
      </span> 
    )
  }

  const renderCardTime = (expiry) => {
    const month = expiry.Month;
    const year = expiry.Year.toString().substr(-2);
    return (
      <span className="card-time">
        {month}/{year}
      </span>
    )
  }

  const renderAccountRoutingNumber = (number) => {
    number = number.slice(-4);
    return (
      <span className="account-number">
        •••• {number}
      </span> 
    )
  }

  const renderDelete = (item, type) => {
    if (!item.DefaultForPayments) {
      return (
        <button
          className="button-transparent delete-payment-icon"
          onClick={() => handleRemove(item.Id, type)}
        >
          <DeleteIcon />
        </button>
      )
    }
  }

  const handleRemove = async (cardId, type) => {
    toggleRemovePaymentModal();
    setRemovedCardID(cardId);
    setTypeOfDelete(type);
  }

  const initialSetDefaultPaymentMethodFunc = async() => {
    if (defaultPaymentMethod && defaultPaymentMethod.success === "True") {
      if (defaultPaymentMethod?.manual_details?.Manual) {
        setDisableMailWireDefault(true);
      } else {
        setDisableMailWireDefault(false);
      }
    }
  }

  const setMailWireAsDefault = async() => {
    setInnerLoading(true);
    const setDefaultPaymentMethodResponse = await setDefaultPaymentMethod({
      client_id: id,
      card_id: "None",
      data_id: selectedDataId
    });

    if (setDefaultPaymentMethodResponse.success !== "True") {
      alert.error(setDefaultPaymentMethodResponse.message);
      setInnerLoading(false);
    } else {
      dispatch(fetchSavedPayPal(id, selectedDataId));
      dispatch(fetchSavedDebitCards(id, selectedDataId));
      dispatch(fetchSavedBankAccounts(id, selectedDataId));
      dispatch(fetchDefaultPaymentMethod(id, selectedDataId));
      setDisableMailWireDefault(true);
      setInnerLoading(false);
    }
  }

  const changeReferenceNumber = (e) => {
    setSelectedDataId(e.target.value);
  }

  return (
    <>
      <div className="profile-payment-method">
        {loading || innerLoading ? (<LoadingSpinner middleFixed={true}/>) : null}
        {!id && (
          <div className="payment-method-title">{t("Payment Method")}</div>
        )}
        {showOnConsuegra() && (
          <div className="reff-number-container">
            <div className="label">{t("Select Reference Number")}</div>
            <select
              className="reference-number-select"
              onChange={changeReferenceNumber}
            >
              {debtList.map((item, i) => {
                return (
                  <option key={i} value={item.referenceNumber}>{item.referenceNumber}</option>
                )
              })}
            </select>
          </div>
        )}
        {showDependOnTenant(profileInfo) && (
          <div className="pay-pal-block">
            <div className="block-title">PayPal</div>
            <div className="pay-pal-container">
              <div className="add-new-pay-pal" onClick={addNewPayPal}>+ Add new PayPal</div>
              {savedPayPalAccounts?.map((item, i) => {
                return (
                  <div key={i} className="single-card">
                    <div className="top-block">
                      <div className="top-left">
                        {item.DefaultForPayments 
                          ? <div className="default-btn-blue">Default</div> 
                          : <div className="default-btn-gray" onClick={() => setCardAsDefault(item)}>Default</div>
                        }
                        {renderDelete(item, "card")}
                      </div>
                    </div>
                    <span className="card-number">{item.CustomerName}</span>
                    <div className="body-block">
                      <span className="card-desc">{item.Email}</span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
        <div className="cards-block">
          <div className="block-title">Cards</div>
          <div className="cards-container">
            <div className="add-new-card" onClick={addNewCard}>{t("+ Add new card")}</div>
            {savedDebitCards?.map((item, i) => {
              return (
                <div key={i} className="single-card">
                  <div className="top-block">
                    <div className="top-left">
                      {item.DefaultForPayments 
                        ? <div className="default-btn-blue">{t("Default")}</div> 
                        : <div className="default-btn-gray" onClick={() => setCardAsDefault(item)}>{t("Default")}</div>
                      }
                      {renderDelete(item, "card")}
                    </div>
                    {renderCardIcon(item.CardName)}
                  </div>
                  {renderCardNumber(item.CardName)}
                  <div className="body-block">
                    <span className="card-desc">{item.Description}</span>
                    {renderCardTime(item.Expiry)}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="bank-account-block">
          <div className="block-title">{t("Bank Account")}</div>
          <div className="bank-account-container">
            <div className="add-new-bank-account" onClick={addNewBankAccount}>{t("+ Add new bank account")}</div>
            {savedBankAccount?.map((item, i) => {
              return (
                <div key={i} className="single-bank-account">
                  <div className="top-block">
                    <div className="top-left">
                      {item.DefaultForPayments 
                        ? <div className="default-btn-blue">{t("Default")}</div> 
                        : <div className="default-btn-gray" onClick={() => openAchNacha(item)}>{t("Default")}</div>
                      }
                      {renderDelete(item, "bank account")}
                    </div>
                  </div>
                  <div className="bank-name-block">{item.BankName}</div>
                  <div className="body-block">
                    <div className="block-container">
                      <div className="label">{t("Routing Number")}</div>
                      <div className="value">{renderAccountRoutingNumber(item.RoutingNumber)}</div>
                    </div>
                    <div className="block-container">
                      <div className="label">{t("Account Number")}</div>
                      <div className="value">{renderAccountRoutingNumber(item.AccountNumber)}</div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        {profileInfo?.new_payment_system && id && (
          <div className="bank-account-block">
            <div className="block-title">Mail/wire</div>
            <div className="bank-account-container">
              {disableMailWireDefault ? (
                <div className="add-new-bank-account default-mail-wire">Mail/Wire is default</div>
              ) : (
                <div className="add-new-bank-account" onClick={() => setMailWireAsDefault()}>Set Mail/Wire as default</div>
              )}
            </div>
          </div>
        )}
      </div>
      <AddPayPalModal
        modal={addPayPalModal}
        toggle={toggleAddPayPalModal}
      />
      <AddDebitCardModal
        modal={addDebitCardModal}
        toggle={toggleAddDebitCardModal}
        selectedDataId={selectedDataId}
      />
      <AddBankAccountModal
        modal={addBankAccountModal}
        toggle={toggleAddBankAccountModal}
        selectedDataId={selectedDataId}
      />
      <DeletePaymentModal
        modal={removePaymentModal}
        toggle={toggleRemovePaymentModal}
        cardID={removedCardID}
        type={typeOfDelete}
        selectedDataId={selectedDataId}
      />
      {isShowingModal ? (
        <ReceiptModal
          data={
            id
              ? NACHAPhoneMultiModalData(
                  modalData.payment_date,
                  modalData.payment_amount,
                  profileInfo.full_name,
                  modalData.type,
                  accountNumberLastFour(modalData.account_number),
                  true
                )
              : NACHAWebMultiModalData(
                  modalData.payment_frequency,
                  modalData.type,
                  modalData.name_on_check,
                  profileInfo.full_name,
                  modalData.routing_number,
                  accountNumberLastFour(modalData.account_number),
                  true,
                  null,
                  null,
                  null,
                  null
                )
          }
          setShowModal={setIsShowingModal}
          header={<ModalHeader />}
        />
      ) : null}
    </>
  )

}