import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import PropTypes from "prop-types";
import { Link as LinkScroll } from "react-scroll";
import { NavLink as LinkTo } from "react-router-dom";
import { Navbar } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

import { MultitenancyLogoPicker } from "multitenancy/multitenancyLogoPicker";

import "./style.scss";
import {
  NavigationLogoutIcon,
  NavigationBurgerIcon,
} from "../icons/NavigationIcons";

import logOut from "scss/images/log-out.png";
import { navItems } from "./navItems";
import { authenticatedAdminNavItems } from "./authenticatedAdminNavItems";
import { authenticatedAgentNavItems } from "./authenticatedAgentNavItems";
import { authenticatedUserNavItems } from "./authenticatedUserNavItems";
import { goToURL, handleLogOut } from "./helpers";
import { setUserName } from "../../redux/actions/topNav";
import { InfoTooltip } from "../InfoTooltip";

export const TopNavigation = ({
  isAuthenticated,
  isAdminAuthenticated,
  isInfoPage,
}) => {
  const dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();

  const userName = useSelector((state) => state.topNav.userName);
  const isProfileComplete = useSelector(
    (state) => state.topNav.isProfileComplete
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(true);
  const [userRole] = useState(isAuthenticated ? "user" : isAdminAuthenticated);

  useEffect(() => {
    const fetchSelfUsername = async () => {
      if (userRole === "admin" || userRole === "agent") {
        dispatch(setUserName(null, "admin"));
      } else {
        dispatch(setUserName());
      }

      setLoading(false);
    };

    (isAuthenticated || isAdminAuthenticated) && fetchSelfUsername();
  }, [isAuthenticated, isAdminAuthenticated, userRole, dispatch]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGoTo = (link) => {
    history.push(link);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuItems = () => {
    switch (userRole) {
      case "admin":
        return (
          <ul className="top-navigation__links-list top-navigation__links-list--auth list-unstyled d-none d-md-flex">
            {authenticatedAdminNavItems.map((item, i) => {
              return (
                <li key={i}>
                  <LinkTo
                    to={item.link}
                    onClick={() => goToURL(item.link)}
                    activeClassName="active"
                    className="top-navigation__link top-navigation__link--auth"
                  >
                    {item.title}
                  </LinkTo>
                </li>
              );
            })}
          </ul>
        );
      case "agent":
        return (
          <ul className="top-navigation__links-list top-navigation__links-list--auth list-unstyled d-none d-md-flex">
            {authenticatedAgentNavItems.map((item, i) => {
              return (
                <li key={i}>
                  <LinkTo
                    to={item.link}
                    onClick={() => goToURL(item.link)}
                    activeClassName="active"
                    className="top-navigation__link top-navigation__link--auth"
                  >
                    {item.title}
                  </LinkTo>
                </li>
              );
            })}
          </ul>
        );
      default:
        return (
          <ul className="top-navigation__links-list top-navigation__links-list--auth list-unstyled d-none d-md-flex">
            {authenticatedUserNavItems.map((item, i) => {
              return (
                <li key={i}>
                  <LinkTo
                    to={item.link}
                    className="top-navigation__link top-navigation__link--auth"
                  >
                    {item.icon}
                    {item.title}
                    {!isProfileComplete && item.title === "Profile" ? (
                      <InfoTooltip title="Your profile is incomplete! Click here to finish out your profile." />
                    ) : null}
                  </LinkTo>
                </li>
              );
            })}
          </ul>
        );
    }
  };

  const navbarClassName = () =>
    isAuthenticated || isAdminAuthenticated
      ? "top-navigation top-navigation--authenticated"
      : "top-navigation";

  const menuItemClassName = (item) =>
    location.pathname === item.link
      ? "mobile-menu-item active"
      : "mobile-menu-item";
  return (
    <Navbar className={navbarClassName()} expand="md">
      <div className="top-navigation__body">
        <div className="top-navigation__logo">
          <LinkTo to="/" onClick={() => window.location.replace("/")}>
            {/* TODO: update logo component, after implementing multi tenancy */}
            <MultitenancyLogoPicker />
          </LinkTo>
        </div>
        {!isInfoPage && (
          <div className="nav">
            {/* Nav items for not authenticated users */}
            {!isAuthenticated && !isAdminAuthenticated ? (
              <ul className="top-navigation__links-list top-navigation__links-list--homepage list-unstyled">
                {navItems.map((item, i) => {
                  return (
                    <li key={i}>
                      <LinkScroll
                        to={item.link}
                        smooth={true}
                        offset={-70}
                        duration={700}
                      >
                        {item.title}
                      </LinkScroll>
                    </li>
                  );
                })}
              </ul>
            ) : (
              renderMenuItems()
            )}

            {/* Logout for authenticated users */}
            {(isAuthenticated || isAdminAuthenticated) && (
              <div className="nav__user-info">
                <ul className="list-unstyled d-flex">
                  <li>
                    <span className="customer-name">
                      {!loading && userName}
                    </span>
                  </li>
                  <li className="d-none d-md-block">
                    <button
                      onClick={() => handleLogOut(userRole)}
                      className="btn"
                    >
                      {/* <NavigationLogoutIcon /> */}
                      <img src={logOut} alt="logout" />
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
        <Button
          aria-controls="nav-menu"
          aria-haspopup="true"
          onClick={handleMenuClick}
          className="mobile-menu-button"
        >
          <NavigationBurgerIcon />
        </Button>
        
        <Menu
          id="nav-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className="mobile-menu"
        >
          <div className="mobile-profile-info">
            <h3 className="heading heading-body-bold">
              {!loading && userName}
            </h3>
            <button onClick={() => handleLogOut(userRole)} className="btn">
              <NavigationLogoutIcon />
            </button>
          </div>
          {authenticatedUserNavItems.map((item, i) => (
            <MenuItem
              className={menuItemClassName(item)}
              onClick={() => handleGoTo(item.link)}
              key={i}
            >
              {item.icon}
              {item.title}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </Navbar>
  );
};

TopNavigation.propTypes = {
  isAuthenticated: PropTypes.bool,
  isAdminAuthenticated: PropTypes.string,
  isInfoPage: PropTypes.bool,
};
