import {
  FETCH_PROFILE,
  EDIT_PROFILE,
  SAVE_PROFILE,
  CANCEL_EDITING,
  UPDATE_PROFILE_FORM,
  UPDATE_EMPLOYER_FORM,
  FETCH_CONSUMER_STRATEGY,
  FETCH_CONSUMER_COMM_COUNT,
  SET_ERRORS,
  SET_THIRD,
  SET_EMP,
  SET_CONSUMER_PROFILE_COMPLETE,
  SET_PROFILE_SAVED,
  SET_OLD_USER_EMAIL,
  EDIT_EMPLOYER_PROFILE,
} from "../types";

import {
  getConsumerProfile,
} from "../../lib/api/common";

import {
  getAgentProfileData,
  getUserProfileData,
  updateAgentProfile,
  updateConsumerEmployer,
  updateUserProfile,
  getConsumerStrategy
} from "../../lib/api/admin"

import {
  updateClientEmployer,
  updateClientProfile
} from "../../lib/api/consumer";

import { showLoader, hideLoader } from "./app";

import { fetchSubscriptionStatus } from "./consumerSubscription";

import { setUserName } from "./topNav";

// PROFILE
export function fetchConsumerProfile(consumerId) {
  return async (dispatch) => {
    dispatch(showLoader());
    const response = await getConsumerProfile({ client_id: consumerId });

    if (response.success === "True") {
      const isProfileComplete =
        (!!response.data.profile.cell_phone ||
          !!response.data.profile.home_phone) &&
        !!response.data.profile.email &&
        !!response.data.employer.employer_full_name;
      dispatch({
        type: SET_CONSUMER_PROFILE_COMPLETE,
        payload: isProfileComplete,
      });
      dispatch({ type: FETCH_PROFILE, payload: response.data });
    } else {
      // eslint-disable-next-line
      console.log("Error: ", response);
    }
    dispatch(fetchConsumerStrategy(consumerId));

    dispatch(hideLoader());
  };
}

export function fetchAdminProfile() {
  return async (dispatch) => {
    dispatch(showLoader());
    const response = await getUserProfileData();
    if (response.success === "True") {
      dispatch({ type: FETCH_PROFILE, payload: response.data });
    } else {
      // eslint-disable-next-line
      console.log("Error: ", response);
    }
    dispatch(hideLoader());
  };
}

export function fetchAgentProfile(agentId) {
  return async (dispatch) => {
    dispatch(showLoader());
    const response = await getAgentProfileData(agentId);
    if (response.success === "True") {
      dispatch({ type: FETCH_PROFILE, payload: response.data });
    } else {
      // eslint-disable-next-line
      console.log("Error: ", response);
    }
    dispatch(hideLoader());
  };
}

export function editProfile() {
  return { type: EDIT_PROFILE };
}

export function saveAgentData(data, agentId) {
  return async (dispatch) => {
    dispatch(showLoader());
    const response = await updateAgentProfile(data, agentId);

    if (response.success === "True") {
      dispatch(setFormErrors([]));
      dispatch({ type: SAVE_PROFILE });
      // dispatch(setUserName());
      dispatch(handleCancelEditingConsumer());
    } else {
      dispatch(setFormErrors(response.errors));
      // eslint-disable-next-line
      console.log("Error: ", response);
    }
    dispatch(hideLoader());
  };
}

export function saveConsumerData(data, consumerId) {
  return async (dispatch) => {
    dispatch(showLoader());
    dispatch(setProfileSaved(false));
    const response = await updateClientProfile({
      ...data,
      client_id: consumerId,
    });

    if (response.success === "True") {
      dispatch(setFormErrors([]));
      dispatch({ type: SAVE_PROFILE });
      !consumerId && dispatch(setUserName());
      dispatch(setProfileSaved(true));
      dispatch(handleCancelEditingConsumer());
      dispatch(fetchConsumerProfile(consumerId));
      (!!data.email || data.email === "") &&
        dispatch(setOldUserEmail(data.email));
      !!consumerId && dispatch(fetchSubscriptionStatus(consumerId));
    } else {
      dispatch(setFormErrors(response.errors));
    }
    dispatch(hideLoader());
  };
}

export function saveEmployerData(data, consumerId) {
  return async (dispatch) => {
    let response;
    dispatch(showLoader());
    if (consumerId) {
      response = await updateConsumerEmployer({
        client_id: consumerId,
        employer: data,
      });
    } else {
      response = await updateClientEmployer({
        employer: data,
      });
    }

    if (response.success === "True") {
      dispatch(setFormErrors([]));
      dispatch({ type: SAVE_PROFILE });
      !consumerId && dispatch(setUserName());
      dispatch(handleCancelEditingConsumer());
      dispatch(fetchConsumerProfile(consumerId));
    } else {
      dispatch(setFormErrors(response.errors));
      // eslint-disable-next-line
      console.log("Error: ", response);
    }
    dispatch(hideLoader());
  };
}

export function saveAdminData(data) {
  return async (dispatch) => {
    dispatch(showLoader());
    const response = await updateUserProfile(data);

    if (response.success === "True") {
      dispatch(setFormErrors([]));
      dispatch({ type: SAVE_PROFILE });
      dispatch(setProfileSaved(true));
      dispatch(handleCancelEditingConsumer());
      dispatch(fetchAdminProfile());
    } else {
      dispatch(setFormErrors(response.errors));
      // eslint-disable-next-line
      console.log("Error: ", response);
    }
    dispatch(hideLoader());
  };
}

export function handleCancelEditingConsumer() {
  return { type: CANCEL_EDITING };
}

export function updateProfileForm(data) {
  return { type: UPDATE_PROFILE_FORM, payload: data };
}

export function updateEmployerForm(data) {
  return { type: UPDATE_EMPLOYER_FORM, payload: data };
}

export function fetchConsumerStrategy(consumerId) {
  return async (dispatch) => {
    const response = await getConsumerStrategy({ client_id: consumerId });
    if (response.success === "True") {
      dispatch({ type: FETCH_CONSUMER_STRATEGY, payload: response.strategy });
      dispatch({ type: FETCH_CONSUMER_COMM_COUNT, payload: response.comm_count });
      dispatch(handleCancelEditingConsumer());
    } else {
      // eslint-disable-next-line
      console.log("Error: ", response);
    }
  };
}

export function setFormErrors(errors) {
  return { type: SET_ERRORS, payload: errors };
}

export function setEmpErrors(errors) {
  return { type: SET_EMP, payload: errors };
}

export function setThirdPartyErrors(errors) {
  return { type: SET_THIRD, payload: errors };
}

export function setProfileSaved(profileStatus) {
  return { type: SET_PROFILE_SAVED, payload: profileStatus };
}

export function setOldUserEmail(email) {
  return { type: SET_OLD_USER_EMAIL, payload: email };
}

export function editEmployer() {
  return { type: EDIT_EMPLOYER_PROFILE };
}
