import sendRequest from "./sendRequest";

// OPTOUT
export const getSubscriptionsStatus = (id) => {
  return sendRequest(`/profile/subscriptions/${id}`, "GET");
};

export const setSubscriptionsStatus = (body, id) => {
  return sendRequest(`/profile/subscriptions/${id}`, "POST", body);
};

// Example: {client_id: "b044463e-85a5-41aa-bf0a-72b00beb084d"}
export const getBalance = (body) => {
  return sendRequest(`/client/balance`, "POST", body);
};

export const getSelfUsername = () => {
  return sendRequest(`/profile/self`, "GET");
};

// TAGS
export const getActiveTags = () => sendRequest(`/notes/active-tags`, "GET");

export const getArchivedTags = () => sendRequest(`/notes/archived-tags`, "GET");

export const createNewTag = (body) =>
  sendRequest(`/notes/add-tag`, "POST", body);

export const archiveTag = (body) =>
  sendRequest(`/notes/archive-tag`, "POST", body);

export const activateTag = (body) =>
  sendRequest(`/notes/activate-tag`, "POST", body);

export const renameTag = (body) =>
  sendRequest(`/notes/rename-tag`, "POST", body);

// NOTES
export const getAllNotes = (body) => sendRequest(`/notes/all`, "POST", body);

export const deleteNotes = (body) => 
  sendRequest(`/notes/note`, "DELETE", body);

export const addNewNote = (body) =>
  sendRequest(`/notes/create-note`, "POST", body);

// PROFILE
export const getUserProfileData = () => sendRequest(`/profile/self`, "GET");

export const getUserLocation = () => sendRequest(`/user/location`, "GET");

export const updateUserProfile = async (body) => {
  if (body.requestData.profile.email) {
    const response = await sendRequest(
      `/profile/update`,
      "PUT",
      body.requestData
    );
    const token = response.token;

    const role = JSON.parse(localStorage.getItem("userData")).role.toString();

    localStorage.setItem(
      "userData",
      JSON.stringify({
        token,
        role,
      })
    );

    return response;
  } else {
    return await sendRequest(`/profile/update`, "PUT", body.requestData);
  }
};

// CONSUMER
// Example: {search: "", status: "all", current_page: 1, per_page: 10}
export const getConsumers = (body) => {
  return sendRequest(`/profile/clients`, "POST", body);
};

// export const updateConsumerProfile = (body) =>
//   sendRequest(`/client/self`, "PUT", body);

export const updateConsumerEmployer = (body) =>
  sendRequest(`/client/update-employer`, "PUT", body);

export const getConsumerPossiblePlans = (body) => {
  return sendRequest(`/client/possible-plans`, "POST", body);
};

export const getConsumerPossiblePlans1 = (body) => {
  return sendRequest(`/client/possible-plans1`, "POST", body);
};

export const getConsumerSavedBankAccounts = (body) => {
  return sendRequest(`/client/saved-bank-accounts`, "POST", body);
};

// AGENT
export const getAgents = (body) => sendRequest(`/profile/roles`, "POST", body);

export const unlockProfile = (body) => sendRequest('/profile/unlock', "POST", body);

export const getAgentProfileData = (id) => {
  return sendRequest(`/profile/agents/${id}`, "GET");
};

export const removeAgent = (body) => {
  return sendRequest(`/profile/deactivate`, "POST", body);
};

export const createNewAgent = (body) => {
  return sendRequest(`/profile/new-agent`, "POST", body);
};

export const resetPassword = (id) => {
  return sendRequest(`/profile/agents/reset-password/${id}`, "POST");
};

export const changePassword = (body) => {
  return sendRequest(`/user/change-password`, "POST", body);
};

export const updateAgentProfile = (body, id) => {
  return sendRequest(`/profile/agents/update/${id}`, "PUT", body);
};

export const isAdmin = () => {
  return JSON.parse(localStorage.getItem("userData")).role;
};

export const getConsumerDebtInfo = (body) => {
  return sendRequest(`/client/debt`, "POST", body);
};

export const getConsumerPlanInfo = (body) => {
  return sendRequest(`/client/plan`, "POST", body);
};

// export const addConsumerCard = (body) => {
//   return sendRequest(`/client/add-card`, "POST", body);
// };

export const activateConsumers = () => {
  return sendRequest(`/profile/create-consumers`, "GET");
};

// MANAGE CONSUMER PLANS
export const calculatePlan = (body, id) => {
  return sendRequest(`/profile/client/${id}/calculate-plan`, "POST", body);
};

// Example data:  { “plan_type”: “Monthly”, “data_id”: “481M10063", “discount”: “0", “number_of_payments”: “70", “tot_amt_to_pay”: 85.86, “tot_mo_payment”: 12.26 }
export const addCustomPlan = (body, id) => {
  return sendRequest(`/profile/client/${id}/add-custom-plan`, "POST", body);
};

export const getConsumerHistory = (body) => {
  return sendRequest(`/profile/client/history`, "POST", body);
};

export const getConsumerStrategy = (body) => {
  return sendRequest(`/client/strategy`, "POST", body);
};

// FILE UPLOAD
export const uploadFile = (body) => {
  return sendRequest(`/uploads/file-upload`, "POST", body);
};

export const getUploadedFileSummary = () => {
  return sendRequest(`/uploads/file-upload`, "GET");
};

export const removeUploadedFile = () => {
  return sendRequest(`/uploads/file-upload`, "DELETE");
};

export const saveUploadedFile = (body) => {
  return sendRequest(`/uploads/file-upload`, "PUT", body);
};

export const checkTemporaryUploadStatus = () => {
  return sendRequest(`/uploads/temporary-upload-status`, "GET");
};

export const checkPermanentUploadStatus = () => {
  return sendRequest(`/uploads/permanent-upload-status`, "GET");
};

// Demand letters
export const getDemandLetter = (body) => {
  return sendRequest(`/demand-letter`, "POST", body);
};
