import React from "react";

import { PageHeader } from "../../../../components/PageHeader";
import { ProfileBody } from "../../../../components/ProfileBody";
import { Helmet } from "react-helmet";

import { multitenancyCompanyShortName } from 'multitenancy/multitenancyContactInfo';

import "./style.scss";

export const AddNewAgent = () => {
  return (
    <div className="add-new-agent profile-page-container">
      <Helmet>
        <title>New Agent – {multitenancyCompanyShortName()}</title>
      </Helmet>
      <PageHeader
        title="New Agent"
        backURL="/admin/roles"
        backTitle="Roles"
      />
      <div className="tab-container">
        <div className="tab-container__column">
          <div className="avatar-wrapper">
            <h2 className="heading heading-2">New Agent</h2>
            <h6 className="heading heading-caption-bold">From Feb 28, 2020</h6>
          </div>
        </div>
        <ProfileBody profileType="new-agent" />
      </div>
    </div>
  );
};
