export const formattingPhoneNumber = (phoneNumberString) => {

  if (phoneNumberString === null) {
    return null;
  }

  if (phoneNumberString?.length > 14) {
    phoneNumberString = phoneNumberString.slice(0, 14);
  }

  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  } else {
    return phoneNumberString;
  }
}

export const formattingZipCode = (value) => {

  if (!value) {
    return
  }

  if (value?.length >= 10) {
    value = value.slice(0, 10);
  }

  let cleaned = value?.split('-').join('');
  let finalValue = cleaned.match(/.{1,5}/g)?.join('-');

  return finalValue;
}

export const unformattedZipCode = (value) => {
  
  if (!value) {
    return
  }

  if (value?.length >= 10) {
    value = value.slice(0, 10);
  }

  return value?.split('-').join('');
}

// export const formattedPhoneNumber = (value, previousValue) => {
//   if (!value) return value;
//   const currentValue = value.replace(/[^\d]/g, "");
//   const cvLength = currentValue.length;

//   if (!previousValue || value.length > previousValue.length) {
//     if (cvLength < 4) return currentValue;
//     if (cvLength < 7)
//       return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
//     return `${currentValue.slice(0, 3)}-${currentValue.slice(
//       3,
//       6
//     )}-${currentValue.slice(6, 12)}`;
//   }
// };

// export const formatUSNumber = (entry = "") => {
//   if (entry === null) {
//     return null;
//   }
//   if (entry.length > 12) {
//     entry = entry.slice(0, 12);
//   }
//   const match = entry
//     .replace(/\D+/g, "")
//     .replace(/^1/, "")
//     .match(/([^\d]*\d[^\d]*){1,10}$/);
//   const matched = (!!match && match[0]) || "";
//   const part1 = matched.length > 2 ? `${matched.substring(0, 3)}` : matched;
//   const part2 = matched.length > 3 ? `-${matched.substring(3, 6)}` : "";
//   const part3 = matched.length > 6 ? `-${matched.substring(6, 10)}` : "";
//   return `${part1}${part2}${part3}`;
// };

export const unformattedPhoneNumber = (value) => {
  
  if (!value) {
    return
  }
  if (value?.length > 14) {
    value = value.slice(0, 14);
  }

  if (value === null) {
    return null;
  }
  return value?.split("(").join("").split(")").join("").split(" ").join("").split("-").join("");
};

export const formattedMoney = (value) => {
  if (value < 0) {
    return value;
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(value).substring(1);
};

export const formattedSSN = (ssn) => {
  if (ssn) {
    const newSSN = ssn.substring(ssn.length - 4, ssn.length);
    return `###-##-${newSSN}`;
  } else {
    return "";
  }
};

export const capitalizedLastName = (name) => {
  // Check if it is a double last name
  if (name.indexOf("-") !== -1) {
    return name.replace(/\w\S*/g, function (txt) {
      return (
        txt.charAt(0).toUpperCase() +
        txt.slice(1, txt.indexOf("-") + 1) +
        txt.charAt(txt.indexOf("-") + 1).toUpperCase() +
        txt.slice(txt.indexOf("-") + 2)
      );
    });
  }

  return name.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const formattedCards = (data) => {
  const formatted = [];
  if (!data.length) {
    return [];
  }

  data.map((card) => {
    formatted.push({
      id: card.Id,
      cardFullName: card.Description,
      cardNumber: card.CardName.slice(-4),
      expirationDate: `${card.Expiry.Month}/${card.Expiry.Year.toString().slice(
        2
      )}`,
      cardType: card.CardName.slice(0, -4).trim(),
      DefaultForPayments: card.DefaultForPayments,
    });
    return null;
  });

  return formatted;
};

export const formattedHistory = (data) => {
  const history = [];
  data.map((item) =>
    history.push({ date: item.updated_at, details: [item.changes] })
  );

  return history;
};

export const formatedDebtOriginator = (data) => {
  const orginator = [];
  data.map((item) => orginator.push(item.orginator || ""));

  return orginator;
};

export const formatedDebtAccountNumber = (data) => {
  const acc_no = [];
  data.map((item) => acc_no.push(item.acc_no || ""));

  return acc_no;
};

export const formatedDebtReference = (data) => {
  const reference = [];
  data.map((item) => reference.push(item.reference || ""));

  return reference;
};

export const formatedDebtStatus = (data) => {
  const debt_status = [];
  data.map((item) => debt_status.push(item.debt_status || ""));

  return debt_status;
};

export const returnLastFiveChar = (data) => {
  return data.slice(data.length - 5);
}