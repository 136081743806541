import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Close } from "@material-ui/icons";
import { useAlert } from "react-alert";

import { LoadingSpinner } from "../../../../components/LoadingSpinner";

import { submitExtraPayment } from "../../../../lib/api/common";

import { SuccessPaymentModal } from "../SuccessPaymentModal";
import { ChangePaymentSuccess } from "../SelectPaymentMethodModal/ChangePaymentSuccess";

import { PayPalUrlGeneration } from "multitenancy/multitenancyPayPal";

import { useTranslation } from 'react-i18next';

import "./style.scss";

let count = 0;

export const AddExtraPaymNewPayPalModal = ({modal, toggle, parentModal, parentToggle, debt, paymentAmount, isChangePaymentMethod}) => {
  
  const { t } = useTranslation();

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const [successPaymentModal, setSuccessPaymentMethod] = useState(false);
  const toggleSuccessPayment = () => setSuccessPaymentMethod(!successPaymentModal);
  const [selectedPaymentObject, setSelectedPaymentObject] = useState({});
  const [changePaymentSuccessModal, setChangePaymentSuccessModal] = useState(false);
  const toggleChangePaymentSuccessModal = () => setChangePaymentSuccessModal(!changePaymentSuccessModal);

  useEffect(() => {
    if (debt && modal) {
      window.addEventListener("message", receiveExtraPaymentMessage, false);
    }

  }, [modal]);
  // catch iframe authorize click

  async function receiveExtraPaymentMessage(event) {
    if (count > 0) return;

    if (event.data === "PAYMENT_SUCCESS") { 
      count++;
      addPaymentCardToConsumer();
    }
  }

  const openSuccessModal = () => {
    toggleSuccessPayment();
  }

  const addPaymentCardToConsumer = async () => {

    if (isChangePaymentMethod) {
      toggleChangePaymentSuccessModal();
    } else {
      makeExtraPaymentForDebitCard();
    }

  };

  const makeExtraPaymentForDebitCard = async(cardDetails) => {
    await new Promise((r) => setTimeout(r, 4000));
    let data;
    data = await submitExtraPayment({
      client_id: id,
      payment_method: "paypal",
      card_id: cardDetails?.card_id,
      debt_id: debt.debt_id,
      amount: paymentAmount,
      agreement_timestamp: new Date(),
    });

    if (data.success === "True") {
      setLoading(false);
      setSelectedPaymentObject(data.paypal_details);
      openSuccessModal();
    } else {
      setLoading(false);
      alert.error(data.message);
    }
  }

  const closeParentModal = () => {
    if (parentModal) {
      parentToggle();
    }
  }

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        scrollable={true}
        onOpened={closeParentModal}
        className="add-new-payment-modal"
      >
        <ModalBody>
          <div className="mod-header">
            <span>{t("Payment Details")}</span>
            <button className="close-modal-btn" onClick={() => toggle()}>
              <Close />
            </button>
          </div>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <iframe id="iframe-modal" src={PayPalUrlGeneration()} title="Add Card" frameBorder="0"/>
          )}
        </ModalBody>
      </Modal>
      {!isChangePaymentMethod ? (
        <SuccessPaymentModal
          modal={successPaymentModal}
          selectedPaymentObject={selectedPaymentObject}
          parentModal={modal}
          parentToggle={toggle}
          paymentAmount={paymentAmount}
          debt={debt}
        />
      ) : null }
      {isChangePaymentMethod && (
        <ChangePaymentSuccess
          modal={changePaymentSuccessModal}
          parentModal={modal}
          parentToggle={toggle}
        />
      )}
    </>
  )
}

AddExtraPaymNewPayPalModal.propTypes = {
  parentModal: PropTypes.bool,
  parentToggle: PropTypes.any,
  toggle: PropTypes.func,
  modal: PropTypes.bool,
  debt: PropTypes.object,
  paymentAmount: PropTypes.string,
  isChangePaymentMethod: PropTypes.bool
}