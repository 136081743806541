const generateSessionId = () => {
  return '_' + Math.random().toString(36).substr(2, 9);
}

export const wtError = () => { 
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "wtError",
  });
}

export const PaymentPlanSelectPlanType = () => { 
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "paymentPlanSelectPlanType",
  });
}

export const PaymentPlanConfirmPaymentDate = () => { 
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "paymentPlanConfirmPaymentDate",
  });
}

export const Error404 = () => { 
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "400Error",
  });
}

export const LoginSuccessful3PointVerification = (data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "loginSuccessful3PointVerification",
    userID: data?.user_id,
    ga_session_id: generateSessionId(),
  });
}

export const LoginSuccessfulGoogleAccount = (data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "loginSuccessfulGoogleAccount",
    userID: data.user_id,
    ga_session_id: generateSessionId(),
  });
}

export const LoginSuccessfulAccessCode = (data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "loginSuccessfulAccessCode",
    userID: data.user_id,
    ga_session_id: generateSessionId(),
  });
}

export const FailedPaymentDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "paymentFailed",
  });
}

export const PaymentPlanSelectedGeneral = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "paymentPlanSelected-General",
  });
}

export const ResolveAccountDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "resolveAccount",
  });
}

export const planSelectedOtpDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "paymentPlanSelectedOTP",
  });
};

export const planSelected12MonthDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "paymentPlanSelected12mo",
  });
};

export const planSelected24MonthDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "paymentPlanSelected24mo",
  });
};

export const planSelected36MonthDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "paymentPlanSelected36mo",
  });
};

export const planSelected48MonthDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "paymentPlanSelected48mo",
  });
};

export const planSelectedSinglePaymenthDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "paymentPlanSelectedSinglePay",
  });
};

export const reviewPlanDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "reviewPlanCompleted",
  });
};

export const paymentDateDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "paymentDateSelected",
  });
};

export const paymentMethodDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "paymentMethodSelected",
  });
};

export const paymentMethodAddedACHDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "bankAccountAdded",
  });
};

export const paymentMethodAddedCardDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "creditCardAdded",
  });
};

export const paymentSuccessfulDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "paymentSuccessful",
  });
};
