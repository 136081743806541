import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { PaymentHistoryTable } from "./PaymentHistoryTable";
import { ExtraPaymentModal } from "./ExtraPaymentFlow/ExtraPaymentModal";
import { LoadingSpinner } from "../../components/LoadingSpinner";

import { PaymentsPagination } from "./PaymentsPagination";
import { CustomButton } from "components/ui-kit/CustomButtom";

import { multitenancyPhone } from "multitenancy/multitenancyContactInfo";

import { 
  getNewPaymentSchedule, 
  getNewAdminPaymentSchedule, 
  getNewAdminPaymentHistory, 
  getNewPaymentHistory 
} from "lib/api/common";

import { 
  planSelectedSinglePaymenthDataLayer,
} from "lib/helpers/dataLayers";

import { fetchSavedPayPal, fetchSavedDebitCards, fetchSavedBankAccounts } from "redux/actions/consumerSavedPaymentMethod";

import { useTranslation } from 'react-i18next';

import "./style.scss";

export const PaymentsHistoryContainer = ({debt, selectedTab, setNearestScheduleDate}) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id } = useParams();
  const [currentHisPage, setCurrentHisPage] = useState(1);
  const [currentSchePage, setCurrentSchePage] = useState(1);

  const [showTable, setShowTable] = useState("history");
  const [oneTimePaymentModal, setOneTimePaymentModal] = useState(false);
  const toggleOneTimePaymentModal = () => setOneTimePaymentModal(!oneTimePaymentModal);

  const [paymentSchedule, setPaymentSchedule] = useState();
  const [paymentHistory, setPaymentHistory] = useState();
  const [loading, setLoading] = useState(false);

  const initialPagination = {
    page: 1,
    per_page: 5,
  }
  useEffect(() => {
    getNewPaymentHistoryInfo(debt, initialPagination);
    if (debt.status === "established" || debt.status === "delinquent") {
      getNewPaymentScheduleInfo(debt, initialPagination);
    }
    // fetch for consumer
    if (debt.status !== "active" && selectedTab === undefined) {
      dispatch(fetchSavedPayPal(id, debt.referenceNumber));
      dispatch(fetchSavedDebitCards(id, debt.referenceNumber));
      dispatch(fetchSavedBankAccounts(id, debt.referenceNumber));
    }
  }, []);

  useEffect(() => {
    // refetch payment on admin tab change
    if (debt.status !== "active" && selectedTab === "1") {
      dispatch(fetchSavedPayPal(id, debt.referenceNumber));
      dispatch(fetchSavedDebitCards(id, debt.referenceNumber));
      dispatch(fetchSavedBankAccounts(id, debt.referenceNumber));
    }
  }, [selectedTab])

  const getNewPaymentScheduleInfo = async (debt, pagination) => {
    setLoading(true);
    if (id) {
      const data = await getNewAdminPaymentSchedule({
        client_id: id,
        debt_id: debt.debt_id,
      }, pagination);
      if (data.success === "True") {
        setPaymentSchedule(data.data);
        setLoading(false);
      }
    } else {
      const data = await getNewPaymentSchedule({
        debt_id: debt.debt_id,
      }, pagination);
      if (data.success === "True") {
        setPaymentSchedule(data.data);
        setNearestScheduleDate(data.next_payment_date);
        setLoading(false);
      }
    }
  }

  const getNewPaymentHistoryInfo = async (debt, pagination) => {
    setLoading(true);
    if (id) {
      const data = await getNewAdminPaymentHistory({
        client_id: id,
        debt_id: debt.debt_id,
      }, pagination);
      if (data.success === "True") {
        setPaymentHistory(data.data);
        setLoading(false);
      }
    } else {
      const data = await getNewPaymentHistory({
        debt_id: debt.debt_id,
      }, pagination);
      if (data.success === "True") {
        setPaymentHistory(data.data);
        setLoading(false);
      }
    }
  }

  const setCurrentHistoryPage = async(page) => {
    await setCurrentHisPage(page);
    await getNewPaymentHistoryInfo(debt, {page: page, per_page:5});
  }

  const setCurrentSchedulePage = async(page) => {
    await setCurrentSchePage(page);
    await getNewPaymentScheduleInfo(debt, {page: page, per_page:5});
  }

  const openExtraPaymentModal = () => {
    planSelectedSinglePaymenthDataLayer();
    toggleOneTimePaymentModal();
  }

  return (
    <>
      {loading ? (<LoadingSpinner middleFixed={true}/>) : null}
      {paymentHistory?.items_number > 0 || paymentSchedule?.items_number > 0? (
        <div className="payments-history-container">
          <div className="header">
            <div className="title">
              {debt.status === "established" ? <span>{t("Payments")}</span> : <span>{t("Payment History")}</span>}
            </div>
            {debt.status === "established" || debt.status === "delinquent" ? (
              <div className="btn-container">
                <div className="payment-history-tab-container">
                  <div
                    className={
                      showTable === "history"
                        ? "payment-history-tab-title active"
                        : "payment-history-tab-title"
                    }
                    onClick={() => setShowTable("history")}
                  >
                    {t("History")}
                  </div>
                  <div
                    className={
                      showTable === "schedule"
                        ? "payment-history-tab-title active"
                        : "payment-history-tab-title"
                    }
                    onClick={() => setShowTable("schedule")}
                  >
                    {t("Upcoming Payments")}
                  </div>
                </div>
                <CustomButton
                  // type="secondary"
                  optionalClass="otp-btn"
                  text={t("Make a One-Time Payment")}
                  onClickHandler={() => openExtraPaymentModal()}
                />
              </div>
            ) : null}
          </div>
          <div className="body">
            {showTable === "history" ? (
                <PaymentHistoryTable data={paymentHistory?.page} showTable={showTable} debt={debt}/>
              ) : (
                <PaymentHistoryTable data={paymentSchedule?.page} showTable={showTable} debt={debt}/>
              )
            }
            <div className="footer-container">
              {showTable === "history" && paymentHistory?.items_number > 5 ? (
                <PaymentsPagination
                  currentPage={currentHisPage}
                  setCurrentPage={setCurrentHistoryPage}
                  pagesCount={(paymentHistory?.pagination &&
                    paymentHistory.pagination.page_range) || 1}
                />
              ) : null }
              {showTable === "schedule" && paymentSchedule?.items_number > 5 ? (
                <PaymentsPagination
                  currentPage={currentSchePage}
                  setCurrentPage={setCurrentSchedulePage}
                  pagesCount={(paymentSchedule?.pagination &&
                    paymentSchedule.pagination.page_range) || 1}
                />
              ) : null}

              {debt.status === "established" || debt.status === "delinquent" ? (
                <>
                  <Button 
                    className="one-time-payment-btn-mobile"
                    onClick={() => openExtraPaymentModal()}
                  >
                    {t("Make a One-Time Payment")}
                  </Button>
                  <span className="info-block">
                    {t("To cancel an upcoming payment, please contact us at")} {multitenancyPhone()}
                  </span>
                </>
              ) : null}
            </div>
            <ExtraPaymentModal 
              toggle={toggleOneTimePaymentModal}
              modal={oneTimePaymentModal}
              debt={debt}
            />
          </div>
        </div>
      ) : null}
    </>
  )

}