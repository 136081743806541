import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Helmet } from "react-helmet";
import { useAlert } from "react-alert";

// import { PageHeader } from "../../components/PageHeader";
import { TableContainer } from "../../components/TableContainer";
import { getAgents, unlockProfile } from "../../lib/api/admin";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { SearchContainer } from "../../components/TableContainer/components/TableCustomControls/SearchContainer";
import { TableShowRows } from "../../components/TableContainer/components/TableCustomControls/TableShowRows";
import { TableShowPages } from "../../components/TableContainer/components/TableCustomControls/TableShowPages";
import { tableHeaders } from "./tableHeadersData";
import { formatedAgentsData } from "./helpers/formatters";
import { CustomButton } from "../../components/ui-kit/CustomButtom";

import { multitenancyCompanyShortName } from 'multitenancy/multitenancyContactInfo';

import "./style.scss";

export const Agents = () => {
  const alert = useAlert();
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showPerPage, setShowPerPage] = useState(10);
  const [pagination, setPagination] = useState();
  const [agentsAmount, setAgentsAmount] = useState();

  const fetchAgents = async (page, search, perPage) => {
    setLoading(true);
    const data = await getAgents({
      search: search || searchText,
      current_page: page || currentPage,
      per_page: perPage || showPerPage,
    });

    if (data.success === "True") {
      setAgentsData(formatedAgentsData(data.data.page));
      setPagination(data.data.pagination);
      setAgentsAmount(data.data.agents_number);
    } else {
      alert.error(`Something went wrong. ${data.message}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAgents();
    // eslint-disable-next-line
  }, []);

  const handleShowPerPage = async (pages) => {
    setShowPerPage(pages);
    setCurrentPage(1);
    await fetchAgents(1, searchText, pages);
  };

  const AddAgentButton = () => {
    let history = useHistory();
    return (
      <CustomButton
        text="Add New Agent"
        onClickHandler={() => history.push("/admin/new-agent")}
      />
    );
  };

  const [agentsData, setAgentsData] = useState([]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleEnterPressed = () => {
    setCurrentPage(1);
    fetchAgents(1, searchText);
  };

  const unlockProf = async(id) => {
    const preparedRequest = {
      id: id
    }
    const data = await unlockProfile(preparedRequest);
    if (data.success === "True") {
      alert.error(data.message);
    } else {
      alert.error(data.message);
    }
  }

  const setCurrentTablePage = async (page) => {
    setCurrentPage(page);
    await fetchAgents(page, searchText);
  };

  return (
    <div className="agents">
      <Helmet>
        <title>Roles - {multitenancyCompanyShortName()}</title>
      </Helmet>
      {/* <PageHeader title="Roles" addAgentButton={<AddAgentButton />} /> */}
      <div className="main-container">
        <div className="header-container">
          <div className="title">Roles</div>
          {<AddAgentButton />}
        </div>
        {loading ? (
          <LoadingSpinner middleFixed={true}/>
        ) : (
          <TableContainer
            tableControls={
              <div className="table-controls">
                <SearchContainer
                  handleSearchChange={handleSearchChange}
                  handleEnterPressed={handleEnterPressed}
                  searchText={searchText}
                />
                <TableShowRows
                  showPerPage={showPerPage}
                  handleShowPerPage={handleShowPerPage}
                />
                <TableShowPages
                  setCurrentTablePage={setCurrentTablePage}
                  currentPage={currentPage}
                  pagination={pagination}
                  itemsAmount={agentsAmount}
                  showPerPage={showPerPage}
                />
              </div>
            }
            tableHeaders={tableHeaders}
            data={agentsData}
            currentPage={currentPage}
            setCurrentPage={setCurrentTablePage}
            unlockProfile={unlockProf}
            pagesCount={pagination.page_range}
          />
        )}
      </div>
    </div>
  );
};
