import React, { useState } from "react";
import PropTypes from "prop-types";

import Modal from "@material-ui/core/Modal";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import "./style.scss";
import { SystemCloseIcon16 } from "../icons/SystemIcons";
import { createNewTag, renameTag } from "../../lib/api/admin";
import { CustomButton } from "../ui-kit/CustomButtom";
import { SingleTag } from "./components/SingleTag";

export const TagsBody = ({
  headerTitle,
  activeTags,
  archivedTags,
  notesTags,
  fetchActiveTags,
  fetchArchivedTags,
  setSelectedNoteTags,
  loading,
}) => {
  const initialSelectedTags = [];
  const [tags] = useState(activeTags || archivedTags || notesTags);
  const [selectedTags, setSelectedTags] = useState(initialSelectedTags);
  const [open, setOpen] = useState(false);
  const [newTagName, setNewTagName] = useState("");
  const [oldTagName, setOldTagName] = useState("");
  const [newTagCategory, setNetTagCategory] = useState("Call Intro");
  const [tagToRename, setTagToRename] = useState("");
  const [modalType, setModalType] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleNewTag = () => {
    setModalType("new-tag");
    setOpen(true);
  };

  const handleChangeName = (e) => {
    setNewTagName(e.target.value);
  };

  const handleChangeCategory = (e) => {
    setNetTagCategory(e.target.value);
  };

  const handleCreateNewTag = async () => {
    handleClose();
    await createNewTag({ name: newTagName.trim(), category: newTagCategory });
    await fetchActiveTags();
  };

  const handleRenameTag = async () => {
    await renameTag({ old_name: oldTagName, new_name: tagToRename });

    await fetchActiveTags();
  };

  const handleSelectTag = async (name) => {
    let newSelectedTags = [...selectedTags];
    if (!activeTags && !archivedTags) {
      await setSelectedTags(name);
      if (selectedTags.indexOf(name) >= 0) {
        newSelectedTags.splice(selectedTags.indexOf(name), 1);
        await setSelectedTags(newSelectedTags);
        await setSelectedNoteTags(newSelectedTags);
      } else {
        newSelectedTags.push(name);
        await setSelectedTags(newSelectedTags);
        await setSelectedNoteTags(newSelectedTags);
      }
    } else {
      return;
    }
  };

  const TagsBodyModal =
    modalType === "rename" ? (
      <div className="tags-body__modal">
        <div className="modal__header">
          <h3 className="heading heading-3">Rename tag</h3>
          <button onClick={() => handleClose()} className="button-transparent">
            <SystemCloseIcon16 />
          </button>
        </div>
        <div className="modal__body">
          <label className="heading heading-caption-bold" htmlFor="tag-name">
            Tag name
          </label>
          <input
            id="tag-name"
            type="text"
            className="input input-medium"
            value={tagToRename}
            onChange={(e) => setTagToRename(e.target.value)}
          />
        </div>
        <div className="modal__footer">
          <CustomButton
            type="secondary"
            text="Cancel"
            onClickHandler={handleClose}
          />
          <CustomButton text="Rename" onClickHandler={handleRenameTag} />
        </div>
      </div>
    ) : (
      <div className="tags-body__modal">
        <div className="modal__header">
          <h3 className="heading heading-3">New tag</h3>
          <button onClick={() => handleClose()} className="button-transparent">
            <SystemCloseIcon16 />
          </button>
        </div>
        <div className="modal__body">
          <div className="form-group">
            <label
              // component="tag-name"
              className="heading heading-caption-bold"
            >
              Tag name
            </label>
            <input
              id="tag-name"
              type="text"
              className="input input-medium"
              onChange={handleChangeName}
            />
          </div>
          <div className="form-group">
            <FormControl component="fieldset">
              <label
                // component="legend"
                className="heading heading-caption-bold"
              >
                Category
              </label>
              <RadioGroup
                aria-label="category"
                name="category"
                value={newTagCategory}
                onChange={handleChangeCategory}
              >
                <FormControlLabel
                  value="Call Intro"
                  control={<Radio color="primary" />}
                  label="Call Intro"
                />
                <FormControlLabel
                  value="OB Specific Tags"
                  control={<Radio color="primary" />}
                  label="OB Specific Tags"
                />
                <FormControlLabel
                  value="Call Detail"
                  control={<Radio color="primary" />}
                  label="Call Detail"
                />
                <FormControlLabel
                  value="Action"
                  control={<Radio color="primary" />}
                  label="Action"
                />
                <FormControlLabel
                  value="Letters"
                  control={<Radio color="primary" />}
                  label="Letters"
                />
                <FormControlLabel
                  value="Added Action"
                  control={<Radio color="primary" />}
                  label="Added Action"
                />
                <FormControlLabel
                  value="Manager Required"
                  control={<Radio color="primary" />}
                  label="Manager Required"
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio color="primary" />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="modal__footer">
          <CustomButton
            type="secondary"
            text="Cancel"
            onClickHandler={handleClose}
          />
          <CustomButton text="Create" onClickHandler={handleCreateNewTag} />
        </div>
      </div>
    );

  return (
    <div className="tags-body">
      <div className="d-flex justify-content-between">
        {headerTitle && <h2 className="heading heading-2">{headerTitle}</h2>}
        {activeTags && (
          <CustomButton text="New Tag" onClickHandler={handleNewTag} />
        )}
      </div>
      <div className="tags-body__main">
        <div className="tags-body__block">
          <h4 className="heading heading-body-bold">Call Intro</h4>
          <div className="tags-body__tags">
            {loading ? (
              <div>Loading</div>
            ) : (
              tags["Call Intro"] &&
              tags["Call Intro"].map((tag, i) => (
                <SingleTag
                  key={i}
                  id={tag.id}
                  selected={selectedTags.indexOf(tag) >= 0}
                  clickHandler={handleSelectTag}
                  name={tag}
                  fetchActiveTags={fetchActiveTags}
                  fetchArchivedTags={fetchArchivedTags}
                  setOldTagName={setOldTagName}
                  setModalType={setModalType}
                  setOpen={setOpen}
                  setTagToRename={setTagToRename}
                  activeTags={activeTags}
                  archivedTags={archivedTags}
                />
              ))
            )}
          </div>
        </div>
        <div className="tags-body__block">
          <h4 className="heading heading-body-bold">OB Specific Tags</h4>
          <div className="tags-body__tags">
            {loading ? (
              <div>Loading</div>
            ) : (
              tags["OB Specific Tags"] &&
              tags["OB Specific Tags"].map((tag, i) => (
                <SingleTag
                  key={i}
                  id={tag.id}
                  selected={selectedTags.indexOf(tag) >= 0}
                  clickHandler={handleSelectTag}
                  name={tag}
                  fetchActiveTags={fetchActiveTags}
                  fetchArchivedTags={fetchArchivedTags}
                  setOldTagName={setOldTagName}
                  setModalType={setModalType}
                  setOpen={setOpen}
                  setTagToRename={setTagToRename}
                  activeTags={activeTags}
                  archivedTags={archivedTags}
                />
              ))
            )}
          </div>
        </div>
        <div className="tags-body__block">
          <h4 className="heading heading-body-bold">Call Detail</h4>
          <div className="tags-body__tags">
            {loading ? (
              <div>Loading</div>
            ) : (
              tags["Call Detail"] &&
              tags["Call Detail"].map((tag, i) => (
                <SingleTag
                  key={i}
                  id={tag.id}
                  selected={selectedTags.indexOf(tag) >= 0}
                  clickHandler={handleSelectTag}
                  name={tag}
                  fetchActiveTags={fetchActiveTags}
                  fetchArchivedTags={fetchArchivedTags}
                  setOldTagName={setOldTagName}
                  setModalType={setModalType}
                  setOpen={setOpen}
                  setTagToRename={setTagToRename}
                  activeTags={activeTags}
                  archivedTags={archivedTags}
                />
              ))
            )}
          </div>
        </div>
        <div className="tags-body__block">
          <h4 className="heading heading-body-bold">Action</h4>
          <div className="tags-body__tags">
            {loading ? (
              <div>Loading</div>
            ) : (
              tags["Action"] &&
              tags["Action"].map((tag, i) => (
                <SingleTag
                  key={i}
                  id={tag.id}
                  selected={selectedTags.indexOf(tag) >= 0}
                  clickHandler={handleSelectTag}
                  name={tag}
                  fetchActiveTags={fetchActiveTags}
                  fetchArchivedTags={fetchArchivedTags}
                  setOldTagName={setOldTagName}
                  setModalType={setModalType}
                  setOpen={setOpen}
                  setTagToRename={setTagToRename}
                  activeTags={activeTags}
                  archivedTags={archivedTags}
                />
              ))
            )}
          </div>
        </div>
        <div className="tags-body__block">
          <h4 className="heading heading-body-bold">Letters</h4>
          <div className="tags-body__tags">
            {loading ? (
              <div>Loading</div>
            ) : (
              tags["Letters"] &&
              tags["Letters"].map((tag, i) => (
                <SingleTag
                  key={i}
                  id={tag.id}
                  selected={selectedTags.indexOf(tag) >= 0}
                  clickHandler={handleSelectTag}
                  name={tag}
                  fetchActiveTags={fetchActiveTags}
                  fetchArchivedTags={fetchArchivedTags}
                  setOldTagName={setOldTagName}
                  setModalType={setModalType}
                  setOpen={setOpen}
                  setTagToRename={setTagToRename}
                  activeTags={activeTags}
                  archivedTags={archivedTags}
                />
              ))
            )}
          </div>
        </div>
        <div className="tags-body__block">
          <h4 className="heading heading-body-bold">Added Action</h4>
          <div className="tags-body__tags">
            {loading ? (
              <div>Loading</div>
            ) : (
              tags["Added Action"] &&
              tags["Added Action"].map((tag, i) => (
                <SingleTag
                  key={i}
                  id={tag.id}
                  selected={selectedTags.indexOf(tag) >= 0}
                  clickHandler={handleSelectTag}
                  name={tag}
                  fetchActiveTags={fetchActiveTags}
                  fetchArchivedTags={fetchArchivedTags}
                  setOldTagName={setOldTagName}
                  setModalType={setModalType}
                  setOpen={setOpen}
                  setTagToRename={setTagToRename}
                  activeTags={activeTags}
                  archivedTags={archivedTags}
                />
              ))
            )}
          </div>
        </div>
        <div className="tags-body__block">
          <h4 className="heading heading-body-bold">Manager Required</h4>
          <div className="tags-body__tags">
            {loading ? (
              <div>Loading</div>
            ) : (
              tags["Manager Required"] &&
              tags["Manager Required"].map((tag, i) => (
                <SingleTag
                  key={i}
                  id={tag.id}
                  selected={selectedTags.indexOf(tag) >= 0}
                  clickHandler={handleSelectTag}
                  name={tag}
                  fetchActiveTags={fetchActiveTags}
                  fetchArchivedTags={fetchArchivedTags}
                  setOldTagName={setOldTagName}
                  setModalType={setModalType}
                  setOpen={setOpen}
                  setTagToRename={setTagToRename}
                  activeTags={activeTags}
                  archivedTags={archivedTags}
                />
              ))
            )}
          </div>
        </div>
        <div className="tags-body__block">
          <h4 className="heading heading-body-bold">Other</h4>
          <div className="tags-body__tags">
            {loading ? (
              <div>Loading</div>
            ) : (
              tags["Other"] &&
              tags["Other"].map((tag, i) => (
                <SingleTag
                  key={i}
                  id={tag.id}
                  selected={selectedTags.indexOf(tag) >= 0}
                  clickHandler={handleSelectTag}
                  name={tag}
                  fetchActiveTags={fetchActiveTags}
                  fetchArchivedTags={fetchArchivedTags}
                  setOldTagName={setOldTagName}
                  setModalType={setModalType}
                  setOpen={setOpen}
                  setTagToRename={setTagToRename}
                  activeTags={activeTags}
                  archivedTags={archivedTags}
                />
              ))
            )}
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {TagsBodyModal}
      </Modal>
    </div>
  );
};

TagsBody.propTypes = {
  headerTitle: PropTypes.string,
  activeTags: PropTypes.any,
  archivedTags: PropTypes.any,
  notesTags: PropTypes.object,
  fetchActiveTags: PropTypes.func,
  fetchArchivedTags: PropTypes.func,
  setSelectedNoteTags: PropTypes.func,
  loading: PropTypes.bool,
};
