import React from "react";

import "./style.scss";

import WaythruLogo from "components/ReceiptModal/assets/receipt_logo2.png";
import ConsuegraLogo from "scss/images/consuegra-logo.png";
import WayLogo from "scss/images/logo_W.svg";
import trombergLogo from "scss/images/TMP_Logo.png";

import investinetEsign from "scss/pdf/investinet_esign.pdf";
import consuegraEsing from "scss/pdf/consueg_esign.pdf";

import { useTranslation } from 'react-i18next';

const hostname = window.location.hostname.toLowerCase();

export const multitenancyTerms = () => {

  const { t } = useTranslation();

  switch (hostname) {
    case "waythru-test1.com":
      return (
        <a target="_blank" className="heading heading-caption-bold" href="https://www.investinet.com/terms-and-conditions" rel="noopener noreferrer" >
          {t('Terms and Conditions')}
        </a>
      );
    case "investinet.waythru.com":
      return (
        <a target="_blank" className="heading heading-caption-bold" href="https://www.investinet.com/terms-and-conditions" rel="noopener noreferrer" >
          {t('Terms and Conditions')}
        </a>
      );
    case "consuegra-test.waythru-test1.com":
      return (
        <a target="_blank" className="heading heading-caption-bold" href="https://www.consuegralaw.com/assets/ConsuegraLaw-TermsConditions.pdf" rel="noopener noreferrer" >
          {t('Terms and Conditions')}
        </a>
      );
    case "consuegralaw.waythru.com":
      return (
        <a target="_blank" className="heading heading-caption-bold" href="https://www.consuegralaw.com/assets/ConsuegraLaw-TermsConditions.pdf" rel="noopener noreferrer" >
          {t('Terms and Conditions')}
        </a>
      );
    case "waythru-test2.com":
      return (
        <a target="_blank" className="heading heading-caption-bold" href="https://waythru.com/terms-and-conditions" rel="noopener noreferrer" >
          {t('Terms and Conditions')}
        </a>
      );
    case "demo.waythru.com":
      return (
        <a target="_blank" className="heading heading-caption-bold" href="https://waythru.com/terms-and-conditions" rel="noopener noreferrer" >
          {t('Terms and Conditions')}
        </a>
      );
    case "tmppllc-test.waythru-test1.com":
      return (
        <a target="_blank" className="heading heading-caption-bold" href="https://www.tmppllc.com/privacy_policy" rel="noopener noreferrer" >
          {t('Terms and Conditions')}
        </a>
      );
    case "tmppllc.waythru.com":
      return (
        <a target="_blank" className="heading heading-caption-bold" href="https://www.tmppllc.com/privacy_policy" rel="noopener noreferrer" >
          {t('Terms and Conditions')}
        </a>
      );
  }
}

export const multitenancyPrivacy = () => {

  const { t } = useTranslation();

  switch (hostname) {
    case "waythru-test1.com":
      return (
        <a target="_blank" className="heading heading-caption-bold" href="https://www.investinet.com/privacy-policy" rel="noopener noreferrer" >
          {t('Privacy Policy')}
        </a>
      );
    case "investinet.waythru.com":
      return (
        <a target="_blank" className="heading heading-caption-bold" href="https://www.investinet.com/privacy-policy" rel="noopener noreferrer" >
          {t('Privacy Policy')}
        </a>
      );
    case "consuegra-test.waythru-test1.com":
      return (
        <a target="_blank" className="heading heading-caption-bold" href="https://www.consuegralaw.com/assets/ConsuegraLaw-PrivacyPolicy.pdf" rel="noopener noreferrer" >
          {t('Privacy Policy')}
        </a>
      );
    case "consuegralaw.waythru.com":
      return (
        <a target="_blank" className="heading heading-caption-bold" href="https://www.consuegralaw.com/assets/ConsuegraLaw-PrivacyPolicy.pdf" rel="noopener noreferrer" >
          {t('Privacy Policy')}
        </a>
      );
    case "waythru-test2.com":
      return (
        <a target="_blank" className="heading heading-caption-bold" href="https://waythru.com/privacy-policy" rel="noopener noreferrer" >
          {t('Privacy Policy')}
        </a>
      );
    case "demo.waythru.com":
      return (
        <a target="_blank" className="heading heading-caption-bold" href="https://waythru.com/privacy-policy" rel="noopener noreferrer" >
          {t('Privacy Policy')}
        </a>
      );
    case "tmppllc-test.waythru-test1.com":
      return (
        <a target="_blank" className="heading heading-caption-bold" href="https://www.tmppllc.com/privacy_policy" rel="noopener noreferrer" >
          {t('Privacy Policy')}
        </a>
      );
    case "tmppllc.waythru.com":
      return (
        <a target="_blank" className="heading heading-caption-bold" href="https://www.tmppllc.com/privacy_policy" rel="noopener noreferrer" >
          {t('Privacy Policy')}
        </a>
      );
  }
}

export const multitenancyEsign = () => {

  const { t } = useTranslation();

  switch (hostname) {
    case "waythru-test1.com":
      return (
        <a href={investinetEsign} rel="noopener noreferrer" target="_blank">{t("E-Sign Disclosure")}</a>
      );
    case "investinet.waythru.com":
      return (
        <a href={investinetEsign} rel="noopener noreferrer" target="_blank">{t("E-Sign Disclosure")}</a>
      );
    case "consuegra-test.waythru-test1.com":
      return (
        <a href={consuegraEsing} rel="noopener noreferrer" target="_blank">{t("E-Sign Disclosure")}</a>
      );
    case "consuegralaw.waythru.com":
      return (
        <a href={consuegraEsing} rel="noopener noreferrer" target="_blank">{t("E-Sign Disclosure")}</a>
      );
    case "waythru-test2.com":
      return (
        <a href={investinetEsign} rel="noopener noreferrer" target="_blank">{t("E-Sign Disclosure")}</a>
      );
    case "demo.waythru.com":
      return (
        <a href={investinetEsign} rel="noopener noreferrer" target="_blank">{t("E-Sign Disclosure")}</a>
      );
    case "tmppllc-test.waythru-test1.com":
      return (
        <a href={investinetEsign} rel="noopener noreferrer" target="_blank">{t("E-Sign Disclosure")}</a>
      );
    case "tmppllc.waythru.com":
      return (
        <a href={investinetEsign} rel="noopener noreferrer" target="_blank">{t("E-Sign Disclosure")}</a>
      );
  }
}

export const multitenancyReceiptTemplateLogo = () => {
  switch (hostname) {
    case "waythru-test1.com":
      return WaythruLogo;
    case "investinet.waythru.com":
      return WaythruLogo;
    case "consuegra-test.waythru-test1.com":
      return ConsuegraLogo;
    case "consuegralaw.waythru.com":
      return ConsuegraLogo;
    case "waythru-test2.com":
      return WayLogo;
    case "demo.waythru.com":
      return WayLogo;
    case "tmppllc-test.waythru-test1.com":
      return trombergLogo;
    case "tmppllc.waythru.com":
      return trombergLogo;
  }
}

export const multitenancyPhone = () => {
  switch (hostname) {
    case "waythru-test1.com":
      return "(888) 852-0054";
    case "investinet.waythru.com":
      return "(888) 852-0054";
    case "consuegra-test.waythru-test1.com":
      return "(866) 798-9110";
    case "consuegralaw.waythru.com":
      return "(866) 798-9110";
    case "waythru-test2.com":
      return "(888) 852-0054";
    case "demo.waythru.com":
      return "(888) 852-0054";
    case "tmppllc-test.waythru-test1.com":
      return "(800) 280-6205";
    case "tmppllc.waythru.com":
      return "(800) 280-6205";
}
}


export const multitenancyHours = () => {
  switch (hostname) {
    case "waythru-test1.com":
      return "Monday through Thursday 8:00 AM to 8:00 PM EST or Friday 8:00 AM to 4:00 PM EST";
    case "investinet.waythru.com":
      return "Monday through Thursday 8:00 AM to 8:00 PM EST or Friday 8:00 AM to 4:00 PM EST";
    case "consuegra-test.waythru-test1.com":
      return "Monday - Friday 9:00 AM - 5:00 PM";
    case "consuegralaw.waythru.com":
      return "Monday - Friday 9:00 AM - 5:00 PM";
    case "waythru-test2.com":
      return "Monday through Thursday 8:00 AM to 8:00 PM EST or Friday 8:00 AM to 4:00 PM EST";
    case "demo.waythru.com":
      return "Monday through Thursday 8:00 AM to 8:00 PM EST or Friday 8:00 AM to 4:00 PM EST";
    case "tmppllc-test.waythru-test1.com":
      return "Monday to Friday, Eastern Standard Time 9 am - 5 pm";
    case "tmppllc.waythru.com":
      return "Monday to Friday, Eastern Standard Time 9 am - 5 pm";
  }
}

export const multitenancyTelUrl= () => {
  switch (hostname) {
    case "waythru-test1.com":
      return "tel:+18888520054";
    case "investinet.waythru.com":
      return "tel:+18888520054";
    case "consuegra-test.waythru-test1.com":
      return "tel:+18667989110";
    case "consuegralaw.waythru.com":
      return "tel:+18667989110";
    case "waythru-test2.com":
      return "tel:+18888520054";
    case "demo.waythru.com":
      return "tel:+18888520054";
    case "tmppllc-test.waythru-test1.com":
      return "tel+18002806205";
    case "tmppllc.waythru.com":
      return "tel+18002806205";
  }
}

export const multitenancyCompanyName = () => {
  switch (hostname) {
    case "waythru-test1.com":
      return "InvestiNet LLC.";
    case "investinet.waythru.com":
      return "InvestiNet LLC.";
    case "consuegra-test.waythru-test1.com":
      return "Consuegra & Duffy, P.L.L.C.";
    case "consuegralaw.waythru.com":
      return "Consuegra & Duffy, P.L.L.C.";
    case "waythru-test2.com":
      return "Waythru Innovations, LLC";
    case "demo.waythru.com":
      return "Waythru Innovations, LLC";
    case "tmppllc-test.waythru-test1.com":
      return "Tromberg, Morris & Poulin, PLLC";
    case "tmppllc.waythru.com":
      return "Tromberg, Morris & Poulin, PLLC";
  }
}

export const multitenancyCompanyShortName = () => {
  switch (hostname) {
    case "waythru-test1.com":
      return "Investinet";
    case "investinet.waythru.com":
      return "Investinet";
    case "consuegra-test.waythru-test1.com":
      return "Consuegra";
    case "consuegralaw.waythru.com":
      return "Consuegra";
    case "waythru-test2.com":
      return "Waythru";
    case "demo.waythru.com":
      return "Waythru";
    case "tmppllc-test.waythru-test1.com":
      return "Tromberg";
    case "tmppllc.waythru.com":
      return "Tromberg";
  }
}