import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import PasswordChecklist from "react-password-checklist"

import { ProfileMediumTextInput } from "../../../components/ProfileBody/ProfileMediumTextInput";
import { updateChangePasswordForm } from "../../../redux/actions/changePassword";

export const ChangePasswordForm = () => {
  const dispatch = useDispatch();
  const changePasswordForm = useSelector(
    (state) => state.changePassword.changePasswordForm
  );

  const [profileError] = useState([]);

  const [isValidFord, setIsValidForm] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("")
	const [passwordAgain, setPasswordAgain] = useState("")

  const handleChange = (e) => {

    if (e.target.name === "password") {
      setOldPassword(e.target.value);
      if (isValidFord && e.target.value.length > 0) {
        dispatch(
          updateChangePasswordForm({
            ...changePasswordForm,
            [e.target.name]: e.target.value,
            save_btn: false
          })
        );
      } else {
        dispatch(
          updateChangePasswordForm({
            ...changePasswordForm,
            [e.target.name]: e.target.value,
            save_btn: true
          })
        );
      }
    }

    if (e.target.name === "new_password") {
      setPassword(e.target.value);
      dispatch(
        updateChangePasswordForm({
          ...changePasswordForm,
          [e.target.name]: e.target.value,
        })
      );
    }

    if (e.target.name === "confirm_password") {
      setPasswordAgain(e.target.value);
      dispatch(
        updateChangePasswordForm({
          ...changePasswordForm,
          [e.target.name]: e.target.value,
        })
      );
    }
  };

  const onValidFord = (isValid) => {
    setIsValidForm(isValid)
    if (isValid && oldPassword.length > 0) {
      dispatch(
        updateChangePasswordForm({
          ...changePasswordForm,
          save_btn: false
        })
      );
    } else {
      dispatch(
        updateChangePasswordForm({
          ...changePasswordForm,
          save_btn: true
        })
      );
    }
  }

  return (
    <div className="profile-form__body">
      <div className="profile-form__block">
        <div className="profile-form__error">
          {profileError.length > 0 && profileError}
        </div>
        <div className="profile-password-checklist">
          <span>Please match all these rules to change the password</span>
          <PasswordChecklist
            rules={["minLength","specialChar","number","capital","match"]}
            minLength={8}
            value={password}
            valueAgain={passwordAgain}
            onChange={(isValid) => onValidFord(isValid)}
          />
        </div>
        <div className="profile-form__container">
          <div className="profile-form-group form-group">
            <label className="heading heading-caption-bold" htmlFor="password">
              Old Password
            </label>
            <ProfileMediumTextInput
              disabled={false}
              onChange={(e) => handleChange(e)}
              name={"password"}
            />
          </div>
        </div>
        <div className="profile-form__container">
          <div className="profile-form-group form-group">
            <label
              className="heading heading-caption-bold"
              htmlFor="new_password"
            >
              New Password
            </label>
            <ProfileMediumTextInput
              value={changePasswordForm.new_password}
              name={"new_password"}
              disabled={false}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="profile-form__container">
          <div className="profile-form-group form-group">
            <label
              className="heading heading-caption-bold"
              htmlFor="confirm_password"
            >
              Confirm New Password
            </label>
            <ProfileMediumTextInput
              value={changePasswordForm.confirm_password}
              name="confirm_password"
              disabled={false}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
