import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { useAlert } from "react-alert";

import { useRoutes } from "./routes";
import { useAuth } from "./hooks/auth.hook";
import { AuthContext } from "./context/AuthContext";
import { useCookies } from "hooks/useCookies";
import { handleLogOut } from "./components/TopNavigation/helpers"
import "./scss/main.scss";
import { Cookies } from "components/Cookies";

import { multitenancyColorMapper } from "./multitenancy/multitenancyColorMapper";

const hostname = window.location.hostname.toLowerCase();

let maxExpirationTime = 1000 * 60 * 30; // 30 mins

if (hostname === "consuegra-test.waythru-test1.com" || hostname === "consuegralaw.waythru.com") {
  maxExpirationTime = 1000 * 60 * 60; // 60 minutes
}

function App() {
  const { cookiesAccepted, handleAcceptCookies } = useCookies();
  const alert = useAlert();
  const { token, login, logout, userId, role, firstLogin } = useAuth();

  const isAuthenticated = !!token;
  const routes = useRoutes(isAuthenticated, role, firstLogin);
  
  // start need for multitenancy colors 
  const root = document.documentElement;
  
  useEffect(() => {
    multitenancyColorMapper.map((item) => {
      if (hostname === item.hostname) {
        root?.style.setProperty("--customWhite01", item.customWhite01);
        root?.style.setProperty("--customBlue", item.customBlue);
      }
    })
  }, []);
  // end multitenancy color
  
  window.onoffline = () => {
    alert.error(
      "Your network connection is lost. Please check your network and reload the page.",
      {
        timeout: 3000,
      }
    );
  };

  window.ononline = () => {
    alert.show("You are back online. Please restart the page.");
  };

  function setExpirationTimer() {
    const expirationDate = new Date(new Date().getTime()).toISOString();
    const storageExpiration =
      JSON.parse(localStorage.getItem("userData")) &&
      JSON.parse(localStorage.getItem("userData")).expirationTime;
    if (expirationDate >= storageExpiration) {
      handleLogOut("admin");
    }
  }

  if (
    (JSON.parse(localStorage.getItem("userData")) &&
      JSON.parse(localStorage.getItem("userData")).role === "admin") ||
    (JSON.parse(localStorage.getItem("userData")) &&
      JSON.parse(localStorage.getItem("userData")).role === "agent")
  ) {
    setExpirationTimer();
  }

  if (role === "admin" || role === "agent") {
    document.addEventListener("click", () => {
      const expirationDate = new Date(new Date().getTime() + maxExpirationTime);
      const data = JSON.parse(localStorage.getItem("userData"));
      if (!data) {
        return;
      }
      data.expirationTime = expirationDate.toISOString();
      localStorage.setItem(
        "userData",
        JSON.stringify({
          ...data,
        })
      );
      clearInterval(timer);
      timer = setInterval(setExpirationTimer, 1000 * 60 * 2);
    });
    let timer = setInterval(setExpirationTimer, 1000 * 60 * 2);
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        login,
        logout,
        userId,
        isAuthenticated,
        role,
        firstLogin,
      }}
    >
      <Router>
        <div className="app">{routes}</div>
        {!cookiesAccepted ? (
          <Cookies handleAcceptCookies={handleAcceptCookies} />
        ) : null}
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
