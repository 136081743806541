import React from "react";

import GoDaddyLogo from "./images/godaddy.png";
import EntrustLogo from "./images/entrust.png";
import moment from "moment";

import { MultitenancyLogoPicker } from "multitenancy/multitenancyLogoPicker";
import { multitenancyTerms, multitenancyPrivacy } from "multitenancy/multitenancyContactInfo";

import { useTranslation } from 'react-i18next';

import ReactFlagsSelect from 'react-flags-select';

import "./style.scss";

export const ConsumerFooter = ({page}) => {

  const { t, i18n } = useTranslation();

  const lang = localStorage.getItem("selectedLanguage");

  if (!lang) {
    localStorage.setItem("selectedLanguage", "US");
    i18n.changeLanguage("US");
  }

  const handleChangeLanguage = (code) => {
    i18n.changeLanguage(code);
    localStorage.setItem("selectedLanguage", code);
  }

  return (
    <div className="consumer-footer">
      <div className="top-blocks">
        <div className="links-container">
          <div className="logo">
            <MultitenancyLogoPicker />
          </div>
          <div className="links">
            { window.location.hostname.toLowerCase() === "waythru-test2.com" || 
              window.location.hostname.toLowerCase() === "investinet.waythru.com" || 
              window.location.hostname.toLowerCase() === "waythru-test1.com" && (
                <a className="heading heading-caption-bold"
                  href="https://www.nmlsconsumeraccess.org/"
                  target="_blank" 
                  rel="noopener noreferrer" 
                >
                  NMLS ID: 937604
                </a>
              )
            }
            {multitenancyTerms()}
            {multitenancyPrivacy()}
            {page !== "/unsubscribe" && (
              <a href="/help-center" className="heading">{t("Help Center")}</a>
            )}
          </div>
        </div>
        <div className="security-images">
          <img className="go-daddy" src={GoDaddyLogo} alt="GoDaddyLogo logo" />
          <img className="entrust" src={EntrustLogo} alt="EntrustLogo logo" />
        </div>
      </div>
      <div className="bottom-blocks">
        <div className="lang-cont">
          <ReactFlagsSelect
            className="language-dropdown"
            selected={lang}
            onSelect={handleChangeLanguage}
            countries={["US", "ES"]}
            customLabels={{"US": "ENG","ES": "ESP"}}
            placeholder="Select"
          />
          <span className="info">
            {t("This communication is from a debt collector and is an attempt to collect a debt. Any information obtained will be used for that purpose.")}
          </span>
        </div>
        <span className="copyright">{t("© All rights reserved,")} {moment(Date()).format("YYYY")}</span>
      </div>
    </div>
  )
}