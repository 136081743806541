import React from "react";
import {
  NavigationConsumersIcon,
  NavigationProfileIcon,
} from "../icons/NavigationIcons";

export const authenticatedAgentNavItems = [
  {
    title: "Customers",
    link: "/admin/consumers",
    iconURL: "#",
    icon: <NavigationConsumersIcon />,
  },
  // {
  //   title: "Disputes",
  //   link: "/admin/disputes",
  //   iconURL: "#",
  //   icon: <NavigationConsumersIcon />,
  // },
  {
    title: "My Profile",
    link: "/admin/profile",
    iconURL: "#",
    icon: <NavigationProfileIcon />,
  },
];
