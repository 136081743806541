import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

// import { PageHeader } from "../../components/PageHeader";
import { ProfileBody } from "../../components/ProfileBody";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { fetchAdminProfile } from "../../redux/actions/profile";

import { multitenancyCompanyShortName } from 'multitenancy/multitenancyContactInfo';

import "./style.scss";

class ProfilePage extends Component {
  componentDidMount() {
    this.props.fetchAdminProfile();
  }

  render() {
    const { loading, profileData } = this.props;

    return (
      <div className="profile-page-container my-profile">
        <Helmet>
          <title>Profile – {multitenancyCompanyShortName()}</title>
        </Helmet>
        {/* <PageHeader title="Profile" /> */}
        <div className="title">Profile</div>
        {loading ? (
          <LoadingSpinner middleFixed={true}/>
        ) : (
          <div className="tab-container">
            <div className="tab-container__column">
              <div className="avatar-wrapper">
                <h2 className="heading heading-2">
                  {profileData && profileData.full_name}
                </h2>
                <h6 className="heading heading-caption-bold">
                  From Feb 28, 2020
                </h6>
              </div>
            </div>
            <ProfileBody profileData={profileData} profileType="admin" />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.profile.loading,
    profileData: state.profile.profileData,
  };
};

export default connect(mapStateToProps, { fetchAdminProfile })(ProfilePage);

ProfilePage.propTypes = {
  loading: PropTypes.bool,
  profileData: PropTypes.object,
  fetchAdminProfile: PropTypes.func,
};
