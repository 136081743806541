// App
export const SHOW_LOADER = "APP/SHOW_LOADER";
export const HIDE_LOADER = "APP/HIDE_LOADER";

// Admin login
export const DISABLE_BUTTON = "ADMINLOGIN/DISABLE_BUTTON";
export const ENABLE_BUTTON = "ADMINLOGIN/ENABLE_BUTTON";

// Common
export const SET_STEP = "COMMON/SET_STEP";
export const SET_ERROR = "COMMON/SET_ERROR";
export const FETCH_ACTIVE_DEBTS = "COMMON/FETCH_ACTIVE_DEBTS";
export const FETCH_PAYMENT_DEBTS = "COMMON/FETCH_PAYMENT_DEBTS";
export const FETCH_ESTABLISHED_DEBTS = "COMMON/FETCH_ESTABLISHED_DEBTS";
export const FETCH_PAIDOFF_DEBTS = "COMMON/FETCH_PAIDOFF_DEBTS";
export const FETCH_ONHOLD_DEBTS = "COMMON/FETCH_ONHOLD_DEBTS";
export const FETCH_RECALLED_DEBTS = "COMMON/FETCH_RECALLED_DEBTS";
export const FETCH_DELINQUENT_DEBTS = "COMMON/FETCH_DELINQUENT_DEBTS";

// 
export const FETCH_ACTIVE_ACCOUNTS = "COMMON/FETCH_ACTIVE_ACCOUNTS";
export const FETCH_ARCHIVED_ACCOUNTS = "COMMON/FETCH_ARCHIVED_ACCOUNTS";
export const FETCH_ONBOARDING_ACCOUNTS = "COMMON/FETCH_ONBOARDING_ACCOUNTS";
export const FETCH_All_ACCOUNTS = "COMMON/FETCH_All_ACCOUNTS";
// 
// export const FETCH_CONSUMER_PAYMENT_SCHEDULE = "COMMON/FETCH_CONSUMER_PAYMENT_SCHEDULE";
// export const FETCH_CONSUMER_PAYMENT_HISTORY = "COMMON/FETCH_CONSUMER_PAYMENT_HISTORY";
// 
export const FETCH_SAVED_PAYPAL = "COMMON/FETCH_SAVED_PAYPAL";
export const FETCH_SAVED_DEBIT_CARDS = "COMMON/FETCH_SAVED_DEBIT_CARDS";
export const FETCH_SAVED_BANK_ACCOUNT = "COMMON/FETCH_SAVED_BANK_ACCOUNT";
// 
export const FETCH_PLAN_INFO = "COMMON/FETCH_PLAN_INFO"

// Setup plan
export const SET_REQUEST = "SETUP_PLAN/SET_REQUEST";
export const SET_DATES = "SETUP_PLAN/SET_DATES";
export const SET_IS_NEXT_BUTTON_DISABLED =
  "SETUP_PLAN/SET_IS_NEXT_BUTTON_DISABLED";
export const SET_PLAN_ID = "SETUP_PLAN/SET_PLAN_ID";

// Balance
export const FETCH_BALANCE = "BALANCE/FETCH_BALANCE";

// Get default Payment Method
export const FETCH_DEFAULT_PAYMENT_METHOD = "FETCH_DEFAULT_PAYMENT_METHOD";

// Single Balance
export const FETCH_SINGLE_BALANCE = "BALANCE/FETCH_SINGLE_BALANCE";

// Consumer Profile
export const SET_EMP = "COMMON/SET_EMP";
export const SET_THIRD = "COMMON/SET_THIRD";
export const SET_ERRORS = "COMMON/SET_ERRORS";
export const FETCH_CONSUMER_PROFILE = "CONSUMER_PROFILE/FETCH_CONSUMER_PROFILE";
export const FETCH_CONSUMER_STRATEGY =
  "CONSUMER_PROFILE/FETCH_CONSUMER_STRATEGY";
export const FETCH_CONSUMER_COMM_COUNT =
  "CONSUMER_PROFILE/FETCH_CONSUMER_COMM_COUNT";
export const EDIT_CONSUMER = "CONSUMER_PROFILE/EDIT_CONSUMER";
export const SAVE_CONSUMER = "CONSUMER_PROFILE/SAVE_CONSUMER";
export const SAVE_EMPLOYER = "CONSUMER_PROFILE/SAVE_EMPLOYER";
export const CANCEL_EDITING = "CONSUMER_PROFILE/CANCEL_EDITING";
export const UPDATE_PROFILE_FORM = "CONSUMER_PROFILE/UPDATE_PROFILE_FORM";
export const UPDATE_EMPLOYER_FORM = "CONSUMER_PROFILE/UPDATE_EMPLOYER_FORM";
export const SET_CONSUMER_PROFILE_COMPLETE =
  "CONSUMER_PROFILE/SET_CONSUMER_PROFILE_COMPLETE";
export const SET_OLD_USER_EMAIL = "CONSUMER_PROFILE/SET_OLD_USER_EMAIL";
export const SET_PROFILE_SAVED = "COMMON/SET_PROFILE_SAVED";

// Admin Profile
export const FETCH_PROFILE = "PROFILE/FETCH_PROFILE";
export const EDIT_PROFILE = "PROFILE/EDIT_PROFILE";
export const EDIT_EMPLOYER_PROFILE = "PROFILE/EDIT_EMPLOYER_PROFILE";
export const SAVE_PROFILE = "PROFILE/SAVE_PROFILE";

// Consumer Payments
export const FETCH_PAYMENTS_DATA = "CONSUMER_PAYMENTS/FETCH_PAYMENTS_DATA";
export const SHOW_PAYMENTS_LOADER = "CONSUMER_PAYMENTS/SHOW_PAYMENTS_LOADING";
export const HIDE_PAYMENTS_LOADER = "CONSUMER_PAYMENTS/HIDE_PAYMENTS_LOADING";
export const SET_NACHA_DATA = "CONSUMER_PAYMENTS/SET_NACHA_DATA";
export const SET_NACHA_MODAL_DATA = "CONSUMER_PAYMENTS/SET_NACHA_MODAL_DATA";
export const SET_PAYMENTS_ESTABLISHED_DEBTS =
  "CONSUMER_PAYMENTS/SET_PAYMENTS_ESTABLISHED_DEBTS";

// Consumer History
export const FETCH_HISTORY_DATA = "CONSUMER_PAYMENTS/FETCH_HISTORY_DATA";
// Forgot Password
export const SET_EMAIL = "FORGOT_PASSWORD/SET_EMAIL";

//Consumer Notes
export const FETCH_CONSUMER_NOTES = "CONSUMER_NOTES/FETCH_CONSUMER_NOTES";
export const FETCH_ACTIVE_TAGS = "CONSUMER_NOTES/FETCH_ACTIVE_TAGS";
export const SET_NOTES_DATES_RANGE = "CONSUMER_NOTES/SET_NOTES_DATES_RANGE";
export const SET_SHOW_PER_PAGE = "CONSUMER_NOTES/SET_SHOW_PER_PAGE";
export const SHOW_DATEPICKER = "CONSUMER_NOTES/SHOW_DATEPICKER";
export const HIDE_DATEPICKER = "CONSUMER_NOTES/HIDE_DATEPICKER";

// Top navigation
export const SET_USERNAME = "TOP_NAVIGATION/SET_USERNAME";
export const SET_PROFILE_COMPLETE = "TOP_NAVIGATION/SET_PROFILE_COMPLETE";
export const SET_PROFILE_INCOMPLETE = "TOP_NAVIGATION/SET_PROFILE_INCOMPLETE";

// Change Password
export const UPDATE_PASSWORD_FORM = "CHANGE_PASSWORD/UPDATE_PASSWORD_FORM";
// export const SAVE_NEW_PASSWORD = "CHANGE_PASSWORD/SAVE_NEW_PASSWORD";

// Consumer Subscription
export const FETCH_SUBSCRIPTION_STATUSES =
  "CONSUMER_SUBSCRIPTION/FETCH_SUBSCRIPTION_STATUSES";
export const SHOW_SUBSCRIPTION_LOADING =
  "CONSUMER_SUBSCRIPTION/SHOW_SUBSCRIPTION_LOADING";
export const HIDE_SUBSCRIPTION_LOADING =
  "CONSUMER_SUBSCRIPTION/HIDE_SUBSCRIPTION_LOADING";
