import React from "react";
import { Modal, ModalBody } from "reactstrap";
import PropTypes from "prop-types";
import { Close } from "@material-ui/icons";

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionActions'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { formattedMoney } from "lib/helpers/formatters";

import { useTranslation } from 'react-i18next';

import "./style.scss";

export const SeeDetailsModal = ({modal, toggle, info, isAdmin}) => {

  const { t } = useTranslation();

  const standardClient = info.display_id === 1 ? true : false;

  const formattedDate = (date) => {
    if (!date) return;
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const dateArray = date.split("-").reverse();
    return `${months[Number(dateArray[1]) - 1]} ${dateArray[0]}, ${
      dateArray[2]
    }`;
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      scrollable={true}
      className="see-details-modal"
    >
      <ModalBody>
        <div className="mod-header">
          <span>{t("Account Details")}</span>
          <button className="close-modal-btn" onClick={() => toggle()}>
            <Close />
          </button>
        </div>
        <div className="mod-body">
          {isAdmin && info.merchant ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <span className="label">Merchant</span>
                <span className="value">{info.merchant}</span>
              </AccordionSummary>
              <AccordionDetails>
                This most commonly references the retailer or store who issued your account. Retailers partner with financial institutions, also referred to as the original creditor, to finance your account.
              </AccordionDetails>
            </Accordion>
          ) : null}
          {isAdmin && info.merchant ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <span className="label">Account Type</span>
                <span className="value">{info.account_type}</span>
              </AccordionSummary>
              <AccordionDetails>
                This is Account Type.
              </AccordionDetails>
            </Accordion>
          ) : null}
          {isAdmin && info.settlement_limit ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <span className="label">Settlement Limit</span>
                <span className="value">{info.settlement_limit}%</span>
              </AccordionSummary>
              <AccordionDetails>
                This is Settlement Limit.
              </AccordionDetails>
            </Accordion>
          ) : null}
          {info.originalCreditor ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <span className="label">{standardClient ? <>{t("Original Creditor")}</> : <>{t("Referring Company")}</>}</span>
                <span className="value">{info.originalCreditor}</span>
              </AccordionSummary>
              <AccordionDetails>
                {standardClient ? <>{t("This is the company you originally owed money to.")}</> : <>{t("This is the brand that owns your account")}</>}
              </AccordionDetails>
            </Accordion>
          ) : null}
          {info.currentCreditor ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <span className="label">{standardClient ? <>{t("Current Creditor")}</> : <>{t("Branch")}</>}</span>
                <span className="value">{info.currentCreditor}</span>
              </AccordionSummary>
              <AccordionDetails>
                {standardClient 
                ? 
                  <>{t("This is the company that now owns your debt. For more information about how debt is bought and sold, see our Rights and Responsibilities page.")}</> 
                : 
                  <>{t("This is the local branch you are familiar with, and to whom you owe money")}</>
                }
              </AccordionDetails>
            </Accordion>
          ) : null}
          {info.referenceNumber ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <span className="label">{t("Reference Number")}</span>
                <span className="value">{info.referenceNumber}</span>
              </AccordionSummary>
              <AccordionDetails>
                {t("This is the number we use in our system to track your account.")}
              </AccordionDetails>
            </Accordion>
          ) : null}
          {info.originalAccountNumber ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <span className="label">{t("Account Number")}</span>
                <span className="value">{info.originalAccountNumber.slice(-4)}</span>
              </AccordionSummary>
              <AccordionDetails>
                {t("This is the number on the account you originally opened.")}
              </AccordionDetails>
            </Accordion>
          ) : null}
          {info.nameOnAccount ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <span className="label">{t("Name on Account")}</span>
                <span className="value">{info.nameOnAccount}</span>
              </AccordionSummary>
              <AccordionDetails>
                {t("This is the name associated with the original account. If this is not you, please let us know.")}
              </AccordionDetails>
            </Accordion>
          ) : null}
          {info.currentInterestRate && standardClient ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <span className="label">{t("Current Interest Rate")}</span>
                <span className="value">{info.currentInterestRate}%</span>
              </AccordionSummary>
              <AccordionDetails>
                {t("This is the legal rate at which your account is currently accruing interest.")}
              </AccordionDetails>
            </Accordion>
          ) : null}
          {info.placementDate ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <span className="label">{t("Placement Date")}</span>
                <span className="value">{formattedDate(info.placementDate)}</span>
              </AccordionSummary>
              <AccordionDetails>
                {t("This is the date the company that owns this account contracted us to work with you to collect on the debt.")}
              </AccordionDetails>
            </Accordion>
          ) : null}
          {info.accountOpenDate ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <span className="label">{t("Account Open Date")}</span>
                <span className="value">{formattedDate(info.accountOpenDate)}</span>
              </AccordionSummary>
              <AccordionDetails>
                {t("This is the date you originally opened this account.")}
              </AccordionDetails>
            </Accordion>
          ) : null}
          {info.chargeOffDate ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <span className="label">{t("Last Payment Date")}</span>
                <span className="value">{formattedDate(info.lastPaymentDate) || ""}</span>
              </AccordionSummary>
              <AccordionDetails>
                {t("This is the date that your most recent payment posted.")}
              </AccordionDetails>
            </Accordion>
          ) : null}
          {info.lastPaymentAmount ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <span className="label">{t("Last Payment Amount")}</span>
                <span className="value">$ {(info.lastPaymentAmount &&
                formattedMoney(info.lastPaymentAmount)) ||
                ""}</span>
              </AccordionSummary>
              <AccordionDetails>
                {t("This is the amount of your last posted payment.")}
              </AccordionDetails>
            </Accordion>
          ) : null}
          {info.accountOpenDate ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <span className="label">{t("Charge Off Date")}</span>
                <span className="value">{formattedDate(info.chargeOffDate)}</span>
              </AccordionSummary>
              <AccordionDetails>
                {t("This is the date your account was written off by the original creditor. For more information about writeoffs, see our Rights and Responsibilities page.")}
              </AccordionDetails>
            </Accordion>
          ) : null}
          {info.lastPaymentDate ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <span className="label">{t("Charge Off Balance")}</span>
                <span className="value">$ {formattedMoney(info.chargeOffBalance)}</span>
              </AccordionSummary>
              <AccordionDetails>
                {t("This was the balance on your account when it was written off.")}
              </AccordionDetails>
            </Accordion>
          ) : null}
          {standardClient ? (
            <>
              {info.judgementDate ? (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <span className="label">{t("Judgment Date")}</span>
                    <span className="value">{formattedDate(info.judgementDate)}</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    {t("This is the date the court rendered the judgment saying you need to repay this money.")}
                  </AccordionDetails>
                </Accordion>
              ) : null}
              {info.judgementCase ? (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <span className="label">{t("Judgment Case")}</span>
                    <span className="value">{info.judgementCase}</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    {t("This is the case number of the judgment saying you need to repay this money.")}
                  </AccordionDetails>
                </Accordion>
              ) : null}
            </>
          ) : null}
        </div>
      </ModalBody>
    </Modal>
  )
}

SeeDetailsModal.propTypes = {
  toggle: PropTypes.func,
  modal: PropTypes.bool,
  info: PropTypes.object
}