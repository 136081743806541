import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";
import { CustomButton } from "components/ui-kit/CustomButtom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";

import { OtherMasterCardIcon, OtherVisaIcon } from "components/icons/OtherIcons";
import amexSvg from "scss/images/amex.svg";
import discoverSVG from "scss/images/discover.svg";
import paypalIcon from "scss/images/paypal.png";
import { LoadingSpinner } from "components/LoadingSpinner";

import { submitOneTimePayment } from "lib/api/common";

import { NewDebitCardModal } from "../NewDebitCardModal";
import { NewBankAccountModal } from "../NewBankAccountModal";
import { NewPayPalModal } from "../NewPayPalModal";

import { FinalDetailsModal } from '../FinalDetailsModal';

import { formattedMoney } from "lib/helpers/formatters";

import { showDependOnTenant } from "multitenancy/multitenancyPayPal";

import "./style.scss";

export const OTPPaymentDetailsModal = ({isModalOpen, toggle, parentModal, parentToggle, debt, paymentAmount}) => {

  const { id } = useParams();

  const alert = useAlert();

  const [selectedPaymentType, setPaymentType] = useState("");
  const [selectedPaymentObject, setSelectedPaymentObject] = useState({});
  const [confirmBtnActive, setConfirmBtnActive] = useState(false);
  const [innerLoading, setLoading] = useState(false);

  const [isFinalDetailsModalOpen, setIsFinalDetailsModalOpen] = useState(false);
  const toggleFinalDetailsModal = () => setIsFinalDetailsModalOpen(!isFinalDetailsModalOpen);

  const [addNewOPTBankAccountModal, setAddNewOPTBankAccountModal] = useState(false);
  const toggleAddNewOPTBankAccountModal = () => setAddNewOPTBankAccountModal(!addNewOPTBankAccountModal);
  const [addNewOTPDebitCardModal, setAddNewOTPDebitCardModal] = useState(false);
  const toggleAddNewOTPDebitCardModal = () => setAddNewOTPDebitCardModal(!addNewOTPDebitCardModal);
  const [addNewPayPalModal, setAddNewPayPalModal] = useState(false);
  const toggleAddNewPayPalModal = () => setAddNewPayPalModal(!addNewPayPalModal);

  const savedPayPalAccounts = useSelector((state) => state.consumerSavedPaymentMethod.savedPayPalAccounts);
  const savedDebitCards = useSelector((state) => state.consumerSavedPaymentMethod.savedDebitCards);
  const savedBankAccount = useSelector((state) => state.consumerSavedPaymentMethod.savedBankAccount);
  const loading = useSelector((state) => state.consumerSavedPaymentMethod.loading);
  const profileInfo = useSelector((state) => state.profile.profileData.profile);

  const handleChangePaymentType = (e, item) => {
    setPaymentType(e.target.id);
    setConfirmBtnActive(true);
    setSelectedPaymentObject(item);
  }

  const renderCardIcon = (cardType, paymentProc) => {

    if (paymentProc === "PayPal") {
      return <img className="card-icon" src={paypalIcon} alt="amex" />
    }

    cardType = cardType.slice(0, -5);
    if (cardType === "MASTERCARD") {
      return <OtherMasterCardIcon />
    }
    if (cardType === "VISA") {
      return <OtherVisaIcon />
    }
    if (cardType === "AMEX") {
      return <img className="card-icon" src={amexSvg} alt="amex" />
    }
    if (cardType === "DISCOVER") {
      return <img className="card-icon" src={discoverSVG} alt="discover" />
    }
  }

  const renderCardNumber = (cardNumber) => {
    cardNumber = cardNumber.slice(-4);
    return (
      <span className="card-number">
        •••• {cardNumber}
      </span> 
    )
  }

  const renderCardTime = (expiry) => {
    const month = expiry.Month;
    const year = expiry.Year.toString().substr(-2);
    return (
      <span className="card-time">
        {month}/{year}
      </span>
    )
  }

  const renderAccountNumber = (accountNumber) => {
    accountNumber = accountNumber.slice(-4);
    return (
      <span className="card-number">
        •••• {accountNumber}
        <span className="card-time"> Account</span>
      </span> 
    )
  }

  const renderBankName = (bankName) => {
    return(
      <span className="card-time">
        {bankName}
      </span>
    )
  }

  const backBtn = () => {
    toggle();
    parentToggle();
  }

  const submitPaymentForAccountNumber = async () => {
    const request = {
      client_id: id,
      account_number: selectedPaymentObject.AccountNumber,
      account_type: "1",
      amount: paymentAmount,
      agreement_timestamp: new Date(),
      bank_name: selectedPaymentObject.BankName,
      card_id: selectedPaymentObject.Id,
      debt_id: debt.debt_id,
      name_on_check: profileInfo.full_name,
      payment_method: "ach",
      routing_number: selectedPaymentObject.RoutingNumber,
    }

    setLoading(true);
    let data = await submitOneTimePayment(request);

    if (data.success === "True") {
      setLoading(false);
      toggleFinalDetailsModal();
    } else {
      setLoading(false);
      alert.error(data.message);
    }
  }

  const submitPaymentForDebitCard = async () => {
    const request = {
      client_id: id,
      agreement_timestamp: new Date(),
      amount: paymentAmount,
      card_id: selectedPaymentObject?.Id,
      debt_id: debt.debt_id,
      payment_method: "card",
    }

    setLoading(true);
    let data = await submitOneTimePayment(request);

    if (data.success === "True") {
      setLoading(false);
      toggleFinalDetailsModal();
    } else {
      setLoading(false);
      alert.error(data.message);
    }
  }

  const submitPaymentForPayPal = async () => {
    const request = {
      client_id: id,
      agreement_timestamp: new Date(),
      amount: paymentAmount,
      card_id: selectedPaymentObject?.Id,
      debt_id: debt.debt_id,
      payment_method: "paypal",
    }

    setLoading(true);
    let data = await submitOneTimePayment(request);

    if (data.success === "True") {
      setLoading(false);
      toggleFinalDetailsModal();
    } else {
      setLoading(false);
      alert.error(data.message);
    }
  }

  const openNewBankAccountModal = () => {
    toggleAddNewOPTBankAccountModal();
  }

  const openNewDebitCardModal = () => {
    toggleAddNewOTPDebitCardModal();
  }

  const openNewPayPalModal = () => {
    toggleAddNewPayPalModal();
  }

  const handleGoNext = () => {
    if (selectedPaymentType === "newDebitCard") {
      openNewDebitCardModal();
    } else if (selectedPaymentType === "newBankAccount") {
      openNewBankAccountModal();
    } else if (selectedPaymentType === "newPayPal") {
      openNewPayPalModal();
    } else {
      if (selectedPaymentObject?.AccountNumber) {
        submitPaymentForAccountNumber();
      } else if (selectedPaymentObject.CardName) {
        submitPaymentForDebitCard();
      } else if (selectedPaymentObject.Email) {
        submitPaymentForPayPal();
      }
    }
  }

  const nextBtnText = () => {
    if (selectedPaymentType === "newDebitCard" || selectedPaymentType === "newBankAccount" || selectedPaymentType === "mailWire" || selectedPaymentType === "newPayPal") {
      return "Next";
    } else {
      return "Confirm Payment Details";
    }
  }

  const closeParentModal = () => {
    if (parentModal) {
      parentToggle();
    }
  }

  const renderSubtitle = () => {
    const diff = debt.plan_balance - paymentAmount;
    return (
      <div className="subtitle">You are about to be charged ${formattedMoney(paymentAmount)} After this one-time payment your current balance on this debt will be ${formattedMoney(diff.toFixed(2))}.</div>
    )
  }

  const checkCountPaymMethod = () => {
    const count = savedBankAccount?.length + savedDebitCards?.length + savedPayPalAccounts?.length
    if (count > 0) {
      return true
    } else {
      return false;
    } 
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onOpened={closeParentModal}
        toggle={toggle}
        scrollable={true}
        className="otp-payment-details-modal"
      >
        {loading || innerLoading ? (<LoadingSpinner middleFixed={true}/>) : null}
        <ModalBody>
          <div className="mod-header">
            <span>Payment Details</span>
            <button className="close-modal-btn" onClick={() => toggle()}>
              <Close />
            </button>
          </div>
          <div className="mod-body">
            {renderSubtitle()}
            <div className="payment-method-block">
              <div className="title">Payment Method</div>
              {savedPayPalAccounts?.map((item, i) => (
              <div key={i} className={`payment-method-container ${selectedPaymentType === item.Id ? "active" : "inactive" }`}>
                <label className="verification-radio">
                  <input
                    type="radio"
                    id={item.Id}
                    name="verificationRadio"
                    onClick={(e) => handleChangePaymentType(e, item)}
                  />
                  <div className="label">
                    <div>
                      {renderCardIcon(item.Email, "PayPal")}
                      <span className="card-number">{item.Email}</span>
                    </div>
                    <span className="card-time">{item.CustomerName}</span>
                  </div>
                  <span className="checkmark"></span>
                </label>
              </div>
            ))}
              {savedDebitCards?.map((item, i) => (
                <div key={i} className={`payment-method-container ${selectedPaymentType === item.Id ? "active" : "inactive" }`}>
                  <label className="verification-radio">
                    <input
                      type="radio"
                      id={item.Id}
                      name="verificationRadio"
                      onClick={(e) => handleChangePaymentType(e, item)}
                    />
                    <div className="label">
                      <div>
                        {renderCardIcon(item.CardName)}
                        {renderCardNumber(item.CardName)}
                      </div>
                      {renderCardTime(item.Expiry)}
                    </div>
                    <span className="checkmark"></span>
                  </label>
                </div>
              ))}
              {savedBankAccount?.map((item, i) => (
                <div key={i} className={`payment-method-container ${selectedPaymentType === item.Id ? "active" : "inactive" }`}>
                  <label className="verification-radio">
                    <input
                      type="radio"
                      id={item.Id}
                      name="verificationRadio"
                      onClick={(e) => handleChangePaymentType(e, item)}
                    />
                    <div className="label">
                      <div>
                        {renderAccountNumber(item.AccountNumber)}
                      </div>
                      {renderBankName(item.BankName)}
                    </div>
                    <span className="checkmark"></span>
                  </label>
                </div>
              ))}
              {checkCountPaymMethod() && (
                <div className="or-block-container">
                  <hr></hr> 
                  <span>or add new</span>
                  <hr></hr>
                </div>
              )}
              {showDependOnTenant(profileInfo) && (
                <div className={`payment-method-container ${selectedPaymentType === "newPayPal" ? "active" : "inactive" }`}>
                  <label className="verification-radio">
                    <input
                      type="radio"
                      id="newPayPal"
                      name="verificationRadio"
                      onClick={(e) => handleChangePaymentType(e)}
                    />
                    <div className="label">PayPal</div>
                    <span className="checkmark"></span>
                  </label>
                </div>
              )}
              <div className={`payment-method-container ${selectedPaymentType === "newDebitCard" ? "active" : "inactive" }`}>
                <label className="verification-radio">
                  <input
                    type="radio"
                    id="newDebitCard"
                    name="verificationRadio"
                    onClick={(e) => handleChangePaymentType(e)}
                  />
                  <div className="label">Debit Card</div>
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className={`payment-method-container ${selectedPaymentType === "newBankAccount" ? "active" : "inactive" }`}>
                <label className="verification-radio">
                  <input
                    type="radio"
                    id="newBankAccount"
                    name="verificationRadio"
                    onClick={(e) => handleChangePaymentType(e)}
                  />
                  <div className="label">Bank Account</div>
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div className="mod-footer">
            <CustomButton
              text="Back"
              onClickHandler={() => backBtn()}
              optionalClass="cancel-btn"
            />
            <CustomButton
              text={nextBtnText()}
              onClickHandler={() => handleGoNext()}
              disabled={!confirmBtnActive || innerLoading}
              optionalClass="confirm-btn"
            />
          </div>
        </ModalBody>
      </Modal>
      {isFinalDetailsModalOpen && (
        <FinalDetailsModal
          isModalOpen={isFinalDetailsModalOpen}
          parentModal={isModalOpen}
          parentToggle={toggle}
          selectedPaymentObject={selectedPaymentObject}
          debt={debt}
          paymentAmount={paymentAmount}
        />
      )}
      {selectedPaymentType === "newDebitCard" && (
        <NewDebitCardModal
          modal={addNewOTPDebitCardModal}
          toggle={toggleAddNewOTPDebitCardModal}
          parentModal={isModalOpen}
          parentToggle={toggle}
          debt={debt}
          paymentAmount={paymentAmount}
          type="OTP"
          isChangePaymentMethod={false}
        />
      )}
      {selectedPaymentType === "newPayPal" && (
        <NewPayPalModal
          modal={addNewPayPalModal}
          toggle={toggleAddNewPayPalModal}
          parentModal={isModalOpen}
          parentToggle={toggle}
          debt={debt}
          paymentAmount={paymentAmount}
          type="OTP"
          isChangePaymentMethod={false}
        />
      )}
      {selectedPaymentType === "newBankAccount" && (
        <NewBankAccountModal
          modal={addNewOPTBankAccountModal}
          toggle={toggleAddNewOPTBankAccountModal}
          parentModal={isModalOpen}
          parentToggle={toggle}
          debt={debt}
          paymentAmount={paymentAmount}
          type="OTP"
          isChangePaymentMethod={false}
        />
      )}
    </>
  )
}