export const tableHeaders = [
  "Full Name",
  "SSN",
  "Phone Number",
  "Address",
  "Date of Birth",
  "Original Creditor",
  "Account #",
  "Reference #",
  "Amount",
  "Status",
];
