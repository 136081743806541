import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

import { setRequest } from "redux/actions/setupPlan";
// import { CardPaymentNacha } from "pages/SetupPlan/components/CardPayment/CardPaymentNacha";
import { AuthAgreementWebOnePay } from "pages/SetupPlan/components/BankAccountPayment/AuthAgreementWebOnePay";
import { AuthAgreementPhoneSinglePay } from "pages/SetupPlan/components/BankAccountPayment/AuthAgreementPhoneSinglePay";
import { ReceiptModal } from "components/ReceiptModal";
// import { NACHACardModalData } from "lib/common/NACHAModalData";
import { NACHAWebSingleModalData } from "lib/common/NACHAModalData";
import { NACHAPhoneSingleModalData } from "lib/common/NACHAModalData";

import { LoadingSpinner } from "../../../../components/LoadingSpinner";

import { formPaymentIframe, addPaymentCard, submitExtraPayment, generateNACHA } from "../../../../lib/api/common";

import { SuccessPaymentModal } from "../SuccessPaymentModal";
import { ChangePaymentSuccess } from "../SelectPaymentMethodModal/ChangePaymentSuccess";

import { useTranslation } from 'react-i18next';

import "./style.scss";

let count = 0;

export const AddExtraPaymNewDebitCardModal = ({modal, toggle, parentModal, parentToggle, debt, paymentAmount, isChangePaymentMethod, nearestScheduleDate}) => {

  const { t } = useTranslation();

  const alert = useAlert();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [repayURL, setRepayURL] = useState(null);
  const [isShowingModal, setIsShowingModal] = useState(false);

  const [successPaymentModal, setSuccessPaymentMethod] = useState(false);
  const toggleSuccessPayment = () => setSuccessPaymentMethod(!successPaymentModal);
  const [selectedPaymentObject, setSelectedPaymentObject] = useState({});
  const [changePaymentSuccessModal, setChangePaymentSuccessModal] = useState(false);
  const toggleChangePaymentSuccessModal = () => setChangePaymentSuccessModal(!changePaymentSuccessModal);
  const profileInfo = useSelector((state) => state.profile.profileData.profile);

  const accountPaymentRequest = useSelector(
    (state) => state.setupPlan.accountPaymentRequest
  );
  const consumersData = useSelector((state) => state.profile.profileData);

  useEffect(() => {
    const fetchAddCard = async () => {

      const data = await formPaymentIframe({ 
        client_id: id,
        debt_id: debt.debt_id,
        data_id: debt.referenceNumber
      });
      setRepayURL(data.url);
      setLoading(false);

      dispatch(
        setRequest({
          ...accountPaymentRequest,
          name_on_check: consumersData.profile?.full_name,
          email: consumersData.profile?.email,
        })
      );
    };
    
    if (debt && modal) {
      fetchAddCard();

      window.addEventListener("message", receiveExtraPaymentMessage, false);
    }

  }, [modal]);
  // catch iframe authorize click

  async function receiveExtraPaymentMessage(event) {
    if (count > 0) return;

    if (event.data === "PAYMENT_SUCCESS") { 
      count++;
      addPaymentCardToConsumer();
    }
  }

  const openSuccessModal = () => {
    toggleSuccessPayment();
  }

  const addPaymentCardToConsumer = async () => {
    setLoading(true);
    let cardData;
    if (id) {
      await new Promise((r) => setTimeout(r, 4000));
      cardData = await addPaymentCard({ client_id: id, authorization: "web", data_id: debt.referenceNumber });
    } else {
      await new Promise((r) => setTimeout(r, 4000));
      cardData = await addPaymentCard({ authorization: "web", data_id: debt.referenceNumber });
    }

    if (cardData.success === "True") {
      setSelectedPaymentObject(cardData.card_details);
      if (isChangePaymentMethod) {
        await generateNACHA({
          agreement_timestamp: new Date(),
          email: profileInfo.email,
          client_id: id,
          payment_frequency: "Regular",
          data_id: debt.referenceNumber
        });
        toggleChangePaymentSuccessModal();
      } else {
        makeExtraPaymentForDebitCard(cardData.card_details);
      }
    } else {
      setLoading(false);
      alert.error(cardData.message);
    }
  };

  const makeExtraPaymentForDebitCard = async(cardDetails) => {
    await new Promise((r) => setTimeout(r, 4000));
    let data;
    data = await submitExtraPayment({
      client_id: id,
      payment_method: "card",
      card_id: cardDetails?.card_id,
      debt_id: debt.debt_id,
      amount: paymentAmount,
      agreement_timestamp: new Date(),
    });

    if (data.success === "True") {
      setLoading(false);
      setSelectedPaymentObject(data.card_details);
      openSuccessModal();
    } else {
      setLoading(false);
      alert.error(t(data.message));
    }
  }

  const closeParentModal = () => {
    if (parentModal) {
      parentToggle();
    }
  }

  const renderNACHABlock = () => {
    // return (
    //   <div className="nacha-con">
    //     <CardPaymentNacha 
    //       // startDate={moment(dateValue).format("ll")}
    //       setIsShowingModal={setIsShowingModal}
    //       nameOnCheck={accountPaymentRequest.name_on_check}
    //       // planInfo={consumerPossiblePlan}
    //       paymentAmount={paymentAmount}
    //       id={id}
    //       consumerState={consumersData.profile?.state}
    //     />
    //   </div>
    // )
    if (!id) {
      return (
        <div className="nacha-con">
          <AuthAgreementWebOnePay
            accountType={null}
            selectedPaymentAccount={"new"}
            setIsShowingModal={setIsShowingModal}
            paymentAmount={paymentAmount}
            nearestScheduleDate={nearestScheduleDate}
          />
        </div>
      )
    } else {
      return (
        <div className="nacha-con">
          <AuthAgreementPhoneSinglePay
            accountType={null}
            accountPaymentRequest={accountPaymentRequest}
            authorizationSignature={consumersData.profile?.full_name}
            paymentAmount={paymentAmount}
            selectedPaymentAccount={"new"}
            setIsShowingModal={setIsShowingModal}
            consumerState={consumersData.profile?.state}
          />
        </div>
      )
    }
  }

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        scrollable={true}
        onOpened={closeParentModal}
        className="add-new-payment-modal"
      >
        <ModalBody>
          <div className="mod-header">
          <span>{t("Payment Details")}</span>
            <button className="close-modal-btn" onClick={() => toggle()}>
              <Close />
            </button>
          </div>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <iframe id="iframe-modal" src={repayURL} title="Add Card" frameBorder="0"/>
          )}
          {loading ? (
            null
          ) : (
            renderNACHABlock()
          )}
        </ModalBody>
      </Modal>
      {/* {isShowingModal ? (
        <ReceiptModal
          data = {NACHACardModalData(
            null,
            accountPaymentRequest.name_on_check,
            null,
            paymentAmount,
            id,
            consumersData.profile?.state
          )}
          setShowModal={setIsShowingModal}
        />
      ) : (
        null
      )} */}
            {isShowingModal ? (!id && (
        <ReceiptModal
          data={NACHAWebSingleModalData(
            null,
              accountPaymentRequest.bank_name,
            accountPaymentRequest.name_on_check,
              accountPaymentRequest.routing_number,
            null,
            paymentAmount,
            nearestScheduleDate
          )}
          setShowModal={setIsShowingModal}
        />
      )) || (id && (
        <ReceiptModal
          data={NACHAPhoneSingleModalData(
            accountPaymentRequest.name_on_check,
            null,
            null,
            paymentAmount,
            consumersData.profile?.state
          )}
          setShowModal={setIsShowingModal}
        />
      ))
      : null}
      {!isChangePaymentMethod ? (
        <SuccessPaymentModal
          modal={successPaymentModal}
          selectedPaymentObject={selectedPaymentObject}
          parentModal={modal}
          parentToggle={toggle}
          paymentAmount={paymentAmount}
          debt={debt}
        />
      ) : null }
      {isChangePaymentMethod && (
        <ChangePaymentSuccess
          modal={changePaymentSuccessModal}
          parentModal={modal}
          parentToggle={toggle}
        />
      )}
    </>
  )
}

AddExtraPaymNewDebitCardModal.propTypes = {
  parentModal: PropTypes.bool,
  parentToggle: PropTypes.any,
  toggle: PropTypes.func,
  modal: PropTypes.bool,
  debt: PropTypes.object,
  paymentAmount: PropTypes.string,
  isChangePaymentMethod: PropTypes.bool
}