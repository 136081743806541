import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { ProfileFormInput } from "../ProfileFormInput";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";

import {
  employerFormAddressItems,
  employerFormPrimaryItems,
} from "./employerItems";

import { LoadingSpinner } from "../../LoadingSpinner";
import { updateEmployerForm } from "../../../redux/actions/profile";
import { ProfileFormStates } from "../ProfileFormStates";

import {
  formattingPhoneNumber,
  formattingZipCode
} from "../../../lib/helpers/formatters";

import "./style.scss";

export const EmployerProfileForm = () => {

  const dispatch = useDispatch();
  const [profileForm, setProfileForm] = useState({});

  const loading = useSelector((state) => state.profile.loading);
  const consumersData = useSelector((state) => state.profile.profileData);
  const isEditingEmployer = useSelector(
    (state) => state.profile.isEditingEmployer
  );

  useEffect(() => {
    setProfileForm(_.omit(consumersData.employer, ["client_id"]));
  }, [consumersData]);

  const handleChange = (value) => {
    if (value.work_phone === "") {
      dispatch(updateEmployerForm({ ...profileForm, work_phone: null }));
    } else {
      dispatch(updateEmployerForm({ ...profileForm, ...value }));
    }
    setProfileForm({ ...profileForm, ...value });
  };

  const handleTypeChange = (e) => {
    dispatch(
      updateEmployerForm({ ...profileForm, employ_type: e.target.value })
    );
    setProfileForm({ ...profileForm, employ_type: e.target.value });
  };

  const handleStateChange = (e) => {
    dispatch(
      updateEmployerForm({ ...profileForm, employer_state: e.target.value })
    );
    setProfileForm({ ...profileForm, employer_state: e.target.value });
  };

  const renderPrimaryInformation = () => {
    return employerFormPrimaryItems.map((item) => {
      if (item.title.toLowerCase() === "work phone") {
        return (
          <div className="profile-form-group form-group" key={item.name}>
            <label className="heading heading-caption-bold" htmlFor={item.name}>
              {item.title}
            </label>
            <ProfileFormInput
              value={
                profileForm && formattingPhoneNumber(profileForm[item.name])
              }
              name={item.name}
              handleChange={handleChange}
              validator={formattingPhoneNumber}
              prevValue={"prev"}
              disabled={!isEditingEmployer || item.disabled}
            />
          </div>
        );
      }

      if (item.title.toLowerCase() === "type") {
        return (
          <div className="profile-form-group form-group" key={item.name}>
            <label className="heading heading-caption-bold" htmlFor="type">
              Type
            </label>
            <Select
              labelId="type"
              id="type"
              onChange={handleTypeChange}
              input={
                <InputBase
                  value={(profileForm && profileForm[item.name]) || "choose"}
                  disabled={!isEditingEmployer}
                  className={
                    isValidField("Type", profileForm && profileForm[item.name])
                      ? "input input-medium"
                      : "input input-medium input--invalid"
                  }
                />
              }
            >
              <MenuItem value={"choose"}>Choose Type</MenuItem>
              <MenuItem value={"ft"}>Full Time</MenuItem>
              <MenuItem value={"pt"}>Part Time</MenuItem>
              <MenuItem value={"se"}>Self-Employed</MenuItem>
              <MenuItem value={"tm"}>Temporary</MenuItem>
              <MenuItem value={"sz"}>Seasonal for Employer</MenuItem>
            </Select>
          </div>
        );
      }

      return (
        <div className="profile-form-group form-group" key={item.name}>
          <label className="heading heading-caption-bold" htmlFor={item.name}>
            {item.title}
          </label>
          <ProfileFormInput
            value={profileForm && profileForm[item.name]}
            name={item.name}
            handleChange={handleChange}
            disabled={!isEditingEmployer || item.disabled}
            isInvalid={!isValidField(item.title, profileForm[item.name])}
          />
        </div>
      );
    });
  };

  const renderPreviewModePrimaryInformation = () => {
    return employerFormPrimaryItems.map((item) => {
      if (item.title.toLowerCase() === "type") {

        if (profileForm[item.name] === "se") {
          profileForm[item.name] = "Self-Employed";
        } else if (profileForm[item.name] === "ft") {
          profileForm[item.name] = "Full Time";
        } else if (profileForm[item.name] === "pt") {
          profileForm[item.name] = "Part Time";
        } else if (profileForm[item.name] === "tm") {
          profileForm[item.name] = "Temporary";
        } else if (profileForm[item.name] === "sz") {
          profileForm[item.name] = "Seasonal for Employer";
        }

        return (
          <div className="preview-mode-row" key={item.name}>
            <div className="preview-mode-label" htmlFor={item.name}>
              {item.title}
            </div>
            <div className={`preview-mode-value ${!isValidField(item.title, profileForm[item.name]) ? "invalid-value" : ""}`}>
              {profileForm && profileForm[item.name]}
            </div>
          </div>
        )
      }

      if (item.name === "work_phone") {
        return (
          <div className="preview-mode-row" key={item.name}>
            <div className="preview-mode-label" htmlFor={item.name}>
              {item.title}
            </div>
            <div className={`preview-mode-value ${!isValidField(item.title, profileForm[item.name]) ? "invalid-value" : ""}`}>
              {profileForm && formattingPhoneNumber(profileForm[item.name])}
            </div>
          </div>
        )
      }

      return (
        <div className="preview-mode-row" key={item.name}>
          <div className="preview-mode-label" htmlFor={item.name}>
            {item.title}
          </div>
          <div className={`preview-mode-value ${!isValidField(item.title, profileForm[item.name]) ? "invalid-value" : ""}`}>
            {profileForm && profileForm[item.name]}
          </div>
        </div>
      );
    });
  }

  const isValidField = (title, value) => {
    if (title === "Employer Name" && !value) {
      return false;
    }

    if (title === "Type" && (value === "choose" || value === "")) {
      return false;
    }
    return true;
  };

  const renderAddress = () => {
    return employerFormAddressItems.map((item) => {
      const formGroupClass = () => {
        if (item.title.toLowerCase() === "street") {
          return "profile-form-group form-group profile-form-group--street";
        } else if (item.title.toLowerCase() === "zip") {
          return "profile-form-group form-group profile-form-group--zip";
        } else {
          return "profile-form-group form-group";
        }
      };
      

      if (item.title.toLowerCase() === "state") {
        return (
          <div className="profile-form-group form-group" key={item.name}>
            <label className="heading heading-caption-bold" htmlFor="state">
              State
            </label>
            <ProfileFormStates
              value={profileForm && profileForm[item.name]}
              disabled={!isEditingEmployer}
              handleStateChange={handleStateChange}
            />
          </div>
        );
      }

      if (item.title.toLowerCase() === "zip") {
        // validator = validatedZip;
        return (
          <div className={formGroupClass()} key={item.name}>
            <label className="heading heading-caption-bold" htmlFor={item.name}>
              {item.title}
            </label>
            <ProfileFormInput
              value={profileForm && formattingZipCode(profileForm[item.name])}
              name={item.name}
              handleChange={handleChange}
              // validator={validator}
              disabled={!isEditingEmployer || item.disabled}
            />
          </div>
        );
      }

      return (
        <div className={formGroupClass()} key={item.name}>
          <label className="heading heading-caption-bold" htmlFor={item.name}>
            {item.title}
          </label>
          <ProfileFormInput
            value={profileForm && profileForm[item.name]}
            name={item.name}
            handleChange={handleChange}
            // validator={validator}
            disabled={!isEditingEmployer || item.disabled}
          />
        </div>
      );
    });
  };

  const renderPreviewAddress = () => {
    return employerFormAddressItems.map((item) => {
      return (
        <div className="preview-mode-row" key={item.name}>
          <div className="preview-mode-label" htmlFor={item.name}>
            {item.title}
          </div>
          <div className={`preview-mode-value ${!isValidField(item.title, profileForm[item.name]) ? "invalid-value" : ""}`}>
            {profileForm && profileForm[item.name]}
          </div>
        </div>
      );
    });
  }

  if (_.isEmpty(profileForm) || loading) {
    return <LoadingSpinner middleFixed={true}/>;
  }

  return (
    <div className="profile-form__body">
      <div className={isEditingEmployer ? "profile-form__block edit-mode-block" : "profile-form__block"}>
      {isEditingEmployer
        ? <div className="profile-form__container">{renderPrimaryInformation()}</div>
        : <div className="profile-preview-mode">{renderPreviewModePrimaryInformation()}</div>
      }
      </div>
      <div className={isEditingEmployer ? "profile-form__block edit-mode-block" : "profile-form__block"}>
        {isEditingEmployer
          ? <div className="profile-form__container">{renderAddress()}</div>
          : <div className="profile-preview-mode">{renderPreviewAddress()}</div>
        }
      </div>
    </div>
  );
};
