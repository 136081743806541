import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";

import { fetchConsumerProfile } from "redux/actions/profile";
import { ConsumerFooter } from "../ConsumerFooter";
import hardships from "scss/images/hadships.png";
import disputes from "scss/images/disputes.png";
import complaints from "scss/images/complaints.png";

import "./style.scss";

export const HelpCenterPage = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchConsumerProfile());
  },[])

  const goToHardships = () => {
    window.location.replace("/hardships");
  }

  const goToDisputes = () => {
    window.location.replace("/disputes");
  }

  const goToComplaints = () => {
    window.location.replace("/complaints");
  }

  return (
    <>
      <Helmet>
        <title>{t("Help Center")}</title>
      </Helmet>
      <div className="help-center-body">
        <div className="title">{t("Help Center")}</div>
        <div className="subtitle">{t("Welcome to our Help Center, a dedicated space for guidance and support. We understand your situation may be challenging, and our goal is to assist you through this journey. Here, you'll find resources to address various concerns, including hardships, disputes, and complaints.")}</div>
        <div className="forms-container">
          <div className="block-container" onClick={() => goToHardships()}>
            <img src={hardships} alt="hardships"/>
            <div className="text-block">
              <div className="block-title">{t("Hardships")}</div>
              <div className="block-subtitle">{t("If you're experiencing financial difficulties affecting your ability to pay, our Hardships section offers understanding and options.")}</div>
            </div>
          </div>
          <div className="block-container" onClick={() => goToDisputes()}>
            <img src={disputes} alt="hardships"/>
            <div className="text-block">
              <div className="block-title">{t("Disputes")}</div>
              <div className="block-subtitle">{t("If you believe there's an error with your account, we're here to listen and resolve it together.")}</div>
            </div>
          </div>
          <div className="block-container" onClick={() => goToComplaints()}>
            <img src={complaints} alt="hardships"/>
            <div className="text-block">
              <div className="block-title">{t("Complaints")}</div>
              <div className="block-subtitle">{t("The Complaints section is designed for you to voice concerns, ensuring they are heard and addressed with respect and attention.")}</div>
            </div>
          </div>
        </div>
      </div>
      <ConsumerFooter />
    </>
  )
}