import React, {useState} from "react";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";

import { LoadingSpinner } from "components/LoadingSpinner";
import { CustomButton } from "components/ui-kit/CustomButtom";

import { contactFormItems } from "../contactItems";
import { ProfileFormInput } from "components/ProfileBody/ProfileFormInput";
import { ProfileFormStates } from "components/ProfileBody/ProfileFormStates";
import { formattingPhoneNumber, unformattedPhoneNumber, formattingZipCode, unformattedZipCode } from "lib/helpers/formatters";

import { addThirdPartyContact } from "lib/api/common";
import { validateEmail } from "lib/helpers/validations";

import { setThirdPartyErrors } from "redux/actions/profile";
import { validateThirdParty } from "../../helpers";

import "./style.scss";

export const AddThirdPartyModal = ({modal, toggle}) => {

  const alert = useAlert();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [contactForm, setContactForm] = useState({
    entity_name: "",
    type: "",
    phone_number: "",
    email: "",
    street: "",
    city: "",
    state: "",
    zip: ""
  });

  const thirdErrors = useSelector((state) => state.profile.thirdErrors);

  const sendContact = async() => {

    dispatch(setThirdPartyErrors(validateThirdParty(contactForm)));

    if (validateThirdParty(contactForm).length > 0) {
      return;
    }

    if (contactForm.phone_number) {
      contactForm.phone_number = unformattedPhoneNumber(contactForm.phone_number)
    }

    if (contactForm.zip) {
      contactForm.zip = unformattedZipCode(contactForm.zip);
    }

    setLoading(true);
    const data = await addThirdPartyContact(contactForm, id);
    if (data.success === "True") {
      setLoading(false);
      toggle();
      window.location.reload(false);
    } else {
      alert.error(data.message);
      setLoading(false);
    }
  }

  const handleChange = (value) => {
    setContactForm({...contactForm, ...value});
  }

  const handleTypeChange = (e) => {
    setContactForm({...contactForm, type: e.target.value});
  }

  const handleStateChange = (e) => {
    setContactForm({...contactForm, state: e.target.value});
  }

  const renderForm = () => {
    return contactFormItems.map((item, i) => {
      if (item.name === "type") {
        return (
          <div className="profile-form-group" key={item.name}>
            <label className="heading heading-caption-bold" htmlFor="type">
              Type
            </label>
            <Select
              labelId="type"
              id="type"
              onChange={handleTypeChange}
              input={
                <InputBase
                  value={(contactForm && contactForm[item.name]) || ""}
                  disabled={item.disabled}
                  className="input input-medium"
                />
              }
            >
              <MenuItem value={"attorney"}>Attorney</MenuItem>
              <MenuItem value={"title_company"}>Title Company</MenuItem>
              <MenuItem value={"power_of_attorney"}>Power of Attorney</MenuItem>
              <MenuItem value={"executor"}>Executor</MenuItem>
              <MenuItem value={"secondary"}>Secondary</MenuItem>
              <MenuItem value={"spouse"}>Spouse</MenuItem>
              <MenuItem value={"other"}>Other</MenuItem>
            </Select>
          </div>
        )
      }

      if (item.name === "state") {
        return (
          <div className="profile-form-group" key={item.name}>
            <label className="heading heading-caption-bold" htmlFor="state">
              State
            </label>
            <ProfileFormStates
              value={contactForm && contactForm[item.name]}
              handleStateChange={handleStateChange}
            />
          </div>
        );
      }

      if (item.name === "phone_number") {
        return (
          <div className="profile-form-group" key={item.name}>
            <label className="heading heading-caption-bold" htmlFor={item.name}>
              {item.title}
            </label>
            <ProfileFormInput
              value={
                contactForm && formattingPhoneNumber(contactForm[item.name])
              }
              name={item.name}
              handleChange={handleChange}
              validator={formattingPhoneNumber}
              prevValue={"prev"}
              disabled={item.disabled}
              isInvalid={!isValidField(item.title, contactForm[item.name])}
            />
          </div>
        );
      }
      
      if (item.name === "zip") {
        return (
          <div className="profile-form-group" key={i}>
            <label className="heading heading-caption-bold" htmlFor={item.name}>
              {item.title}
            </label>
            <ProfileFormInput
              value={formattingZipCode(contactForm && contactForm[item.name])}
              name={item.name}
              handleChange={handleChange}
              disabled={item.disabled}
              isInvalid={!isValidField(item.title, contactForm[item.name])}
            />
          </div>
        )
      }

      return (
        <div className="profile-form-group" key={i}>
          <label className="heading heading-caption-bold" htmlFor={item.name}>
            {item.title}
          </label>
          <ProfileFormInput
            value={contactForm && contactForm[item.name]}
            name={item.name}
            handleChange={handleChange}
            disabled={item.disabled}
            isInvalid={!isValidField(item.title, contactForm[item.name])}
          />
        </div>
      )
    })
  }

  const isValidField = (title, value) => {

    if (title === "Email" && (value && !validateEmail(value))) {
      return false;
    }

    return true;
  };
  
  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      scrollable={true}
      className="third-party-modal"
    >
      <ModalBody>
        {loading ? (<LoadingSpinner middleFixed={true}/>) : null}
        <div className="mod-header">
          <span>Add 3rd Party Contact</span>
          <button className="close-modal-btn" onClick={() => toggle()}>
            <Close />
          </button>
        </div>
        <div className="body-container">
          <div className="valid-error">{thirdErrors && thirdErrors[0]}</div>
          {renderForm()}
        </div>
        <div className="mod-footer">
          <CustomButton
            text="Cancel"
            onClickHandler={() => toggle()}
            optionalClass="cancel-btn"
          />
          <CustomButton
            text="Save Contact"
            onClickHandler={sendContact}
            disabled={loading}
            optionalClass="confirm-btn"
          />
        </div>
      </ModalBody>
    </Modal>
  )

}