const hostname = window.location.hostname.toLowerCase();

export const getTenant = () => {
  switch (hostname) {
    case "waythru-test1.com":
      return "investinet";
    case "investinet.waythru.com":
      return "investinet";
    case "consuegra-test.waythru-test1.com":
      return "consuegra";
    case "consuegralaw.waythru.com":
      return "consuegra";
    case "waythru-test2.com":
      return "investinet";
    case "demo.waythru.com":
      return "investinet";
    case "tmppllc-test.waythru-test1.com":
      return "tromberg";
    case "tmppllc.waythru.com":
      return "tromberg";
  }
}
