import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";

import { CustomButton } from "components/ui-kit/CustomButtom";
import { ProfileFormInput } from "components/ProfileBody/ProfileFormInput";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import deleteIcon from "scss/images/delete.png";

import { contactFormItems } from "./contactItems";
import { ProfileFormStates } from "components/ProfileBody/ProfileFormStates";
import { formattingPhoneNumber, unformattedPhoneNumber, formattingZipCode, unformattedZipCode } from "lib/helpers/formatters";
import { LoadingSpinner } from "components/LoadingSpinner";
import { validateEmail } from "lib/helpers/validations";

import { deleteThirdPartyContact, editThirdPartyContact } from "lib/api/common";

import { setThirdPartyErrors } from "redux/actions/profile";
import { validateThirdParty } from "../helpers";
import editIcon from "scss/images/edit.png";

import "./style.scss";

export const ThirdPartyContainer = (data) => {

  const alert = useAlert();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [isEditMode, setIsEditMode] = useState(false);
  const [contactForm, setContactForm] = useState({});
  const [sendFormRequest, setSendFormRequest] = useState({});
  const [contactId, setContactId] = useState({});
  const [loading, setLoading] = useState(false);

  const thirdErrors = useSelector((state) => state.profile.thirdErrors);

  useEffect(() => {
    setContactForm({...contactForm, ...data.data});
    setSendFormRequest({id: data.data.id});
    setContactId({id: data.data.id});
  }, [])

  const handleCancel = () => {
    dispatch(setThirdPartyErrors([]));
    setIsEditMode(false);
  }

  const handleSave = async() => {
    dispatch(setThirdPartyErrors(validateThirdParty(contactForm)));

    if (validateThirdParty(contactForm).length > 0) {
      return;
    }

    setIsEditMode(false);

    if (sendFormRequest.phone_number) {
      sendFormRequest.phone_number = unformattedPhoneNumber(sendFormRequest.phone_number)
    }

    if (sendFormRequest.zip) {
      sendFormRequest.zip = unformattedZipCode(sendFormRequest.zip);
    }

    setLoading(true);
    const response = await editThirdPartyContact(sendFormRequest, id);
    if (response.success === "True") {
      setLoading(false);
      // clear form after success sending
      setSendFormRequest({id: data.data.id});
    } else {
      alert.error(data.message);
      setLoading(false);
    }
  }

  const handleEditMode = () => {
    setIsEditMode(true);
  }

  const deleteContact = async() => {
    setLoading(true);
    setIsEditMode(false);
    const data = await deleteThirdPartyContact(contactId, id);
    if (data.success === "True") {
      setLoading(false);
      window.location.reload(false);
    } else {
      alert.error(data.message);
      setLoading(false);
    }
  }

  const renderEditBtn = () => {
    if (isEditMode) {
      return (
        <div className="edit-btn-container">
          <img className="delete-icon" onClick={() => deleteContact()} src={deleteIcon}/>
          <CustomButton
            type="secondary"
            text="Cancel"
            onClickHandler={handleCancel}
          />
          <CustomButton
            optionalClass="save-btn"
            text="Save"
            onClickHandler={handleSave}
          />
        </div>
      )
    } else {
      return (
        <CustomButton
          type="secondary"
          text="Edit"
          onClickHandler={handleEditMode}
          imgUrl={editIcon}
        />
      )
    }
  }

  const handleChange = (value) => {
    setSendFormRequest({...sendFormRequest, ...value});
    setContactForm({...contactForm, ...value});
  }

  const handleTypeChange = (e) => {
    setSendFormRequest({...sendFormRequest, type: e.target.value});
    setContactForm({...contactForm, type: e.target.value});
  }

  const handleStateChange = (e) => {
    setSendFormRequest({...sendFormRequest, state: e.target.value});
    setContactForm({...contactForm, state: e.target.value});
  }

  const isValidField = (title, value) => {

    if (title === "Email" && (value && !validateEmail(value))) {
      return false;
    }
    return true;
  };

  const renderContactInformation = () => {
    return contactFormItems.map((item,i) => {
      if (item.name === "type") {
        return (
          <div className="profile-form-group form-group" key={item.name}>
            <label className="heading heading-caption-bold" htmlFor="type">
              Type
            </label>
            <Select
              labelId="type"
              id="type"
              onChange={handleTypeChange}
              input={
                <InputBase
                  value={(contactForm && contactForm[item.name] || '')}
                  disabled={!isEditMode}
                  className="input input-medium"
                />
              }
            >
              <MenuItem value={"attorney"}>Attorney</MenuItem>
              <MenuItem value={"title_company"}>Title Company</MenuItem>
              <MenuItem value={"power_of_attorney"}>Power of Attorney</MenuItem>
              <MenuItem value={"executor"}>Executor</MenuItem>
              <MenuItem value={"secondary"}>Secondary</MenuItem>
              <MenuItem value={"spouse"}>Spouse</MenuItem>
              <MenuItem value={"other"}>Other</MenuItem>
            </Select>
          </div>
        )
      }
      if (item.name === "state") {
        return (
          <div className="profile-form-group" key={item.name}>
            <label className="heading heading-caption-bold" htmlFor="state">
              State
            </label>
            <ProfileFormStates
              value={contactForm && contactForm[item.name]}
              handleStateChange={handleStateChange}
              disabled={!isEditMode}
            />
          </div>
        );
      }
      if (item.name === "phone_number") {
        return (
          <div className="profile-form-group" key={item.name}>
            <label className="heading heading-caption-bold" htmlFor={item.name}>
              {item.title}
            </label>
            <ProfileFormInput
              value={
                contactForm && formattingPhoneNumber(contactForm[item.name])
              }
              name={item.name}
              handleChange={handleChange}
              validator={formattingPhoneNumber}
              prevValue={"prev"}
              disabled={!isEditMode}
            />
          </div>
        );
      }

      if (item.name === "zip") {
        return (
          <div className="profile-form-group" key={i}>
            <label className="heading heading-caption-bold" htmlFor={item.name}>
              {item.title}
            </label>
            <ProfileFormInput
              value={formattingZipCode(contactForm && contactForm[item.name])}
              name={item.name}
              handleChange={handleChange}
              disabled={!isEditMode}
            />
          </div>
        )
      }

      return (
        <div className="profile-form-group" key={i}>
          <label className="heading heading-caption-bold" htmlFor={item.name}>
            {item.title}
          </label>
          <ProfileFormInput
            value={contactForm && contactForm[item.name]}
            name={item.name}
            handleChange={handleChange}
            disabled={!isEditMode}
            isInvalid={!isValidField(item.title, contactForm[item.name])}
          />
        </div>
      )
    })
  }

  const renderPreviewModeContactInformation = () => {
    return contactFormItems.map((item) => {
      return (
        <div className="preview-mode-row" key={item.name}>
          <div className="preview-mode-label" htmlFor={item.name}>
            {item.title}
          </div>
          <div className={`preview-mode-value ${!isValidField(item.title, contactForm[item.name]) ? "invalid-value" : ""}`}>
            {contactForm && contactForm[item.name]}
          </div>
        </div>
      )
    })
  }

  return (
    <div className="third-party-contact">
      {loading ? (<LoadingSpinner middleFixed={true}/>) : null}
      <div className="header">
        <div className="title">
          3rd Party Contact
        </div>
        {renderEditBtn()}
      </div>
      <>
        {isEditMode 
          ? <>
              <div className="valid-error">{thirdErrors && thirdErrors[0]}</div>
              <div className="edit-mode-cont">{renderContactInformation()}</div>
            </>
          : <>{renderPreviewModeContactInformation()}</>
        }
      </>
    </div>
  )
}