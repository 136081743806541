import { useSelector } from "react-redux";

const hostname = window.location.hostname.toLowerCase();

export const PayPalUrlGeneration = () => {
  const profileData = useSelector((state) => state.profile.profileData);
  switch (hostname) {
    case "waythru-test1.com":
      return `https://pathway.investinet.waythru-test1.com/paypal?id=${profileData?.profile?.client_id}`;
    case "waythru-test2.com":
      return `https://pathway.investinet.waythru-test1.com/paypal?id=${profileData?.profile?.client_id}`;
    case "investinet.waythru.com":
      return `https://pathway.investinet.com/paypal?id=${profileData?.profile?.client_id}`;
  }
}

export const showDependOnTenant = (profileInfo) => {

  if (profileInfo?.new_payment_system) {
    switch (hostname) {
      case "waythru-test1.com":
        return false;
      case "waythru-test2.com":
        return false;
      case "investinet.waythru.com":
        return false;
      default:
        return false;
    }
  } else {
    return false;
  }
}

export const showOnConsuegra = () => {
  switch (hostname) {
    case "waythru-test1.com":
      return false;
    case "waythru-test2.com":
      return false;
    case "investinet.waythru.com":
      return false;
    case "consuegra-test.waythru-test1.com":
      return true;
    case "consuegralaw.waythru.com":
      return true;
    default:
      return false;
  }
}