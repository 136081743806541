// TODO: REPLACE hostname - before MERGE
// const hostname = "waythru-test1.com";

let hostname = window.location.hostname.toLowerCase();
const appPort = process.env.REACT_APP_BACKEND_PORT;
const appApi = process.env.REACT_APP_BACKEND_API;
const appPrefix = process.env.REACT_APP_BACKEND_API_PREFIX;
const appProtocol = process.env.REACT_APP_BACKEND_API_PROTOCOL;

// for Redesign url call defauld api
if (hostname === "waythru-test2.com") {
  hostname = "waythru-test1.com";
}

// remove after API domain changed
if (hostname === "investinet.waythru.com") {
  hostname = "waythru.investinet.com";
}

export default async function sendFileRequest(path, method = "GET", body) {
  const bearer = JSON.parse(localStorage.getItem("userData"))
    ? JSON.parse(localStorage.getItem("userData")).token.toString()
    : "";

  const Authorization = bearer !== "" ? `Bearer ${bearer}` : "";

  const response = await fetch(
    `${appProtocol}://${appPrefix}${hostname}${appPort}${appApi}${path}`,
    {
      method: method,
      headers: new Headers({
        Authorization,
      }),
      body,
    }
  );

  const data = await response.json();

  if (data.error) {
    throw new Error(data.error);
  }

  return data;
}
