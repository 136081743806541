import React, { useEffect } from "react";

import alertIcon from "scss/images/alert.png";
import { multitenancyPhone } from "multitenancy/multitenancyContactInfo";

import { 
  FailedPaymentDataLayer,
} from "lib/helpers/dataLayers";

import { useTranslation } from 'react-i18next';

import "./style.scss";

export const FailedPaymentInfo = ({errorMessage, alert_notification}) => {

  const { t } = useTranslation();

  useEffect(() => {
    FailedPaymentDataLayer();
  },[])
  
  return (
    <div className="failed-payment-info-container">
      <div className="text-label">
        <img src={alertIcon} alt="alert"/>
        {errorMessage && (
          <div className="inner-text">
            <div>{t("Last payment was unsuccessful. Please update your payment method or contact us at")} {multitenancyPhone()}.</div>
            <div className="space">{errorMessage}</div>
          </div>
        )}
        {alert_notification && (
          <div>{alert_notification}</div>
        )}
      </div>
    </div>
  )
}