import React, { useState, useCallback, Fragment } from "react";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import PasswordChecklist from "react-password-checklist"

import { setConsumerEmail } from "../../redux/actions/forgotPassword";
import { setStep } from "../../redux/actions/adminLogin";
import { isEmailValid } from "../../lib/helpers/validations";
import {
  sendVerificationCode,
  setNewPassword,
  verifyUser,
} from "../../lib/api/common";
import { SystemArrowLeftIcon16 } from "../../components/icons/SystemIcons";
import { SendVerificationCode } from "./SendVerificationCode";
import { CheckVerificationCode } from "./CheckVerificationCode";
import { ConfirmPassword } from "./ConfirmPassword";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import "./style.scss";
import { CustomButton } from "../../components/ui-kit/CustomButtom";

import { MultitenancyLogoPicker } from "multitenancy/multitenancyLogoPicker";

export const ForgotPasswordPage = () => {
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPasswordRequest, setNewPasswordRequest] = useState({
    new_password: "",
    new_password_confirmation: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const currentStep = useSelector((state) => state.forgotPassword.currentStep);

  const handleGoNext = async () => {
    setIsNextButtonDisabled(true);
    setLoading(true);
    try {
      if (currentStep === "1") {
        const response = await sendVerificationCode({
          email,
        });

        if (response.success === "True") {
          setError("");
          dispatch(setStep("2"));
        } else {
          // TODO: Update error message, when Back end is ready
          setError(response.message);
          setIsNextButtonDisabled(true);
        }
      } else if (currentStep === "2") {
        const response = await verifyUser({
          email,
          verification_code: verificationCode,
        });
        if (response.success === "True") {
          setError("");
          dispatch(setStep("3"));
        } else {
          // TODO: Update error message, when Back end is ready
          setError(response.message);
          setIsNextButtonDisabled(true);
        }
      } else {
        if (
          newPasswordRequest.new_password !==
          newPasswordRequest.new_password_confirmation
        ) {
          setError("Passwords should be the same.");
          return;
        }
        setLoading(true);
        const response = await setNewPassword({
          email,
          verification_code: verificationCode,
          ...newPasswordRequest,
        });
        if (response.success === "True") {
          setError("");
          dispatch(setConsumerEmail(email));
          dispatch(setStep("1"));
          goToAdmin();
        } else {
          // TODO: Update error message, when Back end is ready
          setError(response.message);
          setIsNextButtonDisabled(true);
        }
      }
    } catch (e) {
      alert.error(e);

      setError("Something went wrong.");
      setIsNextButtonDisabled(true);
    }

    setLoading(false);
  };

  const goToAdmin = useCallback(() => {
    history.push("/admin");
  }, [history]);

  const handleEmailChange = (e) => {
    if (!e.target.value) {
      setIsNextButtonDisabled(true);
      setEmail(e.target.value);
      return;
    }
    if (isEmailValid(e.target.value)) {
      setIsNextButtonDisabled(false);
    } else {
      setIsNextButtonDisabled(true);
    }
    setEmail(e.target.value);
  };

  const handleVerificationCodeChange = (e) => {
    if (isNaN(e.target.value) || e.target.value.length > 8) {
      return;
    }

    if (e.target.value.length === 8) {
      setIsNextButtonDisabled(false);
    } else {
      setIsNextButtonDisabled(true);
    }

    setVerificationCode(e.target.value);
  };

  const handlePasswordChange = (e) => {
    e.persist();
    if (e.target.name === "new-password") {
      setNewPasswordRequest((oldNewPasswordRequestData) => {
        return {
          ...oldNewPasswordRequestData,
          new_password: e.target.value,
        };
      });
    } else if (e.target.name === "confirm-password") {
      setNewPasswordRequest((oldNewPasswordRequestData) => {
        return {
          ...oldNewPasswordRequestData,
          new_password_confirmation: e.target.value,
        };
      });
    }
  };

  const handleGoBack = () => {
    setVerificationCode("");
    setNewPasswordRequest({
      new_password: "",
      new_password_confirmation: "",
    });
    setError("");
    if (currentStep === "1") {
      window.location.replace("/admin");
      return;
    }
    dispatch(setStep((+currentStep - 1).toString()));
  };

  const buttonText = () => {
    if (currentStep === "1") {
      return "Reset Password";
    } else if (currentStep === "2") {
      return "Verify";
    } else {
      return "Create New Password";
    }
  };

  const headerText = () => {
    if (currentStep === "1") {
      return "Reset Password";
    } else if (currentStep === "2") {
      return "Check your email";
    } else {
      return "Create New Password";
    }
  };

  const subtitleText = () => {
    if (currentStep === "1") {
      return "Enter your email address";
    } else if (currentStep === "2") {
      return "Enter the verification code you received ";
    } else {
      return null;
    }
  }

  const onValidFord = (isValid) => {
    if (isValid) {
      setIsNextButtonDisabled(false);
    } else {
      setIsNextButtonDisabled(true);
    }
  }

  const renderStep = () => {
    if (currentStep === "1") {
      return (
        <SendVerificationCode
          email={email}
          handleEmailChange={handleEmailChange}
        />
      );
    } else if (currentStep === "2") {
      return (
        <CheckVerificationCode
          verificationCode={verificationCode}
          handleVerificationCodeChange={handleVerificationCodeChange}
        />
      );
    } else if (currentStep === "3") {
      return (
      <div>
        <ConfirmPassword
          newPassword={newPasswordRequest.new_password}
          newPasswordConfirmation={newPasswordRequest.new_password_confirmation}
          handlePasswordChange={handlePasswordChange}
        />
        <div className="profile-password-checklist">
          <PasswordChecklist
            rules={["minLength","specialChar","number","capital","match"]}
            minLength={8}
            value={newPasswordRequest.new_password}
            valueAgain={newPasswordRequest.new_password_confirmation}
            onChange={(isValid) => {onValidFord(isValid)}}
            messages={{
              minLength: "8 and more characters",
              specialChar: "a special character",
              number: "a number",
              capital: "a capital letter",
              match: "passwords match",
            }}
          />
        </div>

      </div>
      );
    }
  };

  return (
    <div className="forgot-password">
      <div className="forgot-password__container">
      <div className="logo">
          <MultitenancyLogoPicker />
        </div>
        <h2 className="heading heading-1">{headerText()}</h2>
        {/* Do not show back button on final step */}
        <div className="subtitle">{subtitleText()}</div>
        {currentStep !== "3" ? (
          <div className="form-group">
            <button
              className="button button-transparent button-back"
              onClick={() => handleGoBack()}
            >
              <SystemArrowLeftIcon16 />
              Back
            </button>
          </div>
        ) : null}
        {loading ? <LoadingSpinner middleFixed={true}/> : <Fragment>{renderStep()}</Fragment>}

        {error ? (
          <div className="forgot-password__error-message">{error}</div>
        ) : null}
        <CustomButton
          text={buttonText()}
          size="large"
          onClickHandler={handleGoNext}
          disabled={isNextButtonDisabled}
        />
      </div>
    </div>
  );
};
