import React, { useState, useEffect } from "react";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

// import { PageHeader } from "../../components/PageHeader";
import { TagsBody } from "../../components/TagsBody";
import { getActiveTags, getArchivedTags } from "../../lib/api/admin";

import { multitenancyCompanyShortName } from 'multitenancy/multitenancyContactInfo';

import "./style.scss";

export const SettingsPage = () => {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [activeTags, setActiveTags] = useState([]);
  const [archivedTags, setArchivedTags] = useState([]);

  useEffect(() => {
    (async () => {
      await fetchActiveTags();
      await fetchArchivedTags();
      setLoading(false);
    })();
  }, []);

  const fetchActiveTags = async () => {
    const activeTags = await getActiveTags();
    setActiveTags(activeTags.data);
  };

  const fetchArchivedTags = async () => {
    const archivedTags = await getArchivedTags();
    setArchivedTags(archivedTags.data);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = ({ children, value, index }) => {
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && <div>{children}</div>}
      </div>
    );
  };

  return (
    <div className="default-container settings-page">
      <Helmet>
        <title>Settings – {multitenancyCompanyShortName()}</title>
      </Helmet>
      {/* <PageHeader title="Settings" /> */}
      <div className="title">Tags</div>
      <div className="tab-container">
        <div className="tab-container__column">
          <h2 className="heading heading-2">Notes Tags</h2>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleTabChange}
            aria-label="Vertical tabs"
            className="tab-container__tabs"
          >
            <Tab label="Current Tags" id="vertical-tab-current" />
            <Tab label="Archived Tags" id="vertical-tab-archived" />
          </Tabs>
        </div>
        <div className="tab-container__column tab-container__column--wide">
          <TabPanel value={value} index={0}>
            <TagsBody
              headerTitle={"Current Tags"}
              activeTags={activeTags}
              loading={loading}
              fetchActiveTags={fetchActiveTags}
              fetchArchivedTags={fetchArchivedTags}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TagsBody
              headerTitle={"Archived Tags"}
              archivedTags={archivedTags}
              fetchActiveTags={fetchActiveTags}
              fetchArchivedTags={fetchArchivedTags}
              loading={loading}
            />
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

SettingsPage.propTypes = {
  children: PropTypes.array,
  value: PropTypes.string,
  index: PropTypes.string,
};
