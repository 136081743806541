import {
  SET_USERNAME,
  SET_PROFILE_COMPLETE,
  SET_PROFILE_INCOMPLETE,
} from "../types";

import { getSelfUsername } from "../../lib/api/admin";
// import { getClientProfile } from "../../lib/api/consumer";

export function setUserName(consumerId, type) {
  return async (dispatch) => {
    let data;
    if (type === "admin") {
      data = await getSelfUsername();
    } 
    // else {
      // data = await getClientProfile({ client_id: consumerId });
    // }

    // Update this when Backend has another solution
    if (data?.detail === "Signature has expired.") {
      localStorage.removeItem("userData");
      localStorage.removeItem("user_id");
      if (type === "admin") {
        window.location.replace("/admin");
      } else {
        window.location.replace("/");
      }
    }

    if (data?.success === "True") {
      if (type === "admin") {
        dispatch({ type: SET_USERNAME, payload: data.data.full_name });
        return;
      }

      dispatch({ type: SET_USERNAME, payload: data.data.profile.full_name });
      if (
        (!data.data.profile.cell_phone && !data.data.profile.home_phone) ||
        !data.data.profile.email ||
        !data.data.employer.employer_full_name
      ) {
        dispatch(setProfileIsIncomplete());
      } else {
        dispatch(setProfileIsComplete());
      }
    } else {
      // eslint-disable-next-line
      console.log("Error: ", data);
    }
  };
}

export function setProfileIsIncomplete() {
  return { type: SET_PROFILE_INCOMPLETE };
}

export function setProfileIsComplete() {
  return { type: SET_PROFILE_COMPLETE };
}