import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { KeyboardArrowRight } from "@material-ui/icons";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TabPanel } from "./helpers/TabPanel";
import { LoadingSpinner } from "components/LoadingSpinner";
import { formattedMoney } from "lib/helpers/formatters";

import { 
  fetchAllAccounts,  
} from "redux/actions/newConsumerDebts";

import { ConsumerOverview } from "ConsumerPage/ConsumerOverview";
import { PlanSelector } from "NewAdmin/ConsumerDebtsContainer/components/PlanSelector";
import { EstablishedPlanInfo } from "NewAdmin/ConsumerDebtsContainer/components/EstablishedPlanInfo";

import { PaymentsHistoryContainer } from "ConsumerPage/PaymentsHistoryContainer";
import { FailedPaymentInfo } from "ConsumerPage/FailedPaymentInfo";
import { getTenant } from "multitenancy/multitenancyGetTenant";

import "./style.scss";

export const ConsumerDebtsContainer = ({selectedTab}) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const allAccountsDebts = useSelector((state) => state.newConsumerDebts.allAccountsDebts);

  useEffect(() => {
    dispatch(fetchAllAccounts(id));
  }, [])

  const returnStatusName = (name) => {
    if (name === "paid_off") {
      return "paid off";
    } else if (name === "on_hold") {
      return "on hold";
    } else {
      return name;
    }
  }

  const renderBalanceValue = (item) => {

    if (getTenant() === "consuegra" && "next_due" in item) {
      return <div className="tab-label-value">$ {formattedMoney(item.ptp_balance)}</div>
    } else {
      return <div className="tab-label-value">$ {formattedMoney(item.currentBalance)}</div>
    }
  }

  return (
    <div className="consumer-debts-container-admin">
      {allAccountsDebts === null ? (<LoadingSpinner middleFixed={true}/>) : null}
      <div className="tabs-list-container">
        <div className="tabs-list">
          <div className="header">
            <span className="total-balance-title">Current Balance ({allAccountsDebts?.length || 0} {allAccountsDebts?.length > 1 ? "accounts" : "account"})</span>
          </div>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleTabChange}
            aria-label="Vertical tabs"
            className="consumer-tabs-container"
          >
            {allAccountsDebts?.map((item, i) => {
              return (
                <Tab key={i} label={
                  <>
                    <div className="consumer-tab-container">
                      <div className="tab-label-container">
                        <span className="tab-label-title">{item.originalCreditor}</span>
                        <div className="value-container">
                          {renderBalanceValue(item)}
                          <div className="status-container">
                            <span className={`status-icon ${item.status}`}></span>
                            {returnStatusName(item.status)}
                          </div>
                        </div>
                      </div>
                      <div className="arrow-right">
                        {allAccountsDebts?.length > 1 ? <KeyboardArrowRight /> : null}
                      </div>
                    </div>
                  </>
                } />
              )
            })}
          </Tabs>
        </div>
      </div>
      {allAccountsDebts?.map((item, i) => {
        return(
          <TabPanel key={i} value={value} index={i}>
            {item.alert_notification ? <FailedPaymentInfo alert_notification={item.alert_notification}/> : null}
            {item.success === false ? <FailedPaymentInfo errorMessage={item.error_message}/> : null}
            <ConsumerOverview overviewInfo={item} isAdmin={true}/>
            {item.status === "active" ? <PlanSelector debt={item} selectedTab={selectedTab}/>  : null}
            {item.status === "established" || item.status === "delinquent" ? <EstablishedPlanInfo debt={item}/> : null}
            <PaymentsHistoryContainer debt={item} selectedTab={selectedTab}/>
          </TabPanel>
        )
      })}
    </div>
  )
}