import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useAlert } from "react-alert";

import { PageHeader } from "../../components/PageHeader";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { changePassword } from "../../lib/api/admin";
import { ProfileFormTitle } from "../../components/ProfileBody/ProfileFormTitle";
import { fetchAdminProfile } from "../../redux/actions/profile";
import { CustomButton } from "../../components/ui-kit/CustomButtom";

import { multitenancyCompanyShortName } from 'multitenancy/multitenancyContactInfo';

export const ChangePasswordPage = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profile.profileData);

  const changePasswordForm = useSelector(
    (state) => state.changePassword.changePasswordForm
  );

  const changePasswordSaveBtn = useSelector(
    (state) => state.changePassword.changePasswordForm.save_btn
  )

  useEffect(() => {
    const fetchUserProfileData = async () => {
      dispatch(fetchAdminProfile());
    };

    fetchUserProfileData();
  }, [dispatch]);

  const handleSave = async () => {
    const data = await changePassword(changePasswordForm);
    if (data.success === "True") {
      // dispatch({ type: SAVE_NEW_PASSWORD });
      window.location.replace("/admin/profile");
    } else {
      alert.error(data.message);
    }
  };

  const profileFormChildren = () => {
    return (
      <div className="profile-form-title__buttons">
        <Helmet>
          <title>Update Password – {multitenancyCompanyShortName()}</title>
        </Helmet>
        <Link
          className="button button-secondary button-secondary--medium"
          to="/admin/profile"
          disabled={false}
        >
          Cancel
        </Link>
        <CustomButton
          text="Save"
          onClickHandler={handleSave}
          disabled={changePasswordSaveBtn}
        />
      </div>
    );
  };

  return (
    <div className="profile-page-container">
      <PageHeader title="Profile" />
      <div className="tab-container">
        <div className="tab-container__column">
          <div className="avatar-wrapper">
            <h2 className="heading heading-2">
              {profileData && profileData.full_name}
            </h2>
            <h6 className="heading heading-caption-bold">From Feb 28, 2020</h6>
          </div>
        </div>
        <div className="profile-body tab-container__column tab-container__column--medium">
          <ProfileFormTitle title="Change Password">
            {profileFormChildren()}
          </ProfileFormTitle>
          <ChangePasswordForm />
        </div>
      </div>
    </div>
  );
};
