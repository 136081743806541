import React from "react";

import moment from "moment";
import PropTypes from "prop-types";

import { accountNumberLastFour } from "../../../../lib/helpers/other";
import { ButtonLink } from "components/ui-kit/ButtonLink";
import { formattedMoney } from "lib/helpers/formatters";

import { multitenancyPhone, multitenancyHours, multitenancyCompanyName } from "multitenancy/multitenancyContactInfo";

export const AuthAgreementPhoneSinglePay = ({
  accountType,
  accountNumber,
  accountPaymentRequest,
  setIsShowingModal,
  paymentAmount,
  consumerState,
}) => {
  // const sum = localStorage.getItem("paymentSum");

  return (
    <div className="nacha-container">
      <div className="nacha-text">
        <p>
          As of today’s date,{" "}
          <span className="bold">{moment(Date()).format("MM/DD/YYYY")}</span>, you have
          given verbal authorization to debit your account. Please confirm your understanding with &quot;I agree&quot;.
        </p>
        <p>
          To confirm the payment details, the payment of{" "}
          <span className="bold">${formattedMoney(paymentAmount)}</span> will be debited on or after{" "}
          <span className="bold">{moment(Date()).format("MM/DD/YYYY")}</span> for{" "}
          <span className="bold">{accountPaymentRequest?.name_on_check}</span>
          {accountType ? (
            <span> from a <span className="bold">{accountType}</span> account number
              ending in{" "}
              <span className="bold">{accountNumberLastFour(accountPaymentRequest.account_number) || accountNumber}.</span>
            </span>
          ):(
            <span>. </span>
          )}
        </p>
        <p>
          You will receive a confirmation letter with the terms of your payment and confirming
          your authorization.
        </p>
        {consumerState === "Rhode Island" || consumerState === "Massachusetts" ? (
          <p>
            You are giving us verbal authorization to establish a postdated payment 
            on your account. InvestiNet does not require you to set up postdated payments 
            and you have the option to use other methods to submit payments 
            if you do not wish to schedule a postdated payment.
          </p>
        ) : null}
        <p>
          If you have any questions or concerns, or would like to revoke this authorization, please give us a call please call at 1 {multitenancyPhone()} between {multitenancyHours()}. 
          I need to record your authorization to initiate the payment.
        </p>
        <p>{accountPaymentRequest?.name_on_check}, do you authorize {multitenancyCompanyName()} to initiate this payment in accordance with the terms I just stated.</p>
      </div>
      <ButtonLink
        text="Download NACHA Authorization Agreement"
        onClickHandler={() => setIsShowingModal(true)}
      />
    </div>
  );
};

AuthAgreementPhoneSinglePay.propTypes = {
  accountType: PropTypes.any,
  accountNumber: PropTypes.any,
  accountPaymentRequest: PropTypes.object,
  setIsShowingModal: PropTypes.func,
};
