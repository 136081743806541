import React from "react";
import moment from "moment";
import { useAlert } from "react-alert";
import { useParams } from "react-router-dom";

import { ReceiptModal } from "../../../components/ReceiptModal";
import { formattedMoney } from "../../../lib/helpers/formatters";
import { useModal } from "../../../components/DebtCustomCard/hooks/useModal";

import fileIcon from "../../../scss/images/file-icon.png";
import { NACHAButtonName, paymentProcessingDate } from "components/DebtCustomCard/helpers";
import { 
  NACHAWebMultiModalData, 
  NACHAWebSingleModalData,
  NACHAPhoneMultiModalData,
  NACHAPhoneSingleModalData,

  OLD_NACHAWebMultiModalData,
  OLD_NACHAWebSingleModalData,
  OLD_NACHAPhoneMultiModalData,
  OLD_NACHAPhoneSingleModalData
} from "lib/common/NACHAModalData";

import { InfoTooltip } from "components/InfoTooltip";

import { useTranslation } from 'react-i18next';

import "./style.scss";

export const PaymentHistoryTable = ({data, showTable, debt}) => {

  const { t } = useTranslation();

  const { id } = useParams();
  const alert = useAlert();

  const {
    modalData,
    NACHAModalData,
    selectedHistoryType,
    setSelectedHistoryType,
    modalDate,
    selectedPayer,
    showModal,
    setShowModal,
    handleGetReceipt,
    handleShowNACHAModal
  } = useModal(id, debt, alert);

  const showPaymentType = (type) => {
    if (type === "other") {
      return "Mail/Wire";
    } else if (type === "paypal") {
      return "PayPal";
    }
    return type;
  }

  return (
    <div className={`paymentHistory-table-container ${showTable}`}>
      <table className={`paymentHistory-table ${showTable}`}>
        <thead>
          <tr>
            <th>{t("Date")}</th>
            {showTable === "history" && (
              <th>{t("Processing Date")}</th>
            )}
            <th>{t("Amount")}</th>
            <th>{t("Balance")}</th>
            {showTable === "history" && (
              <> 
                {<th>{t("Payment Method")}</th>}
                {data && <th>{t("Receipt")}</th>}
                {data && <th>{t("ACH Auth")}</th>}
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, i) => {
            return (
            <tr key={i} className={item.success === false ? "failed" : "success"}>
              <td>{moment.utc(item.payment_date).format("ll")}</td>
              {showTable === "history" && (
                <td>                      
                  {paymentProcessingDate(
                    item.payment_date,
                    item.payment_method
                  )}
                </td>
              )}
              <td>$ {formattedMoney(item.amount)}</td>
              <td>$ {formattedMoney(item.remains)}</td>
              {showTable === "history" && (
                <td className="payment-method">                      
                  {t(showPaymentType(item.payment_method))}
                </td>
              )}
              {item.success && (
                <td className="receipt">
                  <div
                    onClick={() =>
                      handleGetReceipt(
                        item.amount,
                        item.payment_date,
                        item.type
                      )
                    }
                    type="button"
                    className="receipt-btn"
                  >
                    <img src={fileIcon} alt="file icon"/>
                    <span>{t("See Receipt")}</span>
                  </div>
                </td>
              )}
              {item.success === false && (
                <td className="failed-info-tooltip">
                  <InfoTooltip
                    title={item.status.split(/(?=[A-Z])/).join(" ")}
                  />
                </td>
              )}
              <td className="nacha">
                {item.payment_method === "ach" ? (
                  <button
                    onClick={() => handleShowNACHAModal(item)}
                    type="button"
                    className="button button-transparent"
                  >
                    {NACHAButtonName(item.payment_frequency)}
                  </button>
                ) : null}
              </td>
            </tr>
          )})}
        </tbody>
      </table>
      {showModal && modalData && (
        <ReceiptModal
          data={modalData}
          setShowModal={setShowModal}
          modalDate={modalDate}
        />
      )}
      {selectedPayer === "consumer" &&
      selectedHistoryType &&
      selectedHistoryType === "Regular" ? (
        <ReceiptModal
          data={
            NACHAModalData.end_date ? (
              NACHAWebMultiModalData(
                NACHAModalData.payment_frequency,
                NACHAModalData.type,
                NACHAModalData.bank_name,
                NACHAModalData.name_on_check,
                NACHAModalData.routing_number,
                NACHAModalData.account_number,
                false,
                NACHAModalData.start_date,
                NACHAModalData.end_date,
                NACHAModalData.start_amount,
                NACHAModalData.end_amount
              )
            ) : (
              OLD_NACHAWebMultiModalData(
                NACHAModalData.payment_frequency,
                NACHAModalData.per_month,
                NACHAModalData.start_date,
                NACHAModalData.type,
                NACHAModalData.bank_name,
                NACHAModalData.name_on_check,
                NACHAModalData.routing_number,
                NACHAModalData.account_number
              )
            )

        }
          setShowModal={setSelectedHistoryType}
          modalDate={modalDate}
        />
      ) : null}
      {selectedPayer === "consumer" &&
      selectedHistoryType &&
      (selectedHistoryType === "Extra" ||
        selectedHistoryType === "One Time") ? (
        <ReceiptModal
          data={
            NACHAModalData.end_date ? (
              NACHAWebSingleModalData(
                NACHAModalData.type,
                NACHAModalData.bank_name,
                NACHAModalData.name_on_check,
                NACHAModalData.routing_number,
                NACHAModalData.account_number,
                NACHAModalData.payment_amount
              )
            ) : (
              OLD_NACHAWebSingleModalData(
                NACHAModalData.type,
                NACHAModalData.bank_name,
                NACHAModalData.name_on_check,
                NACHAModalData.routing_number,
                NACHAModalData.account_number,
                NACHAModalData.payment_amount
              )
            )
        }
          setShowModal={setSelectedHistoryType}
          modalDate={modalDate}
        />
      ) : null}
      {selectedPayer === "admin/agent" &&
      selectedHistoryType &&
      selectedHistoryType === "Regular" ? (
        <ReceiptModal
          data={
            NACHAModalData.end_date ? (
              NACHAPhoneMultiModalData(
                NACHAModalData.start_date,
                NACHAModalData.end_date,
                NACHAModalData.name_on_check,
                NACHAModalData.type,
                NACHAModalData.account_number,
                NACHAModalData.is_change_payment_method,
                NACHAModalData.consumer_state,
                NACHAModalData.number_of_payment,
                NACHAModalData.payment_frequency,
                NACHAModalData.start_amount,
                NACHAModalData.end_amount,
              )
            ) : (
              OLD_NACHAPhoneMultiModalData(
                NACHAModalData.start_date,
                NACHAModalData.per_month,
                NACHAModalData.name_on_check,
                NACHAModalData.type,
                NACHAModalData.account_number
              )
            )
        }
          setShowModal={setSelectedHistoryType}
          modalDate={modalDate}
        />
      ) : null}
      {selectedPayer === "admin/agent" &&
      selectedHistoryType &&
      (selectedHistoryType === "Extra" ||
        selectedHistoryType === "One Time") ? (
        <ReceiptModal
          data={
            NACHAModalData.end_date ? (
              NACHAPhoneSingleModalData(
                NACHAModalData.name_on_check,
                NACHAModalData.type,
                NACHAModalData.account_number,
                NACHAModalData.per_month
              )
            ) : (
              OLD_NACHAPhoneSingleModalData(
                NACHAModalData.name_on_check,
                NACHAModalData.type,
                NACHAModalData.account_number,
                NACHAModalData.per_month
              )
            )
          }
          setShowModal={setSelectedHistoryType}
          modalDate={modalDate}
        />
      ) : null}
    </div>
  )
}