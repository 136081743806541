import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";
import { CustomButton } from "components/ui-kit/CustomButtom";

import { paymentAmounsList } from "./helpers/paymentAmounsListData";

import { OTPPaymentDetailsModal } from "../OTPPaymentDetailsModal";

import "./style.scss";

export const OTPSelectAmountModal = ({isModalOpen, toggle, debt}) => {

  const [isPaymentSelected, setIsPaymentSelected] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState("");

  const [isOTPPaymentDetailsModalOpen, setIsOTPPaymentDetailsModalOpen] = useState(false);
  const toggleOTPPaymentDetails = () => setIsOTPPaymentDetailsModalOpen(!isOTPPaymentDetailsModalOpen);


  useEffect(() => {
    if (paymentAmount) {
      setIsPaymentSelected(true);
    }
  }, [paymentAmount]);

  const handleAmountChange = (e) => {
    if (
      +e.target.value > debt.plan_balance ||
      +e.target.value < 0 ||
      isNaN(+e.target.value)
    )
      return;

    const value = e.target.value;
    if (value.split(".")[1] && value.split(".")[1].length > 2) {
      const begin = value.split(".")[0];
      const end = value.split(".")[1].slice(0, 2);
      setPaymentAmount(`${begin}.${end}`);
    } else {
      setPaymentAmount(value);
    }

    if (
      e.target.value !== "" &&
      !isNaN(+e.target.value) &&
      e.target.value >= 5
    ) {
      setIsPaymentSelected(true);
    } else {
      setIsPaymentSelected(false);
    }
  };

  const handleGoNext = () => {
    toggleOTPPaymentDetails();
  }

  const isAmountButtonDisabled = (amount) => {
    return Number(amount.split(" ")[1]) > +debt.plan_balance;
  };

  const handleSelectAmount = (amount) => {
    if (amount === "Full") {
      setPaymentAmount(debt.plan_balance);
      setIsPaymentSelected(true);
      return;
    }
    const formattedAmount = amount.split(" ")[1];
    setPaymentAmount(formattedAmount);
    setIsPaymentSelected(true);
  };

  const closeAndReboot = () => {
    window.location.reload(false);
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        // toggle={toggle}
        scrollable={true}
        className="opt-select-amount-modal"
        centered
      >
        <ModalBody>
          <div className="mod-header">
            <span>How much do you want to pay?</span>
            <button className="close-modal-btn" onClick={() => closeAndReboot()}>
              <Close />
            </button>
          </div>
          <div className="otp-body-container">
            <div className="main-block">
              <span>
                Enter any amount that fits your current financial situation
              </span>
              <div className="otp-input">
                <p>
                  Amount
                </p>
                <input
                  type="text"
                  placeholder="Amount"
                  // disabled={preview}
                  value={paymentAmount}
                  onChange={handleAmountChange}
                />
                <span>$</span>
              </div>
              <div className="otp-possible-payment-container">
                <ul className="otp-possible-payment-btn-list list-unstyled">
                  {paymentAmounsList.map((amount, i) => (
                    <li key={i}>
                      <CustomButton
                        type="secondary"
                        size="small"
                        optionalClass="otp-possible-payment-btn"
                        text={amount}
                        onClickHandler={() => handleSelectAmount(amount)}
                        disabled={isAmountButtonDisabled(amount)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="mod-footer">
            <CustomButton
              text="Cancel"
              onClickHandler={() => closeAndReboot()}
              optionalClass="cancel-btn"
            />
            <CustomButton
              text="Select Amount"
              onClickHandler={() => handleGoNext()}
              disabled={!isPaymentSelected}
              optionalClass="confirm-btn"
            />
          </div>
        </ModalBody>
      </Modal>
      <OTPPaymentDetailsModal
        isModalOpen={isOTPPaymentDetailsModalOpen}
        toggle={toggleOTPPaymentDetails}
        parentModal={isModalOpen}
        parentToggle={toggle}
        debt={debt}
        paymentAmount={paymentAmount}
      />
    </>
  )
}
