import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";
import { useAlert } from "react-alert";

import { DebtCustomPlan } from "components/DebtCustomCard/DebtCustomPlan";
import { addCustomPlan } from "lib/api/admin";
import { CustomButton } from "components/ui-kit/CustomButtom";
import { fetchSingleBalance } from "redux/actions/singleBalance";

import { PiggyCodeModal } from "./PiggyCodeModal";

import "./style.scss";

export const AddPlanModal = ({ toggle, isModalOpen, debtId, reference }) => {
  const alert = useAlert();
  const { id } = useParams();
  const dispatch = useDispatch();
  const balance = useSelector((state) => state.singleBalance.balanceData);

  const [isOpenPlan, setIsOpenPlan] = useState(false);
  const [discountPercent, setDiscountPercent] = useState("");
  const [payments, setPayments] = useState([]);
  const [isAddPlanDisabled, setIsAddPlanDisabled] = useState(true);
  const [isPaymentsDisabled, setIsPaymentsDisabled] = useState(true);
  const [request, setRequest] = useState({
    is_open_ended: isOpenPlan,
    plan_type: "Monthly",
    data_id: reference,
    number_of_payments: "",
    tot_amt_to_pay: "",
    tot_mo_payment: "",
    discount: "",
  });
  
  const [isPiggyCodeModal, setPiggyCodeModal] = useState();
  const togglePiggyCodeModal = () => setPiggyCodeModal(!isPiggyCodeModal);

  useEffect(() => {
    if (!balance) {
      dispatch(fetchSingleBalance(id));
    }
  }, [isModalOpen]);

  const handleAddCustomPlan = async () => {

    if (isOpenPlan === true) {
      // if (+request.tot_amt_to_pay < 1200) {
        togglePiggyCodeModal();
      // } else {
      //   setIsAddPlanDisabled(true);
      //   const formRequest = {
      //     plan_type: request.plan_type,
      //     data_id: request.data_id,
      //     number_of_payments: request.number_of_payments,
      //     tot_amt_to_pay: request.tot_amt_to_pay,
      //     tot_mo_payment: request.tot_mo_payment,
      //     discount: 0,
      //     is_open_ended: request.is_open_ended
      //   }

      //   const data = await addCustomPlan(formRequest, id);

      //   if (data.success === "True") {
      //     window.location.reload(false);
      //   } else {
      //     alert.error(data.message);
      //   }
        
      //   setIsAddPlanDisabled(false);
      // }
    }

    if (isOpenPlan === false) {

      setIsAddPlanDisabled(true);

      const data = await addCustomPlan(request, id);
  
      if (data.success === "True") {
        window.location.reload(false);
      } else {
        alert.error(data.message);
      }
      setIsAddPlanDisabled(false);
    }
  };

  const handlePlanTypeChange = (e) => {
    e.persist();
    if (e.target.value === "biweekly") {
      setRequest((oldRequestData) => {
        return {
          ...oldRequestData,
          plan_type: "Bi-Weekly",
        };
      });
    } else {
      setRequest((oldRequestData) => {
        return {
          ...oldRequestData,
          plan_type:
            e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
        };
      });
    }
  };

  const updateRequestData = (key, value) => {
    if (key !== "number_of_payments") {
      const v1 = value.split(".")[0];
      const v2 = value.split(".")[1];
      let end = "";

      if (v2) {
        end = `.${v2.slice(0, 2)}`;
      } else if (v2 === "") {
        end = `.`;
      } else {
        end = "";
      }

      const newValue = `${v1}${end}`;

      setRequest((oldRequestData) => {
        return {
          ...oldRequestData,
          [key]: newValue,
        };
      });
    } else {
      setRequest((oldRequestData) => {
        return {
          ...oldRequestData,
          [key]: value,
        };
      });
    }
  };

  const backBtn = () => {
    toggle();
  }

  const handleChangePlanType = (e) => {
    
    setIsOpenPlan(JSON.parse(e.target.value));

    setRequest({...request, is_open_ended: JSON.parse(e.target.value)})
  }

  return (
    <>
      <Modal isOpen={isModalOpen} toggle={toggle} centered className="add-plan-modal">
        <ModalBody>
          <button className="close-modal-btn" onClick={() => toggle()}>
            <Close />
          </button>
          <div>
            <h3 className="modal-title">Add Plan</h3>
            <div className="add-option-modal__payment-select">
              <div className="plan-label">Plan Type</div>
              <select
                name="plan-types"
                id="plan-types"
                onChange={handleChangePlanType}
              >
                <option value={false}>SIF/PIF plan</option>
                <option value={true}>Open Ended Plan</option>
              </select>
            </div>
            <div className="add-option-modal__payment-select">
              <div className="plan-label">Plan Frequency</div>
              <select
                name="plan-types"
                id="plan-types"
                onChange={handlePlanTypeChange}
              >
                <option value="monthly">Monthly</option>
                <option value="weekly">Weekly</option>
                <option value="biweekly">Biweekly</option>
              </select>
            </div>
            <DebtCustomPlan
              debtData={
                balance?.debts_info.length !== 0 &&
                balance?.debts_info.filter((item) => item.id === debtId)[0]
                  .current_balance
              }
              setIsAddPlanDisabled={setIsAddPlanDisabled}
              discountPercent={discountPercent}
              setDiscountPercent={setDiscountPercent}
              payments={payments}
              setPayments={setPayments}
              type={"weekly"}
              isAddPlanDisabled={isAddPlanDisabled}
              isPaymentsDisabled={isPaymentsDisabled}
              setIsPaymentsDisabled={setIsPaymentsDisabled}
              request={request}
              updateRequestData={updateRequestData}
              isOpenPlan={isOpenPlan}
            />
            <div className="mod-footer">
              <CustomButton
                text="Back"
                onClickHandler={() => backBtn()}
                optionalClass="cancel-btn"
              />
              <CustomButton
                text="Add Plan"
                onClickHandler={handleAddCustomPlan}
                disabled={isAddPlanDisabled}
              />
            </div>
          </div>

        </ModalBody>
      </Modal>
      {isPiggyCodeModal && (
        <PiggyCodeModal
          isModalOpen={isPiggyCodeModal}
          toggle={togglePiggyCodeModal}
          parentModal={isModalOpen}
          parentToggle={toggle}
          request={request}
        />
      )}
    </>
  );
};