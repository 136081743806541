import React from "react";

import PropTypes from "prop-types";

import {
  SystemCheckCircledIcon24,
  SystemAlertIcon24,
} from "../../components/icons/SystemIcons";
import { CustomButton } from "../../components/ui-kit/CustomButtom";

export const StepOne = ({
  error,
  email,
  handleEmailChange,
  isEmailValid,
  password,
  handlePasswordChange,
  gotToForgotPassword,
  handleLogIn,
  isButtonDisabled,
  isPasswordValid
}) => {
  return (
    <form method="POST">
      <div className="form-group">
        <label htmlFor="email" className="heading heading-caption-bold">
          Email
        </label>
        <input
          className={
            error ? "input input-large input-has-error" : "input input-large"
          }
          type="text"
          name="email"
          value={email}
          onChange={handleEmailChange}
        />
        {isEmailValid && <SystemCheckCircledIcon24 />}
      </div>
      <div className="form-group password">
        <label htmlFor="password" className="heading heading-caption-bold">
          Password
        </label>
        <input
          className={
            error ? "input input-large input-has-error" : "input input-large"
          }
          type="password"
          name="password"
          value={password}
          onChange={handlePasswordChange}
        />
        {error && <SystemAlertIcon24 />}
        {
          <div className="d-flex justify-content-between">
            <div className="error heading heading-caption-bold">{error}</div>
          </div>
        }
      </div>
      <CustomButton
        text="Login"
        size="large"
        onClickHandler={(e) => handleLogIn(e)}
        disabled={isButtonDisabled || !(isEmailValid && isPasswordValid)}
      />
      <div className="forgot-pass-container">
        <button
          className="button button-link heading heading-caption-bold forgot-pass-btn"
          onClick={(e) => gotToForgotPassword(e)}
          type="button"
        >
          Forgot Password?
        </button>
      </div>
    </form>
  );
};

StepOne.propTypes = {
  error: PropTypes.string,
  email: PropTypes.string,
  handleEmailChange: PropTypes.func,
  isEmailValid: PropTypes.bool,
  password: PropTypes.string,
  handlePasswordChange: PropTypes.func,
  gotToForgotPassword: PropTypes.func,
  handleLogIn: PropTypes.func,
  isButtonDisabled: PropTypes.bool,
};
