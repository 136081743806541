import sendRequest from "./sendRequest";

export const adminLogin = async (body) => {
  clearLocalStorage();
  try {
    const data = await sendRequest(`/user/login`, "POST", body);

    localStorage.setItem(
      "userData",
      JSON.stringify({
        token: data.token,
      })
    );

    return data;
  } catch (e) {
    return { success: "False", message: `${e}` };
  }
};

export const adminVerification = async (body) => {
  const token = JSON.parse(localStorage.getItem("userData")).token.toString();
  const data = await sendRequest(`/user/verify`, "POST", body);

  localStorage.setItem(
    "userData",
    JSON.stringify({
      token,
      role: data.role,
      verified: data.verified,
    })
  );
  return data;
};

// mail
export const consumerSendVerificationCode = async (body) => {
  const data = await sendRequest(
    `/client/send-verification-code`,
    "POST",
    body
  );

  return data;
};
// verification_code, email
export const consumerVerification = async (body) => {
  const data = await sendRequest(`/client/verify`, "POST", body);
  const token =
    data.token || JSON.parse(localStorage.getItem("userData")).token.toString();

  if (data.success === "True") {
    localStorage.setItem(
      "userData",
      JSON.stringify({
        token,
        role: data.role,
      })
    );
    localStorage.setItem("firstLogin", true);
  }

  return data;
};

export const consumerEnableMFA = async (body) => {
  const response = await sendRequest(`/client/mfa`, "POST", body);
  return response;
}

export const getConsumerEnableMFAByClientId = async (body) => {
  const preparedRequest = {
    client_id: body
  }
  const response = await sendRequest(`/client/mfa`, "GET", preparedRequest);
  return response;
}

export const getConsumerEnableMFA = async () => {
  const response = await sendRequest(`/client/mfa`, "GET");
  return response;
}

// HELPERS
const clearLocalStorage = () => {
  localStorage.clear("token");
  localStorage.clear("role");
  localStorage.clear("userId");
};
