import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";
import { useAlert } from "react-alert";
import { useParams } from "react-router-dom";

import { getConsumerDebtHistory } from "lib/api/common";

import fileIcon from "../../../../scss/images/file-icon.png"
import { ReceiptModal } from "components/ReceiptModal";
import { useModal } from "components/DebtCustomCard/hooks/useModal";

import { formattedMoney } from "lib/helpers/formatters";

import { useTranslation } from 'react-i18next';

import "./style.scss";

export const SuccessPaymentModal = ({modal, parentModal, parentToggle, selectedPaymentObject, paymentAmount, debt}) => {
  
  const { t } = useTranslation();

  const { id } = useParams();
  const alert = useAlert();

  const {
    modalData,
    modalDate,
    showModal,
    setShowModal,
    handleGetReceipt
  } = useModal(id, debt, alert);

  const closeModal = () => {
    window.location.reload(false);
  }

  const renderSubtitle = () => {
    const diff = debt.plan_balance - paymentAmount;
    return (
      <div className="subtitle">{t("Thank you for the one-time payment. Your current plan balance is")} ${formattedMoney(diff.toFixed(2))}.</div>
    )
  }
  const renderPaymentMethodValue = () => {
    let value;
    // for existing Debit Card
    if (selectedPaymentObject?.CardName) {
      value = selectedPaymentObject.CardName.slice(-4);
      return `•••• ${value}`;
    }
    // for new Debit Card
    if (selectedPaymentObject?.card_name) {
      value = selectedPaymentObject.card_name.slice(-4);
      return `•••• ${value}`;
    }
    // for existing Account Number
    if (selectedPaymentObject?.AccountNumber) {
      value = selectedPaymentObject.AccountNumber.slice(-4);
      return `•••• ${value}`;
    }
    // for new Account Number
    if (selectedPaymentObject?.account_number) {
      value = selectedPaymentObject.account_number.slice(-4);
      return `•••• ${value}`;
    }
    if (selectedPaymentObject?.Email) {
      return "PayPal";
    }
    if (selectedPaymentObject?.email) {
      return "PayPal";
    }
  }

  const closeParentModal = () => {
    if (parentModal) {
      parentToggle();
    }
  }

  const getLatestTransactionDate = (otpHistory, paymentHistory) => {
    const fullPaymentHistory = otpHistory.concat(paymentHistory);

    const filteredSuccessfulPayments = _.filter(
      fullPaymentHistory,
      function (item) {
        return item.success === true;
      }
    );

    return filteredSuccessfulPayments.reduce((a, b) =>
      a.payment_date > b.payment_date ? a : b
    );
  };

  const getReceipt = async() => {
    let latestTransaction = null;
    // Need this magic to get latest history for fetching receipt
    const paymentHistory = await getConsumerDebtHistory({
      client_id: id,
      debt_id: debt.debt_id,
    });

    paymentHistory.debts_payment_history.map((item) => {
      if (item.debt_id === debt.debt_id) {
        latestTransaction = getLatestTransactionDate(
          item.otp_payment_history,
          item.payment_history
        );
      }
    })

    handleGetReceipt(latestTransaction.amount, latestTransaction.payment_date, "payment_plan");
  }

  return (
    <>
      <Modal
        isOpen={modal}
        // toggle={closeModal}
        onOpened={closeParentModal}
        className="success-payment-modal"
        id="final-details"
        centered
      >
        <ModalBody>
          <div className="mod-header">
            <button className="close-modal-btn" onClick={() => closeModal()}>
              <Close />
            </button>
          </div>
          <div className="mod-body">
            <div className="title">{t("You are one step closer to leaving debt in your past")}</div>
            {renderSubtitle()}
            <div className="success-info-container">
              <div className="info-row">
                <span className="label">{t("Total Amount")}</span>
                <span className="value">${formattedMoney(paymentAmount)}</span>
              </div>
              <div className="info-row">
                <span className="label">{t("Payment Method")}</span>
                <span className="value">{selectedPaymentObject ? renderPaymentMethodValue() : t("Mail/Wire")}</span>
              </div>
              <div className="info-row">
                <span className="label">{t("Transaction Date")}</span>
                <span className="value">{moment(new Date()).format("ll")}</span>
              </div>
            </div>
            <div className="mod-footer-extra">
              <div className="download-receipt-btn" onClick={() => getReceipt()}>
                <img src={fileIcon} alt="file icon"/>
                <span>{t("Download Receipt")}</span>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {showModal && modalData && (
        <ReceiptModal
          data={modalData}
          setShowModal={setShowModal}
          modalDate={modalDate}
        />
      )}
    </>
  )
}

SuccessPaymentModal.propTypes = {
  parentModal: PropTypes.bool,
  parentToggle: PropTypes.any,
  selectedPaymentObject: PropTypes.object,
  paymentAmount: PropTypes.string,
  debt: PropTypes.object
}
