export const multitenancyColorMapper = [
  {
    hostname: 'waythru-test1.com',
    customWhite01: "#EDF4FD",
    customBlue: "#639FF1"
  },
  {
    hostname: 'investinet.waythru.com',
    customWhite01: "#EDF4FD",
    customBlue: "#639FF1"
  },
  {
    hostname: 'consuegra-test.waythru-test1.com',
    customWhite01: "#cfe3f9",
    customBlue: "#639FF1"
  },
  {
    hostname: 'consuegralaw.waythru.com',
    customWhite01: "#cfe3f9",
    customBlue: "#639FF1"
  },
  {
    hostname: 'waythru-test2.com',
    customWhite01: "#EBEAFC",
    customBlue: "#25149C"
  },
  {
    hostname: 'demo.waythru.com',
    customWhite01: "#EBEAFC",
    customBlue: "#25149C"
  },
  {
    hostname: 'tmppllc-test.waythru-test1.com',
    customWhite01: "#EBEAFC",
    customBlue: "#002856"
  },
  {
    hostname: 'tmppllc.waythru.com',
    customWhite01: "#EBEAFC",
    customBlue: "#002856"
  }
]