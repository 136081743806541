import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { useAlert } from "react-alert";
import PasswordChecklist from "react-password-checklist"

import {
  adminFormAddressItems,
  adminFormPhoneNumbersItems,
  adminFormPrimaryItems,
} from "./adminItems";
import { updateProfileForm } from "../../../redux/actions/profile";
import { ProfileFormInput } from "../ProfileFormInput";
import { validatedZip } from "../helpers/validators";
import { ProfileFormStates } from "../ProfileFormStates";
import { LoadingSpinner } from "../../LoadingSpinner";
import { resetPassword } from "../../../lib/api/admin";
import { ProfileMediumTextInput } from "../ProfileMediumTextInput";
import { PhoneNumbersBlock } from "./components/PhoneNumbersBlock";
import { CustomButton } from "../../ui-kit/CustomButtom";

export const AdminProfileForm = ({ profileType, setIsValidForm }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.profile.loading);
  const adminData = useSelector((state) => state.profile.profileData);
  const isEditingProfile = useSelector(
    (state) => state.profile.isEditingProfile
  );

  const [profileForm, setProfileForm] = useState({});

  useEffect(() => {
    setProfileForm(_.omit(adminData, ["id"]));
  }, [adminData]);

  useEffect(() => {
    if (profileType === "new-agent") {
      dispatch(
        updateProfileForm({
          ...profileForm,
          dob: moment(new Date()).format("YYYY-MM-DD"),
        })
      );
      setProfileForm({
        ...profileForm,
        dob: moment(new Date()).format("YYYY-MM-DD"),
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (value) => {
    if (value.cell_phone === "") {
      dispatch(updateProfileForm({ ...profileForm, cell_phone: null }));
    } else if (value.home_phone === "") {
      dispatch(updateProfileForm({ ...profileForm, home_phone: null }));
    } else {
      dispatch(updateProfileForm({ ...profileForm, ...value }));
    }
    setProfileForm({ ...profileForm, ...value });
  };

  const handleChangeDate = (value) => {
    dispatch(
      updateProfileForm({
        ...profileForm,
        dob: moment(value).format("YYYY-MM-DD"),
      })
    );
    setProfileForm({ ...profileForm, dob: moment(value).format("YYYY-MM-DD") });
  };

  const handleStateChange = (e) => {
    dispatch(updateProfileForm({ ...profileForm, state: e.target.value }));
    setProfileForm({ ...profileForm, state: e.target.value });
  };

  const handleTemporaryPasswordChange = (e) => {
    dispatch(
      updateProfileForm({
        ...profileForm,
        temporary_password: e.target.value,
      })
    );
    setProfileForm({ ...profileForm, temporary_password: e.target.value });
  };

  const onValidForm = (isValid) => {
    setIsValidForm(!isValid);
  }

  const handleResetPassword = async () => {
    const data = await resetPassword(adminData.id);

    if (data.success === "True") alert.success("Reset password. Success.");
  };

  const renderPrimaryInformation = () => {
    return adminFormPrimaryItems.map((item) => {
      const [itemValue] = Object.values(
        _.pickBy(adminData.profile, function (value, key) {
          return _.startsWith(key, item.name);
        })
      );

      if (item.title.toLowerCase() === "start date") {
        return (
          <div className="profile-form-group form-group" key={item.name}>
            <label className="heading heading-caption-bold" htmlFor={item.name}>
              {profileType === "new-agent" || profileType === "agent"
                ? "Start"
                : "Birth"}{" "}
              Date
            </label>
            <div
              className={
                isEditingProfile
                  ? "input input-medium input-date"
                  : "input input-medium input-date disabled"
              }
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  disabled={!isEditingProfile || item.disabled}
                  id="birthDate"
                  //value={moment(formData.selectedBirthDate).format(
                  //  "MM/DD/YYYY"
                  // )}
                  onChange={handleChangeDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  name={item.name}
                  value={
                    moment(profileForm.dob).format("MM/DD/YYYY") || moment(itemValue).format("MM/DD/YYYY")
                  }
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        );
      }

      return (
        <div className="profile-form-group form-group" key={item.name}>
          <label className="heading heading-caption-bold" htmlFor={item.name}>
            {item.title}
          </label>
          <ProfileFormInput
            value={profileForm && profileForm[item.name]}
            name={item.name}
            handleChange={handleChange}
            disabled={
              (!isEditingProfile || item.disabled) &&
              profileType !== "new-agent"
            }
          />
        </div>
      );
    });
  };

  const isValidField = (title, value) => {
    if (title === "Cell Phone" && !value && !profileForm.home_phone) {
      return false;
    }

    if (title === "Home Phone" && !value && !profileForm.cell_phone) {
      return false;
    }
    return true;
  };

  const renderAdress = () => {
    return adminFormAddressItems.map((item) => {
      let validator;
      // Pick validator function for current input
      if (item.title.toLowerCase() === "zip") {
        validator = validatedZip;
      }
      const formGroupClass = () => {
        if (item.title.toLowerCase() === "street") {
          return "profile-form-group form-group profile-form-group--street";
        } else if (item.title.toLowerCase() === "zip") {
          return "profile-form-group form-group profile-form-group--zip";
        } else {
          return "profile-form-group form-group";
        }
      };

      if (item.title.toLowerCase() === "state") {
        return (
          <div className="profile-form-group form-group" key={item.name}>
            <label className="heading heading-caption-bold" htmlFor="state">
              State
            </label>
            <ProfileFormStates
              value={profileForm && profileForm[item.name]}
              disabled={!isEditingProfile}
              handleStateChange={handleStateChange}
            />
          </div>
        );
      }

      return (
        <div className={formGroupClass()} key={item.name}>
          <label className="heading heading-caption-bold" htmlFor={item.name}>
            {item.title}
          </label>
          <ProfileFormInput
            value={profileForm && profileForm[item.name]}
            name={item.name}
            handleChange={handleChange}
            validator={validator}
            disabled={!isEditingProfile || item.disabled}
          />
        </div>
      );
    });
  };

  if ((_.isEmpty(profileForm) || loading) && profileType !== "new-agent") {
    return <LoadingSpinner middleFixed={true}/>;
  }

  return (
    <div className="profile-form__body">
      <div className="profile-form__block">
        <h3 className="heading heading-body-bold profile-title">Primary Information</h3>
        <div className="profile-form__container">
          {renderPrimaryInformation()}
        </div>
      </div>
      <div className="profile-form__block">
        <h3 className="heading heading-body-bold profile-title">Phone Numbers</h3>
        <div className="profile-form__container">
          <PhoneNumbersBlock
            formPhoneNumbersItems={adminFormPhoneNumbersItems}
            profileForm={profileForm}
            adminData={adminData}
            isEditingProfile={isEditingProfile}
            handleChange={handleChange}
            profileType={profileType}
            isValidField={isValidField}
          />
        </div>
      </div>
      <div className="profile-form__block">
        <h3 className="heading heading-body-bold profile-title">Address</h3>
        <div className="profile-form__container">{renderAdress()}</div>
      </div>
      <div className="profile-form__block">
        <h3 className="heading heading-body-bold profile-title">Password</h3>
        <div className="profile-form__container">
          <div className="profile-form-group form-group">
            {profileType === "agent" && (
              <CustomButton
                type="secondary"
                text="Reset Password"
                onClickHandler={handleResetPassword}
              />
            )}
            {profileType === "admin" && (
              <Link
                className="button button-secondary button-secondary--medium"
                disabled={false}
                to="/admin/change-password"
              >
                Change Password
              </Link>
            )}
            {profileType === "new-agent" && (
              <div>
                <div className="profile-password-checklist">
                <PasswordChecklist
                  rules={["minLength","specialChar","number","capital"]}
                  minLength={8}
                  value={profileForm.temporary_password || ""}
                  onChange={(isValid) => {onValidForm(isValid)}}
                />
                <label
                  className="heading heading-caption-bold"
                  htmlFor="temporaryPassword"
                >
                  Temporary Password
                </label>
                </div>
                <ProfileMediumTextInput
                  name="temporary_password"
                  value={profileForm.temporary_password || ""}
                  onChange={handleTemporaryPasswordChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

AdminProfileForm.propTypes = {
  profileType: PropTypes.string,
  setIsValidForm: PropTypes.func,
};
