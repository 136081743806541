import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { PageHeader } from "../../../../components/PageHeader";
import { ProfileBody } from "../../../../components/ProfileBody";
import { removeAgent } from "../../../../lib/api/admin";
import { fetchAgentProfile } from "../../../../redux/actions/profile";

import { multitenancyCompanyShortName } from 'multitenancy/multitenancyContactInfo';

import "./style.scss";

class SingleAgent extends Component {
  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.fetchAgentProfile(id);
  }

  async handleRemoveAgent() {
    await removeAgent({
      id: this.props.profileData.id,
      is_active: "False",
    });

    this.props.history.push("/admin/roles");
  }

  render() {
    const { profileData } = this.props;
    const helmetTitle = profileData?.full_name + ` – ${multitenancyCompanyShortName()}`;

    return (
      <div className="single-agent profile-page-container">
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <PageHeader
          title="Roles name"
          backURL="/admin/roles"
          backTitle="Roles"
        />
        <div className="tab-container">
          <div className="tab-container__column">
            <div className="avatar-wrapper">
              <h2 className="heading heading-2">
                {profileData && profileData.full_name}
              </h2>
              <h6 className="heading heading-caption-bold">
                From Feb 28, 2020
              </h6>
              <button
                className="button button-transparent heading heading-body-bold"
                onClick={() => this.handleRemoveAgent()}
              >
                Remove Agent
              </button>
            </div>
          </div>
          <ProfileBody profileData={profileData} profileType="agent" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profileData: state.profile.profileData,
  };
};

export default connect(mapStateToProps, { fetchAgentProfile })(
  withRouter(SingleAgent)
);

SingleAgent.propTypes = {
  match: PropTypes.object,
  fetchAgentProfile: PropTypes.func,
  profileData: PropTypes.object,
  history: PropTypes.object,
};
