import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";
import { useAlert } from "react-alert";
import { formattedMoney } from "lib/helpers/formatters";

import { OtherMasterCardIcon, OtherVisaIcon } from "../../../../components/icons/OtherIcons";
import amexSvg from "../../../../scss/images/amex.svg";
import discoverSVG from "../../../../scss/images/discover.svg";
import paypalIcon from "scss/images/paypal.png";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";

import { AddExtraPaymNewBankAccountModal } from "../AddExtraPaymNewBankAccountModal";
import { AddExtraPaymNewDebitCardModal } from "../AddExtraPaymNewDebitCardModal";
import { AddExtraPaymNewPayPalModal } from "../AddExtraPaymNewPayPalModal";

import { SuccessPaymentModal } from "../SuccessPaymentModal";

import { ChangePaymentSuccess } from "./ChangePaymentSuccess";

import { setDefaultPaymentMethod, submitExtraPayment } from "../../../../lib/api/common";

import { CustomButton } from "../../../../components/ui-kit/CustomButtom";

import { useTranslation } from 'react-i18next';
import { showDependOnTenant } from "multitenancy/multitenancyPayPal";

import "./style.scss";

export const SelectPaymentMethodModal = ({
  modal, 
  toggle, 
  parentModal, 
  parentToggle, 
  isChangePaymentMethod, 
  paymentAmount,
  currentBalance,
  debt,
  nearestScheduleDate
}) => {

  const { t } = useTranslation();

  const alert = useAlert();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const [selectedPaymentType, setPaymentType] = useState("");
  const [isPaymentSelected, setIsPaymentSelected] = useState(false);
  const [selectedPaymentObject, setSelectedPaymentObject] = useState({});

  const [successPaymentModal, setSuccessPaymentMethod] = useState(false);
  const toggleSuccessPayment = () => setSuccessPaymentMethod(!successPaymentModal);

  const [changePaymentSuccessModal, setChangePaymentSuccessModal] = useState(false);
  const toggleChangePaymentSuccessModal = () => setChangePaymentSuccessModal(!changePaymentSuccessModal);

  const [addNewBankAccountModal, setAddNewBankAccountModal] = useState(false);
  const toggleAddNewBankAccountModal = () => setAddNewBankAccountModal(!addNewBankAccountModal);

  const [addNewDebitCardModal, setAddNewDebitCardModal] = useState(false);
  const toggleAddNewDebitCardModal = () => setAddNewDebitCardModal(!addNewDebitCardModal);

  const [addNewPayPalModal, setAddNewPayPalModal] = useState(false);
  const toggleAddNewPayPalModal = () => setAddNewPayPalModal(!addNewPayPalModal);

  const savedPayPalAccounts = useSelector((state) => state.consumerSavedPaymentMethod.savedPayPalAccounts);
  const savedDebitCards = useSelector((state) => state.consumerSavedPaymentMethod.savedDebitCards);
  const savedBankAccount = useSelector((state) => state.consumerSavedPaymentMethod.savedBankAccount);
  const profileInfo = useSelector((state) => state.profile.profileData.profile);


  const handleChangePaymentType = (e, item) => {
    setPaymentType(e.target.id);
    setIsPaymentSelected(true);
    setSelectedPaymentObject(item);
  }

  const closeModal = () => {
    setIsPaymentSelected(false);
    toggle();
  }

  const openSuccessModal = () => {
    toggleSuccessPayment();
  }

  const openNewDebitCardModal = () => {
    toggleAddNewDebitCardModal();
  }

  const openNewBankAccountModal = () => {
    toggleAddNewBankAccountModal();
  }

  const openNewPayPalModal = () => {
    toggleAddNewPayPalModal();
  }

  const handleSelectedPaymentMethod = () => {

    if (selectedPaymentType === "mailWire") {
      extraPaymentPaymentMailWire();
      return;
    }

    if (selectedPaymentType === "newDebitCard") {
      // here should open new payment method
      openNewDebitCardModal();
    } else if (selectedPaymentType === "newBankAccount") {
      openNewBankAccountModal();
    } else if (selectedPaymentType === "newPayPal") {
      openNewPayPalModal();
    } else {
      if (isChangePaymentMethod) {
        // change payment method request
        changeExistingPaymentMethod();
      } else {
        // OTP request
        if (selectedPaymentObject.AccountNumber) {
          // request for bank account OTP
          makeOtpPaymentForAccountNumber();
        } else if (selectedPaymentObject.CardName) { 
          // request for debit card OTP
          makeOtpPaymentForDebitCard();
        } else if (selectedPaymentObject.Email) {  
          makeOtpPaymentForPayPal();
        }
      }
    }
  }

  const changeExistingPaymentMethod = async () => {
    const request = {
      card_id: selectedPaymentObject.Id,
      data_id: debt.referenceNumber
      
    }

    setLoading(true);
    setIsPaymentSelected(false);
    const setDefaultPaymentMethodResponse = await setDefaultPaymentMethod(request);

    if (setDefaultPaymentMethodResponse.success !== "True") {
      setLoading(false);
      setIsPaymentSelected(true);
      alert.error(setDefaultPaymentMethodResponse.message);
    } else {
      setLoading(false);
      setIsPaymentSelected(true);
      toggleChangePaymentSuccessModal();
    }
  }

  const makeOtpPaymentForAccountNumber = async() => {  
    const request = {
      client_id: id,
      account_number: selectedPaymentObject.AccountNumber,
      account_type: "1",
      agreement_timestamp: new Date(),
      amount: paymentAmount,
      bank_name: selectedPaymentObject.BankName,
      card_id: selectedPaymentObject.Id,
      debt_id: debt.debt_id,
      // email: profileInfo.email,
      name_on_check: profileInfo.full_name,
      payment_method: "ach",
      routing_number: selectedPaymentObject.RoutingNumber
    };

    let data;
    setLoading(true);
    setIsPaymentSelected(false);
    data = await submitExtraPayment(request);

    if (data.success === "True") {
      setLoading(false);
      setIsPaymentSelected(true);
      openSuccessModal();
    } else {
      setLoading(false);
      setIsPaymentSelected(true);
      alert.error(t(data.message));
    }
  }

  const extraPaymentPaymentMailWire = async () => {

    const request = {
      client_id: id,
      agreement_timestamp: new Date(),
      amount: paymentAmount,
      debt_id: debt.debt_id,
      card_id: "None",
      payment_method: "other",
    };

    let data;
    setLoading(true);
    setIsPaymentSelected(false);
    data = await submitExtraPayment(request);

    if (data.success === "True") {
      setLoading(false);
      setIsPaymentSelected(true);
      openSuccessModal();
    } else {
      setLoading(false);
      setIsPaymentSelected(true);
      alert.error(data.message);
    }
  }

  const makeOtpPaymentForDebitCard = async () => {

    const request = {
      client_id: id,
      agreement_timestamp: new Date(),
      amount: paymentAmount,
      card_id: selectedPaymentObject.Id,
      debt_id: debt.debt_id,
      payment_method: "card",
    };

    let data;
    setLoading(true);
    setIsPaymentSelected(false);
    data = await submitExtraPayment(request);

    if (data.success === "True") {
      setLoading(false);
      setIsPaymentSelected(true);
      openSuccessModal();
    } else {
      setLoading(false);
      setIsPaymentSelected(true);
      alert.error(t(data.message));
    }
  }

  const makeOtpPaymentForPayPal = async () => {

    const request = {
      client_id: id,
      agreement_timestamp: new Date(),
      amount: paymentAmount,
      card_id: selectedPaymentObject.Id,
      debt_id: debt.debt_id,
      payment_method: "paypal",
    };

    let data;
    setLoading(true);
    setIsPaymentSelected(false);
    data = await submitExtraPayment(request);

    if (data.success === "True") {
      setLoading(false);
      setIsPaymentSelected(true);
      openSuccessModal();
    } else {
      setLoading(false);
      setIsPaymentSelected(true);
      alert.error(data.message);
    }
  }

  const renderCardIcon = (cardType, paymentProc) => {

    if (paymentProc === "PayPal") {
      return <img className="card-icon" src={paypalIcon} alt="amex" />
    }

    cardType = cardType.slice(0, -5);
    if (cardType === "MASTERCARD") {
      return <OtherMasterCardIcon />
    }
    if (cardType === "VISA") {
      return <OtherVisaIcon />
    }
    if (cardType === "AMEX") {
      return <img className="card-icon" src={amexSvg} alt="amex" />
    }
    if (cardType === "DISCOVER") {
      return <img className="card-icon" src={discoverSVG} alt="discover" />
    }
  }

  const renderCardNumber = (cardNumber) => {
    cardNumber = cardNumber.slice(-4);
    return (
      <span className="card-number">
        •••• {cardNumber}
      </span> 
    )
  }

  const renderCardTime = (expiry) => {
    const month = expiry.Month;
    const year = expiry.Year.toString().substr(-2);
    return (
      <span className="card-time">
        {month}/{year}
      </span>
    )
  }

  const renderAccountNumber = (accountNumber) => {
    accountNumber = accountNumber.slice(-4);
    return (
      <span className="card-number">
        •••• {accountNumber}
        <span className="card-time"> {t("Account")}</span>
      </span> 
    )
  }

  const renderBankName = (bankName) => {
    return(
      <span className="card-time">
        {bankName}
      </span>
    )
  }

  const renderInfoBlock = () => {
    const diff = currentBalance - paymentAmount;
    return (
      <div className="info-block">
        {t("You are about to be charged")} ${formattedMoney(paymentAmount)}. {t("After this one-time payment your current balance on this debt will be")} ${formattedMoney(diff.toFixed(2))}.
      </div>
    )
  }

  const nextBtnText = () => {
    if (selectedPaymentType === "newDebitCard" || selectedPaymentType === "newBankAccount" || selectedPaymentType === "newPayPal") {
      return "Next";
    } else if (isChangePaymentMethod) {
      return "Change Payment Method";
    } else {
      return "Confirm Payment Details";
    }
  }

  const closeParentModal = () => {
    if (parentModal) {
      parentToggle();
    }
  }

  const backBtn = () => {
    setIsPaymentSelected(false);
    toggle();
    if (parentModal) {
      parentToggle();
    }
  }

  const checkCountPaymMethod = () => {
    const count = savedBankAccount?.length + savedDebitCards?.length + savedPayPalAccounts?.length
    if (count > 0) {
      return true
    } else {
      return false;
    } 
  }

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        scrollable={true}
        onOpened={closeParentModal}
        className="change-payment-method-modal"
      >
        <ModalBody>
          <div className="mod-header">
            <span>{isChangePaymentMethod ? <>{t("Change Payment Method")}</> : <>{t("Payment Details")}</>}</span>
            <button className="close-modal-btn" onClick={() => closeModal()}>
              <Close />
            </button>
          </div>
          {!isChangePaymentMethod ? renderInfoBlock() : null}
          <div className="mod-body">
            {savedPayPalAccounts?.map((item, i) => (
              <div key={i} className={`payment-method-container ${selectedPaymentType === item.Id ? "active" : "inactive" }`}>
                <label className="verification-radio">
                  <input
                    type="radio"
                    id={item.Id}
                    name="verificationRadio"
                    onClick={(e) => handleChangePaymentType(e, item)}
                  />
                  <div className="label">
                    <div>
                      {renderCardIcon(item.Email, "PayPal")}
                      <span className="card-number">{item.Email}</span>
                    </div>
                    <span className="card-time">{item.CustomerName}</span>
                  </div>
                  <span className="checkmark"></span>
                </label>
              </div>
            ))}
            {savedDebitCards?.map((item, i) => (
              <div key={i} className={`payment-method-container ${selectedPaymentType === item.Id ? "active" : "inactive" }`}>
                <label className="verification-radio">
                  <input
                    type="radio"
                    id={item.Id}
                    name="verificationRadio"
                    onClick={(e) => handleChangePaymentType(e, item)}
                  />
                  <div className="label">
                    <div>
                      {renderCardIcon(item.CardName)}
                      {renderCardNumber(item.CardName)}
                    </div>
                    {renderCardTime(item.Expiry)}
                  </div>
                  <span className="checkmark"></span>
                </label>
              </div>
            ))}
            {savedBankAccount?.map((item, i) => (
              <div key={i} className={`payment-method-container ${selectedPaymentType === item.Id ? "active" : "inactive" }`}>
                <label className="verification-radio">
                  <input
                    type="radio"
                    id={item.Id}
                    name="verificationRadio"
                    onClick={(e) => handleChangePaymentType(e, item)}
                  />
                  <div className="label">
                    <div>
                      {renderAccountNumber(item.AccountNumber)}
                    </div>
                    {renderBankName(item.BankName)}
                  </div>
                  <span className="checkmark"></span>
                </label>
              </div>
            ))}
            {checkCountPaymMethod() && (
              <div className="or-block-container">
                <hr></hr> 
                <span>{t("or add new")}</span>
                <hr></hr>
              </div>
            )}
            {showDependOnTenant(profileInfo) && (
              <div className={`payment-method-container ${selectedPaymentType === "newPayPal" ? "active" : "inactive" }`}>
                <label className="verification-radio">
                  <input
                    type="radio"
                    id="newPayPal"
                    name="verificationRadio"
                    onClick={(e) => handleChangePaymentType(e)}
                  />
                  <div className="label">PayPal</div>
                  <span className="checkmark"></span>
                </label>
              </div>
            )}
            <div className={`payment-method-container ${selectedPaymentType === "newDebitCard" ? "active" : "inactive" }`}>
              <label className="verification-radio">
                <input
                  type="radio"
                  id="newDebitCard"
                  name="verificationRadio"
                  onClick={(e) => handleChangePaymentType(e)}
                />
                <div className="label">{t("Debit Card")}</div>
                <span className="checkmark"></span>
              </label>
            </div>
            <div className={`payment-method-container ${selectedPaymentType === "newBankAccount" ? "active" : "inactive" }`}>
              <label className="verification-radio">
                <input
                  type="radio"
                  id="newBankAccount"
                  name="verificationRadio"
                  onClick={(e) => handleChangePaymentType(e)}
                />
                <div className="label">{t("Bank Account")}</div>
                <span className="checkmark"></span>
              </label>
            </div>
            {id && profileInfo?.new_payment_system && (
              <div className={`payment-method-container ${selectedPaymentType === "mailWire" ? "active" : "inactive" }`}>
                <label className="verification-radio">
                  <input
                    type="radio"
                    id="mailWire"
                    name="verificationRadio"
                    onClick={(e) => handleChangePaymentType(e)}
                  />
                  <div className="label">{t("Mail/Wire")}</div>
                  <span className="checkmark"></span>
                </label>
              </div>
            )}
          </div>
          <div className="mod-footer">
            <CustomButton
              text={t("Cancel")}
              onClickHandler={() => backBtn()}
              optionalClass="cancel-btn"
            />
            <CustomButton
              text={t(nextBtnText())}
              onClickHandler={() => handleSelectedPaymentMethod()}
              disabled={!isPaymentSelected}
              optionalClass="confirm-btn"
            />
          </div>
        </ModalBody>
        {loading ? (<LoadingSpinner middleFixed={true}/>) : null}
      </Modal>
      {!isChangePaymentMethod && selectedPaymentType !== "newBankAccount" && selectedPaymentType !== "newDebitCard" ? (
        // Success modal for existing cards
        <SuccessPaymentModal
          modal={successPaymentModal}
          selectedPaymentObject={selectedPaymentObject}
          parentModal={modal}
          parentToggle={toggle}
          paymentAmount={paymentAmount}
          debt={debt}
        />
      ) : null }
      <AddExtraPaymNewBankAccountModal
        modal={addNewBankAccountModal}
        toggle={toggleAddNewBankAccountModal}
        parentModal={modal}
        parentToggle={toggle}
        debt={debt}
        paymentAmount={paymentAmount}
        isChangePaymentMethod={isChangePaymentMethod}
        setIsPaymentSelected={setIsPaymentSelected}
        nearestScheduleDate={nearestScheduleDate}
      />
      <AddExtraPaymNewDebitCardModal
        modal={addNewDebitCardModal}
        toggle={toggleAddNewDebitCardModal}
        parentModal={modal}
        parentToggle={toggle}
        debt={debt}
        paymentAmount={paymentAmount}
        isChangePaymentMethod={isChangePaymentMethod}
        nearestScheduleDate={nearestScheduleDate}
      />
      <AddExtraPaymNewPayPalModal
        modal={addNewPayPalModal}
        toggle={toggleAddNewPayPalModal}
        parentModal={modal}
        parentToggle={toggle}
        debt={debt}
        paymentAmount={paymentAmount}
        isChangePaymentMethod={isChangePaymentMethod}
      />
      <ChangePaymentSuccess
        modal={changePaymentSuccessModal}
        parentModal={modal}
        parentToggle={toggle}
      />
    </>
  )
}

SelectPaymentMethodModal.propTypes = {
  parentModal: PropTypes.bool,
  parentToggle: PropTypes.any,
  toggle: PropTypes.func,
  modal: PropTypes.bool,
  isChangePaymentMethod: PropTypes.bool,
  paymentAmount: PropTypes.string,
  currentBalance: PropTypes.any,
  debt: PropTypes.object,
}
