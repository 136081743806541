import React from "react";

import PropTypes from "prop-types";

import { CustomButton } from "../ui-kit/CustomButtom";
import { multitenancyPrivacy } from "multitenancy/multitenancyContactInfo";

import { useTranslation } from 'react-i18next';

import "./style.scss";

export const Cookies = ({ handleAcceptCookies }) => {

  const { t } = useTranslation();

  return (
    <div className="cookies">
      <p>
        {t("We use cookies to improve your experience on our site. To learn more, read our")} {multitenancyPrivacy()}.
      </p>
      <CustomButton text={t("Accept")} onClickHandler={handleAcceptCookies} />
    </div>
  );
};

Cookies.propTypes = {
  handleAcceptCookies: PropTypes.func,
};
