import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";
import { CustomButton } from "components/ui-kit/CustomButtom";
import moment from "moment";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { OtherMasterCardIcon, OtherVisaIcon } from "components/icons/OtherIcons";
import amexSvg from "scss/images/amex.svg";
import discoverSVG from "scss/images/discover.svg";
import fileIcon from "scss/images/file-icon.png";
import { formattedMoney } from "lib/helpers/formatters";
import { useModal } from "components/DebtCustomCard/hooks/useModal";
import { ReceiptModal } from "components/ReceiptModal";

import { getConsumerDebtHistory } from "lib/api/common";

import "./style.scss";

export const FinalDetailsModal = ({isModalOpen, parentModal, parentToggle, startDate, endDate, selectedPlan, selectedPaymentObject, debt, paymentAmount}) => {

  const { id } = useParams();

  const closeModal = () => {
    window.location.reload(false);
  }

  const {
    modalData,
    modalDate,
    showModal,
    setShowModal,
    handleGetReceipt
  } = useModal(id, debt, alert);

  const closeParentModal = () => {
    if (parentModal) {
      parentToggle();
    }
  }

  const renderPaymentMethodValue = () => {
    let value;
    // for existing Debit Card
    if (selectedPaymentObject?.CardName) {
      value = selectedPaymentObject.CardName.slice(-4);
      return (
        <div>
          {renderCardIcon()}•••• {value}
        </div>
      )
    }
    // for new Debit Card
    if (selectedPaymentObject?.card_name) {
      value = selectedPaymentObject.card_name.slice(-4);
      return (
        <div>
          {renderCardIcon()}•••• {value}
        </div>
      )
    }
    // for existing Account Number
    if (selectedPaymentObject?.AccountNumber) {
      value = selectedPaymentObject.AccountNumber.slice(-4);
      return (
        <div>
          {renderCardIcon()}•••• {value}
        </div>
      )
    }
    // for new Account Number
    if (selectedPaymentObject?.account_number) {
      value = selectedPaymentObject.account_number.slice(-4);
      return (
        <div>
          {renderCardIcon()}•••• {value}
        </div>
      )
    }
    
    if (selectedPaymentObject?.Email) {
      return "PayPal";
    }
    if (selectedPaymentObject?.email) {
      return "PayPal";
    }
  }

  const renderCardIcon = () => {

    let cardType;
    
    if (!selectedPaymentObject?.CardName && !selectedPaymentObject?.card_name) {
      return;
    }

    if (selectedPaymentObject?.CardName) {
      cardType = selectedPaymentObject?.CardName.slice(0, -5);
    } else {
      cardType = selectedPaymentObject?.card_name.slice(0, -5);
    }

    if (cardType === "MASTERCARD") {
      return <OtherMasterCardIcon />
    }
    if (cardType === "VISA") {
      return <OtherVisaIcon />
    }
    if (cardType === "AMEX") {
      return <img className="card-icon" src={amexSvg} alt="amex" />
    }
    if (cardType === "DISCOVER") {
      return <img className="card-icon" src={discoverSVG} alt="discover" />
    }
  }

  const renderSubtitle = () => {
    const diff = debt.plan_balance - paymentAmount;
    return (
      <div className="subtitle">Thank you for the one-time payment. Your current plan balance is ${formattedMoney(diff.toFixed(2))}.</div>
    )
  }

  const getLatestTransactionDate = (otpHistory, paymentHistory) => {
    const fullPaymentHistory = otpHistory.concat(paymentHistory);

    const filteredSuccessfulPayments = _.filter(
      fullPaymentHistory,
      function (item) {
        return item.success === true;
      }
    );

    return filteredSuccessfulPayments.reduce((a, b) =>
      a.payment_date > b.payment_date ? a : b
    );
  };

  const getReceipt = async() => {
    let latestTransaction = null;
    // Need this magic to get latest history for fetching receipt
    const paymentHistory = await getConsumerDebtHistory({
      client_id: id,
      debt_id: debt.debt_id,
    });

    paymentHistory.debts_payment_history.map((item) => {
      if (item.debt_id === debt.debt_id) {
        latestTransaction = getLatestTransactionDate(
          item.otp_payment_history,
          item.payment_history
        );
      }
    })

    handleGetReceipt(latestTransaction.amount, latestTransaction.payment_date, "otp");
  }

  const planHeader = () => {
    return (
      (selectedPlan && selectedPlan.payments === 1 && "One Payment") ||
      (selectedPlan && selectedPlan.payment_frequency === "Weekly" && "Per Week") ||
      (selectedPlan && selectedPlan.payment_frequency === "Monthly" && "Per Month") ||
      (selectedPlan && selectedPlan.payment_frequency === "Bi-Weekly" && "Biweekly")
    );
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        // toggle={toggle}
        onOpened={closeParentModal}
        scrollable={true}
        className="final-details-modal"
        id="final-details"
        centered
      >
        <ModalBody>
          <div className="mod-header">
            {/* <span>{paymentAmount ? "You are one step closer to leaving debt in your past" : "Final Details"}</span> */}
            <button className="close-modal-btn" onClick={() => closeModal()}>
              <Close />
            </button>
          </div>
          <div className="mod-body">
            {paymentAmount ? (
              <>
                <div className="title">You are one step closer to leaving debt in your past</div>
                {renderSubtitle()}
                <div className="success-info-container">
                  <div className="info-row">
                    <span className="label">Total Amount</span>
                    <span className="value">${formattedMoney(paymentAmount)}</span>
                  </div>
                  <div className="info-row">
                    <span className="label">Payment Method</span>
                    <span className="value">{renderPaymentMethodValue()}</span>
                  </div>
                  <div className="info-row">
                    <span className="label">Transaction Date</span>
                    <span className="value">{moment(new Date()).format("ll")}</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="title">Final Details</div>
                <div className="overview-text">Plan Overview</div>
                <div className="success-info-container">
                  <div className="info-row">
                    <span className="label">{planHeader()}</span>
                    <span className="value">${formattedMoney(selectedPlan?.per_month)}</span>
                  </div>
                  <div className="info-row">
                    <span className="label">Payments</span>
                    <span className="value">{selectedPlan?.payments}</span>
                  </div>
                  <div className="info-row">
                    <span className="label">Discount</span>
                    <span className="value">{selectedPlan?.discount_percentage}%</span>
                  </div>
                  <div className="info-row">
                    <span className="label">Start Date</span>
                    <span className="value">{moment(startDate).format("ll")}</span>
                  </div>
                  <div className="info-row">
                    <span className="label">End Date</span>
                    <span className="value">{moment(endDate).format("ll")}</span>
                  </div>
                  <div className="info-row">
                    <span className="label">Payment Method</span>
                    <span className="value">{selectedPaymentObject ? renderPaymentMethodValue() : "Mail/Wire"}</span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="mod-footer">
            {paymentAmount ? (
              <div className="download-receipt-btn" onClick={() => getReceipt()}>
                <img src={fileIcon} alt="file icon"/>
                <span>Download Receipt</span>
              </div>
            ) : (
              <CustomButton
                buttonId="see-plan-details"
                text="See Plan Details"
                onClickHandler={() => closeModal()}
                optionalClass="confirm-btn"
              />
            )}
          </div>
        </ModalBody>
      </Modal>
      {showModal && modalData && (
        <ReceiptModal
          data={modalData}
          setShowModal={setShowModal}
          modalDate={modalDate}
        />
      )}
    </>
  )
}